import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxMyAccountMyOrdersDetailsMessagesScheme = z.object({
  customer_data_order_details_heading_order_number:
    themeScheme.MessageScheme.default("Numer zamówienia"),
  customer_data_order_details_heading_order_status:
    themeScheme.MessageScheme.default("Status zamówienia"),
  customer_data_order_details_heading_date:
    themeScheme.MessageScheme.default("Data zamówienia"),
  customer_data_order_details_order_payment_type:
    themeScheme.MessageScheme.default("Płatność"),
  customer_data_order_details_order_total_cost:
    themeScheme.MessageScheme.default("Koszt całkowity"),
  customer_data_order_details_order_products_value:
    themeScheme.MessageScheme.default("Wartość produktów"),
  customer_data_order_details_order_deliver_cost:
    themeScheme.MessageScheme.default("Koszt dostawy za zamówienie"),
  customer_data_order_details_order_value:
    themeScheme.MessageScheme.default("Wartość zamówienia"),
  customer_data_order_details_order_delivery_address:
    themeScheme.MessageScheme.default("Adres dostawy"),
  customer_data_order_details_order_invoice_address:
    themeScheme.MessageScheme.default("Adres do faktury"),
  customer_data_order_details_repeat_payment_button:
    themeScheme.MessageScheme.default("Ponów płatność"),
  customer_data_order_details_dispatch_number:
    themeScheme.MessageScheme.default("Numer wysyłki"),
  customer_data_order_details_dispatch_status:
    themeScheme.MessageScheme.default("Status wysyłki"),
  customer_data_order_details_dispatch_delivery_date:
    themeScheme.MessageScheme.default("Prognozowana data dostawy"),
  customer_data_order_details_dispatch_letter:
    themeScheme.MessageScheme.default("Numer listu przewozowego"),
  customer_data_order_details_dispatch_products_value:
    themeScheme.MessageScheme.default("Wartość produktów"),
  customer_data_order_details_dispatch_delivery_cost:
    themeScheme.MessageScheme.default("Koszt dostawy za wysyłkę"),
  customer_data_orders_dispatch_cancel_button:
    themeScheme.MessageScheme.default("Anuluj wysyłkę"),
  customer_data_cancel_dispatch_popup_title:
    themeScheme.MessageScheme.default("Anulacja wysyłki"),
  customer_data_cancel_dispatch_popup_description:
    themeScheme.MessageScheme.default(
      "Czy na pewno chcesz zrezygnować z wysyłki?"
    ),
  customer_data_cancel_dispatch_popup_button_save:
    themeScheme.MessageScheme.default("Potwierdź"),
  customer_data_cancel_dispatch_popup_button_cancel:
    themeScheme.MessageScheme.default("Anuluj"),
  customer_data_order_details_product_quantity:
    themeScheme.MessageScheme.default("Liczba sztuk"),
  customer_data_order_details_product_status:
    themeScheme.MessageScheme.default("Status produktu"),
  customer_data_order_details_product_price:
    themeScheme.MessageScheme.default("Cena za sztukę"),
  customer_data_order_details_line_price:
    themeScheme.MessageScheme.default("Cena"),
  customer_data_order_details_trackingnumber_not_assigned_info:
    themeScheme.MessageScheme.default("Nie nadano listu przewozowego"),
  customer_data_order_no_data: themeScheme.MessageScheme.default("Brak danych"),
  payment_name_CASH_ON_DELIVERY: themeScheme.MessageScheme.default(
    "Płatność przy odbiorze"
  ),
  payment_name_PAYMENT_IN_ADVANCE:
    themeScheme.MessageScheme.default("Płatność z góry"),
});

export const IBoxMyAccountMyOrdersDetailsSettingsScheme = z.object({
  customer_data_order_details_heading_info_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_order_details_heading_info_buttons_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_order_details_total_costs_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_order_details_dispatch_delivery_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_order_details_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  customer_data_order_details_heading_order_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  customer_data_order_details_heading_order_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_order_value_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  customer_data_order_details_heading_order_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_date_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_heading_date_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_date_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_order_details_heading_date_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_order_status_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_heading_order_status_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_order_status_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_order_details_heading_order_status_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_payment_type_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_payment_type_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_payment_type_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_payment_type_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_delivery_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_delivery_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_delivery_address_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_delivery_address_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_invoice_address_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_invoice_address_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_invoice_address_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_invoice_address_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_cancel_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_order_details_repeat_payment_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  customer_data_order_details_order_total_cost_label_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_order_details_order_total_cost_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_number_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_order_details_dispatch_number_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_products_value_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_products_value_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_products_value_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_products_value_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_deliver_cost_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_deliver_cost_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_deliver_cost_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_deliver_cost_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_heading_product_name_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_heading_product_name_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_quantity_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_quantity_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_quantity_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_quantity_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_status_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_status_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_status_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_status_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_product_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_product_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_line_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_line_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_line_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_line_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_status_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_status_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_status_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_order_details_dispatch_status_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_products_value_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_products_value_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_products_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_products_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_delivery_cost_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_delivery_cost_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_delivery_cost_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_delivery_cost_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_delivery_date_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_delivery_date_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_delivery_date_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_delivery_date_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_letter_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_dispatch_letter_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_dispatch_letter_value_link:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  customer_data_order_details_dispatch_letter_value_typo:
    themeScheme.TypoIdScheme.default("typo$$10").catch("typo$$10"),
  customer_data_order_details_order_value_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_value_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_order_value_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_order_details_order_value_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_order_details_cancel_dispatch_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  popup_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_yes_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  popup_no_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_order_details_repeat_payment_redirect_link:
    themeScheme.UrlScheme.default("/").catch("/"),
});
