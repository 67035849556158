import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const BoxSummaryB2BMessagesScheme = z.object({
  basket_success_single_order_title_label:
    themeScheme.MessageScheme.default("DANE ZAMÓWIENIA"),
  basket_success_single_order_value_netto_label:
    themeScheme.MessageScheme.default("Wartość zamówienia netto"),
  basket_success_single_order_value_gross_label:
    themeScheme.MessageScheme.default("Wartość zamówienia brutto"),
  basket_success_single_order_order_date_label:
    themeScheme.MessageScheme.default("Data zamównienia"),
  basket_success_single_order_delivery_date_label:
    themeScheme.MessageScheme.default("Data dostawy"),
  basket_success_single_order_payment_method_label:
    themeScheme.MessageScheme.default("Forma płatności"),
  basket_success_single_order_table_sku_label:
    themeScheme.MessageScheme.default("SKU"),
  basket_success_single_order_table_image_label:
    themeScheme.MessageScheme.default("Zdjęcie"),
  basket_success_single_order_table_product_name_label:
    themeScheme.MessageScheme.default("Nazwa produktu"),
  basket_success_single_order_table_unit_label:
    themeScheme.MessageScheme.default("Jednostka"),
  basket_success_single_order_table_product_added_label:
    themeScheme.MessageScheme.default("Dodane produkty"),
  basket_success_single_order_table_netto_value_label:
    themeScheme.MessageScheme.default("Wartość netto"),
  basket_success_single_order_table_gross_value_label:
    themeScheme.MessageScheme.default("Wartość brutto"),
  basket_success__credit_bar_dropdown_credit_limit:
    themeScheme.MessageScheme.default("Limit kredytowy"),
  basket_success_credit_bar_dropdown_cash_on_delivery:
    themeScheme.MessageScheme.default("Płatność przy dostawie"),
  basket_success_single_order_table_netto_price_label:
    themeScheme.MessageScheme.default("Cena netto"),
  basket_success_single_order_table_gross_price_label:
    themeScheme.MessageScheme.default("Cena brutto"),
});

export const BoxSummaryB2BSettingsScheme = z.object({
  basket_success_page_orders_list_background_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  basket_success_page_orders_spacing:
    themeScheme.SizeCSSScheme.default("2px").catch("2px"),
  basket_success_text_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_header_section_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_header_section_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_data_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_data_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_data_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_data_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_data_typo2:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_data_color2:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_success_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_success_header_line_size:
    themeScheme.SizeCSSScheme.default("1px").catch("1px"),
  basket_success_header_line_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
