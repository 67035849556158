import { FilterOperators } from "./requestPayload.types";

export enum BoxTableDataEndpointType {
  CONTRACTORS = "CONTRACTORS",
  ORDERS = "ORDERS",
  ORDERS_B2B = "ORDERS_B2B",
  WISH_LIST = "WISH_LIST",
  LAST_SEEN = "LAST_SEEN",
  NONE = "NONE", //only for FE purposes
}

export interface GetColumnNamesResponse {
  columnNames: string[];
}

export interface GetColumnNamesRequest {
  endpoint: BoxTableDataEndpointType;
}

export interface GetTableDataRequest {
  filter: {
    name: string;
    operator: FilterOperators;
    value: string;
    orOperator: boolean;
  }[];
  page: {
    number: number;
    size: number;
  };
  sort: {
    name: string;
    desc: boolean;
  }[];
  projection: string[];
  endpointType: BoxTableDataEndpointType;
  caseSensitive: boolean;
}

export interface GetTableDataResponse {
  result: Record<string, string | number>[];
  page: {
    number: number;
    size: number;
    total: number;
    count: number;
  };
}
