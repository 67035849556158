import { z } from "zod";
import {
  BuzzEffectDetailsScheme,
  ColorEffectDetailsScheme,
  ColumnEffectsScheme,
  FadeEffectDetailsScheme,
  FlashEffectDetailsScheme,
  MoveEffectDetailsScheme,
  OverlayEffectDetailsScheme,
  PulseEffectDetailsScheme,
  RotateEffectDetailsScheme,
  RowEffectsScheme,
  ScaleEffectDetailsScheme,
  ShadowEffectDetailsScheme,
  SkewEffectDetailsScheme,
} from "./visualEffects.schemes";

export enum StickyRowEffectOption {
  NONE = "NONE",
  SLIDE_IN = "SLIDE_IN",
  PADDING = "PADDING",
  COLOR = "COLOR",
}

export enum EFFECT_VARIANTS {
  GROW = "GROW",
  SHRINK = "SHRINK",
  FORWARD = "FORWARD",
  BACKWARD = "BACKWARD",
  UP = "UP",
  RIGHT = "RIGHT",
  DOWN = "DOWN",
  LEFT = "LEFT",
  IN = "IN",
  OUT = "OUT",
}

type TBuzzEffectDetails = z.infer<typeof BuzzEffectDetailsScheme>;

type TPulseEffectDetails = z.infer<typeof PulseEffectDetailsScheme>;

type TSkewEffectDetails = z.infer<typeof SkewEffectDetailsScheme>;

type TMoveEffectDetails = z.infer<typeof MoveEffectDetailsScheme>;

type TFadeEffectDetails = z.infer<typeof FadeEffectDetailsScheme>;

type TFlashEffectDetails = z.infer<typeof FlashEffectDetailsScheme>;

type TRotateEffectDetails = z.infer<typeof RotateEffectDetailsScheme>;

type TScaleEffectDetails = z.infer<typeof ScaleEffectDetailsScheme>;

type TOverlayEffectDetails = z.infer<typeof OverlayEffectDetailsScheme>;

type TColorEffectDetails = z.infer<typeof ColorEffectDetailsScheme>;

type TShadowEffectDetails = z.infer<typeof ShadowEffectDetailsScheme>;

export type EffectDetail =
  | TBuzzEffectDetails
  | TPulseEffectDetails
  | TSkewEffectDetails
  | TMoveEffectDetails
  | TFadeEffectDetails
  | TFlashEffectDetails
  | TRotateEffectDetails
  | TScaleEffectDetails
  | TOverlayEffectDetails
  | TColorEffectDetails
  | TShadowEffectDetails;

export enum HoverRowColumnEffectOption {
  BUZZ = "BUZZ",
  PULSE = "PULSE",
  SKEW = "SKEW",
  MOVE = "MOVE",
  FADE = "FADE",
  FLASH = "FLASH",
  ROTATE = "ROTATE",
  SCALE = "SCALE",
  OVERLAY = "OVERLAY",
  COLOR = "COLOR",
  SHADOW = "SHADOW",
  NONE = "NONE",
}

export type TRowEffects = z.infer<typeof RowEffectsScheme>;
export type TColumnEffects = z.infer<typeof ColumnEffectsScheme>;
