import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  HoverEffects,
  IAlignment,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  TColorReadoutValue,
  TLinkReadoutValue,
  TTypoReadoutValue,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IImageContent,
  ILinkContent,
} from "../../settingsPatterns/contentPatterns.types";
import { PortalPath } from "../../shared/portalPath/portalPath";
import {
  BoxTilesSliderContentScheme,
  BoxTilesSliderContentSettingsScheme,
  BoxTilesSliderDisplaySettingsScheme,
} from "./BoxTilesSlider.scheme";

export type IBoxTilesSliderProps = IPortalBox<IBoxTilesSliderData> & {
  path: PortalPath;
  editMode: boolean;
  pathExtraSettings: string;
};

export type IBoxTilesSliderData = GenericBoxData<
  IBoxTilesSliderContent,
  IBoxTilesSliderContentSettings,
  IBoxTilesSliderDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export type IBoxTilesSliderContent = z.infer<
  typeof BoxTilesSliderContentScheme
>;

export interface ITileContent {
  id: string;
  image: IImageContent;
  title: string;
  slideUrl: ILinkContent;
  linkStyle: TLinkReadoutValue;
  seoDescription: string;
}

export interface ITileSettings {
  id: string;
  fill: boolean;
  image: {
    opacity: number;
    alignment: IAlignment;
    hoverEffect: HoverEffects;
  };
  titleStyle: TTypoReadoutValue;
  titleColor: TColorReadoutValue;
  titleAlignment: AlignmentHorizontalOption;
  titlePlacement: VerticalPosition;
  spaceBetweenTitleAndImage: number;
  show: ITileShow;
}

export interface ITileShow {
  image: boolean;
  title: boolean;
  link: boolean;
}

export type IBoxTilesSliderContentSettings = z.infer<
  typeof BoxTilesSliderContentSettingsScheme
>;

export type IBoxTilesSliderDisplaySettings = z.infer<
  typeof BoxTilesSliderDisplaySettingsScheme
>;

export enum TilesSliderRotateOptions {
  SINGLE_SLIDE = "SINGLE_SLIDE",
  SINGLE_TILE = "SINGLE_TILE",
  SMOOTHLY = "SMOOTHLY",
}
