import { useEffect, useState } from "react";

export const useWindowScrollPositions = (): {
  scrollX: number;
  scrollY: number;
} => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 });

  useEffect(() => {
    const scrollWrapper = document.getElementById("scroll-wrapper");
    if (!scrollWrapper) return;

    function updatePosition(evt: Event) {
      setPosition({
        scrollX: (evt.target as HTMLElement).scrollTop,
        scrollY: (evt.target as HTMLElement).scrollTop,
      });
    }

    scrollWrapper.addEventListener("scroll", updatePosition);

    return () => scrollWrapper.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollPosition;
};
