import { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

export interface IMetaData {
  title: string;
  description: string;
  keywords: string;
}
interface IMetaDataProps {
  metaData: IMetaData;
}

const MetaData: React.FC<IMetaDataProps> = ({
  metaData: { title, description, keywords },
}: IMetaDataProps): JSX.Element => {
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    const newSearchPhrase = router.query["phrase"] || "";
    setSearchPhrase(newSearchPhrase as string);
  }, [router.query, router.query["phrase"]]);

  return (
    <Head>
      {title && <title>{title + " " + searchPhrase}</title>}
      {description && (
        <meta name="description" content={description} key="desc" />
      )}
      {keywords && <meta name="keywords" content={keywords} />}
    </Head>
  );
};

export default MetaData;
