import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxContactFormMessagesScheme = z.object({
  contact_form_title: themeScheme.MessageScheme.default(
    "Określ i opisz temat, a nasz konsultant skontaktuje się z Tobą"
  ),
  contact_form_placeholder_topic:
    themeScheme.MessageScheme.default("Wpisz temat"),
  contact_form_heading_topic: themeScheme.MessageScheme.default("Wpisz temat"),
  contact_form_placeholder_name: themeScheme.MessageScheme.default("Imię"),
  contact_form_heading_name: themeScheme.MessageScheme.default("Imię"),
  contact_form_placeholder_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  contact_form_heading_surname: themeScheme.MessageScheme.default("Nazwisko"),
  contact_form_placeholder_email: themeScheme.MessageScheme.default("Email"),
  contact_form_heading_email: themeScheme.MessageScheme.default("Email"),
  contact_form_placeholder_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  contact_form_heading_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  contact_form_placeholder_textArea:
    themeScheme.MessageScheme.default("Treść wiadomości"),
  contact_form_heading_textArea:
    themeScheme.MessageScheme.default("Treść wiadomości"),
  contact_form_save_button:
    themeScheme.MessageScheme.default("Wyślij wiadomość"),
  contact_form_success: themeScheme.MessageScheme.default(
    "Formularz został wysłany"
  ),
  contact_form_failure: themeScheme.MessageScheme.default(
    "Nie udało się wysłać formularza"
  ),
  contact_form_empty_verification_topic: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_empty_verification_firstName: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_empty_verification_lastName: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_empty_verification_email: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_syntax_verification_email: themeScheme.MessageScheme.default(
    "Wprowadź poprawny adres e-mail, np. kowalski@domena.pl"
  ),
  contact_form_empty_verification_phone: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_verification_phone: themeScheme.MessageScheme.default(
    "Numer musi mieć od 9 do 13 cyfr"
  ),
  contact_form_empty_verification_textArea: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  contact_form_short_input_length_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono maksymalną ilość znaków (100)"
  ),
  contact_form_long_input_length_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono maksymalną ilość znaków (500)"
  ),
  contact_form_information: themeScheme.MessageScheme.default(
    'Administratorem Państwa danych osobowych jest ****** z siedzibą w Warszawie.<a href="#"> Państwa</a> dane przetwarzane będą w celu: rozwiązania spraw, z którym Państwo się do nas zwracacie, prowadzenia analiz i statystyk, wypełnienia obowiązków prawnych (w stosunku do zgłoszeń dotyczących takich obowiązków) oraz w celu dochodzenia lub obrony przed roszczeniami. W związku z przetwarzaniem przez *****. Państwa danych osobowych mają Państwo prawo do: sprostowania danych; dostępu do danych; usunięcia danych; ograniczenia przetwarzania; przenoszenia danych; sprzeciwu wobec przetwarzania danych ....'
  ),

  contact_form_heading_upload_photo: themeScheme.MessageScheme.default(
    "Zdjęcie uszkodzenia lub wady"
  ),
  contact_form_upload_file_button:
    themeScheme.MessageScheme.default("Wybierz plik"),
  contact_form_upload_file_delete: themeScheme.MessageScheme.default("Usuń"),
  contact_form_upload_file_error: themeScheme.MessageScheme.default(
    "Możesz dodać max. 5 plików, w formacie: doc, pdf, jpg, png, gif, o łącznym rozmiarze do 10 MB"
  ),
  complaint_form_heading_reason: themeScheme.MessageScheme.default(
    "Przyczyna reklamacji"
  ),
  complaint_form_placeholder_reason: themeScheme.MessageScheme.default(
    "Przyczyna reklamacji"
  ),

  complaint_form_empty_verification_complaint_reason:
    themeScheme.MessageScheme.default("Uzupełnienie pola jest wymagane"),
  complaint_form_reason_input_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono maksymalną ilość znaków (50)"
  ),
  complaint_form_heading_nip: themeScheme.MessageScheme.default("NIP"),
  complaint_form_placeholder_nip: themeScheme.MessageScheme.default("NIP"),
  form_syntax_verification_nip:
    themeScheme.MessageScheme.default("Niepoprawny nip"),
  complaint_form_heading_address:
    themeScheme.MessageScheme.default("Adres zwrotny"),
  complaint_form_placeholder_address:
    themeScheme.MessageScheme.default("Adres zwrotny"),
  complaint_form_empty_verification_return_adress:
    themeScheme.MessageScheme.default("Uzupełnienie pola jest wymagane"),
  complaint_form_return_adress_input_exceeded:
    themeScheme.MessageScheme.default(
      "Przekroczono maksymalną ilość znaków (100)"
    ),
  complaint_form_invoice_number_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono maksymalną ilość znaków (100)"
  ),
  complaint_form_heading_invoice_number: themeScheme.MessageScheme.default(
    "Numer faktury zakupowej"
  ),
  complaint_form_placeholder_invoice_number: themeScheme.MessageScheme.default(
    "Numer faktury zakupowej"
  ),
});

export const IBoxContactFormSettingsScheme = z.object({
  contact_form_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  contact_form_elements_gap:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  contact_form_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  contact_form_submit_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  contact_form_submit_button_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  contact_form_submit_button_bottom_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  contact_form_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  contact_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contact_form_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  contact_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  contact_form_success_info_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  contact_form_success_info_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contact_form_textArea_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  contact_form_error_info_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  contact_form_error_info_text_color:
    themeScheme.ColorIdScheme.default("color$$4").catch("color$$4"),
  contact_form_terms_spacing:
    themeScheme.SizeCSSScheme.default("130px").catch("130px"),
  contact_form_background_error_color:
    themeScheme.ColorIdScheme.default("color$$12").catch("color$$12"),
  contact_form_background_success_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  contact_form_information_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contact_form_information_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  contact_form_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  contact_form_link_basic_color:
    themeScheme.ColorIdScheme.default("color$$4").catch("color$$4"),
  contact_form_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  //
  file_upload: themeScheme.SettingBooleanScheme.default("false").catch("false"),
  contact_form_heading_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  contact_form_heading_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contact_form_upload_file_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  contact_form_upload_file_name_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  contact_form_upload_file_name_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contact_form_upload_file_delete_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  contact_form_upload_file_delete_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  contact_form_upload_file_gap:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
});
