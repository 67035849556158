import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import { PageWithBoxesPageBuilder, TPlatform } from "@ecp-pageTypes";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { eventProductDetails } from "../events";
import { mapPlatformToSymbol } from "@ecp-boxes/helpers/helpers";
import { IProductDetailsEvent } from "../schema";
import { useGetDataPage } from "@ecp-boxes/helpers/pageContext";

interface IUseProductDetailsEvent {
  deps?: unknown[];
  platform: TPlatform;
}

export const useProductDetailsEvent = ({
  platform,
  deps = [],
}: IUseProductDetailsEvent) => {
  const { getSessionStorageItem } = useSetSessionStorage();
  const prevProductDetailsEvent = useRef<Partial<IProductDetailsEvent> | null>(
    null
  );
  const {
    dataPage: {
      page: { name, url, type, productCategoryCode, productSku },
    },
  } = useGetDataPage() ?? { dataPage: { page: {} } };
  useEffect(() => {
    const email = getSessionStorageItem("email");

    const customerId =
      parseInt(getSessionStorageItem("customer_id") as string) || null;

    if ((name || url) && type === "PRODUCT") {
      const productDetailsEvent: Partial<IProductDetailsEvent> = {
        category_id: productCategoryCode,
        product_id: productSku,
        customer_id: customerId,
        email,
        site_type: mapPlatformToSymbol(platform),
      };
      if (!isEqual(prevProductDetailsEvent.current, productDetailsEvent)) {
        eventProductDetails(productDetailsEvent);
        prevProductDetailsEvent.current = productDetailsEvent;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, name, url, type]);
};
