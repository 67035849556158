import { createContext, useContext, useState } from "react";

export const LayoutControllerContext = createContext<{
  lastClickedId: string | null;
  boxSliderActiveId: string | null;
  setLastClickedId: (id: string | null) => void;
}>({
  lastClickedId: null,
  boxSliderActiveId: null,
  setLastClickedId: () => {},
});

export function useLayoutControllerContext() {
  const context = useContext(LayoutControllerContext);
  if (!context) {
    throw new Error(
      "useLayoutControllerContext must be used under LayoutControllerContext.Provider"
    );
  }
  return context;
}

export const LayoutControllerProvider = ({
  children,
  boxSliderActiveId,
}: {
  children: React.ReactNode;
  boxSliderActiveId: string | null;
}) => {
  const [lastClickedId, setLastClickedId] = useState<string | null>(null);

  return (
    <LayoutControllerContext.Provider
      value={{
        boxSliderActiveId,
        lastClickedId,
        setLastClickedId,
      }}
    >
      {children}
    </LayoutControllerContext.Provider>
  );
};
