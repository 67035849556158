import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { SizeCSSScheme } from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  ButtonActionOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMiniCartB2BMessagesScheme = z.object({
  mini_cart_order_header_label: themeScheme.MessageScheme.default("Zamówienie"),
  mini_cart_header_label: themeScheme.MessageScheme.default("Twój koszyk"),
  mini_cart_summary_items_sufix: themeScheme.MessageScheme.default("art."),
  mini_cart_header_summary_items_sufix:
    themeScheme.MessageScheme.default("art."),
  mini_cart_product_qty_sufix: themeScheme.MessageScheme.default("szt."),

  mini_cart_header_summary_orders_sufix:
    themeScheme.MessageScheme.default("zam."),
  mini_cart_gross_price_label: themeScheme.MessageScheme.default("brutto"),
  mini_cart_netto_price_label: themeScheme.MessageScheme.default("netto"),
  mini_cart_header_items_label: themeScheme.MessageScheme.default(""),
  mini_cart_logistic_bar_minimum_left_label:
    themeScheme.MessageScheme.default("zostało"),
  mini_cart_logistic_bar_minimum_label:
    themeScheme.MessageScheme.default("Min. logistyczne:"),
  mini_cart_product_gross_price_label:
    themeScheme.MessageScheme.default("brutto"),
  mini_cart_product_netto_price_label:
    themeScheme.MessageScheme.default("netto"),
  mini_cart_button_to_cart_label:
    themeScheme.MessageScheme.default("Przejdź do koszyka"),
  mini_cart_summary_credit_bar_error_usage: themeScheme.MessageScheme.default(
    "Limit kredytowy został przekroczony. Zmniejsz wartość koszyka lub wybierz inną formę płatności."
  ),
  mini_cart_summary_credit_bar_error_restricted:
    themeScheme.MessageScheme.default("Twój status konta jest ograniczony."),
  mini_cart_credit_bar_left_label:
    themeScheme.MessageScheme.default("pozostało"),
  mini_cart_credit_bar_usage_label:
    themeScheme.MessageScheme.default("wykorzystane"),
  mini_cart_credit_bar_label:
    themeScheme.MessageScheme.default("Limit kredytowy:"),
});

export const IBoxMiniCartB2BSettingsScheme = z.object({
  mini_cart_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_min_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_min_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_price_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_old_price_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_old_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_single_item_items_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_items_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_order_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_order_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_icon_URL: themeScheme.UrlScheme.default("").catch(""),
  mini_cart_icon_size: SizeCSSScheme.default("24px").catch("24px"),
  mini_cart_icon_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  mini_cart_dropped_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  mini_cart_qty_object_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_qty_object_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_qty_object_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_action_on_icon: z
    .nativeEnum(ButtonActionOption)
    .default(ButtonActionOption.CLICK)
    .catch(ButtonActionOption.CLICK),
  mini_cart_on_action_redirect_url:
    themeScheme.UrlScheme.default("/").catch("/"),
  mini_cart_gross_amount_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_gross_amount_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_net_amount_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_net_amount_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_header_items_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_header_items_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_single_order_items_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_order_items_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_progress_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_progress_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_progress_success_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_left_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_logistic_bar_left_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_single_item_gross_price_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_gross_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_single_item_net_price_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_single_item_net_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_orders_separator_line_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_orders_separator_line_size:
    themeScheme.SizeCSSScheme.default("1px").catch("1px"),
  mini_cart_credit_bar_error_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_credit_bar_error_alert_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_credit_bar_error_alert_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_button_to_cart_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  mini_cart_button_redirect_URL: themeScheme.UrlScheme.default("/").catch("/"),
  mini_cart_summary_credit_bar_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_summary_credit_bar_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_summary_credit_bar_usage_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_summary_credit_bar_usage_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_summary_credit_bar_usage_amount_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_summary_credit_bar_usage_amount_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_summary_credit_bar_left_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_summary_credit_bar_left_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mini_cart_summary_credit_bar_left_amount_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_summary_credit_bar_left_amount_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
});
