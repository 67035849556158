import { mapPlatformToSymbol } from "@ecp-boxes/helpers/helpers";
import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { IViewPageEvent } from "../schema";
import { eventViewPage } from "../events";
import { TPlatform } from "@ecp-pageTypes";
import { useGetDataPage } from "@ecp-boxes/helpers/pageContext";

interface IUseViewPageEvent {
  platform: TPlatform;
  deps?: unknown[];
}

export const useViewPageEvent = ({
  platform,
  deps = [],
}: IUseViewPageEvent) => {
  const { getSessionStorageItem, setSessionStorageItem } =
    useSetSessionStorage();
  const prevViewPageEvent = useRef<Partial<IViewPageEvent | null>>(null);
  const {
    dataPage: {
      page: { name, url },
    },
  } = useGetDataPage() ?? { dataPage: { page: {} } };

  useEffect(() => {
    const email = getSessionStorageItem("email");
    setSessionStorageItem("site_type", mapPlatformToSymbol(platform));

    const customerId =
      parseInt(getSessionStorageItem("customer_id") as string) || null;

    if (name || url) {
      const viewPageEvent: Partial<IViewPageEvent> = {
        page_name: name,
        url,
        customer_id: customerId,
        email,
        site_type: mapPlatformToSymbol(platform),
      };

      if (!isEqual(prevViewPageEvent.current, viewPageEvent)) {
        eventViewPage(viewPageEvent);
        prevViewPageEvent.current = viewPageEvent;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, name, url]);
};
