import styled from "styled-components";
import { ISpacing } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const StyledSlotWrapper = styled.div<{
  slotColumns: number;
  margins: ISpacing | undefined;
}>(
  ({ slotColumns, margins }) => `
    grid-column: span ${slotColumns} / span ${slotColumns};
    margin: ${margins?.top ?? 0}px ${margins?.right ?? 0}px ${
    margins?.bottom ?? 0
  }px ${margins?.left ?? 0}px;
  `
);
