import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const BoxAddToCartMessagesScheme = z.object({
  product_button_add_to_cart:
    themeScheme.MessageScheme.default("Dodaj do koszyka"),
  product_button_add_to_success: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
});

export const BoxAddToCartSettingsScheme = z.object({
  product_button_add_to_cart_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  product_button_add_to_card_success_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  product_button_add_to_card_success_font_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  product_button_add_to_card_success_background_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  product_button_add_to_card_error_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  product_button_add_to_card_error_font_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  product_button_add_to_card_error_background_color:
    themeScheme.ColorIdScheme.default("color$$12").catch("color$$12"),
  product_button_add_to_card_product_id:
    themeScheme.SettingSkuScheme.default("").catch(""),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  add_to_cart_quantity:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
  add_to_cart_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
});
