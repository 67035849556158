import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxContractorsMessageScheme = z.object({
  contractor_quicksearch_placeholder:
    themeScheme.MessageScheme.default("czego szukasz?").catch("czego szukasz?"),
});

export const IBoxContractorsSettingsScheme = z.object({
  contractor_fullfield_radiobutton_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contractor_empty_radiobutton_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contractor_outline_radiobutton_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contractor_quicksearch_input_style:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  contractor_quicksearch_loop_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  contractor_dropdown_style:
    themeScheme.DropdownIdScheme.default("dropdown$$1").catch("dropdown$$1"),
});
