import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  ButtonActionOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMyAccountHeaderMessagesScheme = z.object({
  login_title: themeScheme.MessageScheme.default("Jesteś już użytkownikiem?"),
  login_button: themeScheme.MessageScheme.default("Zaloguj się"),
  register_title: themeScheme.MessageScheme.default("Jesteś tu pierwszy raz?"),
  register_button: themeScheme.MessageScheme.default("Załóż konto"),
  button_element_1: themeScheme.MessageScheme.default(
    "Jako pierwszy dowiesz się o promocjach"
  ),
  button_element_2: themeScheme.MessageScheme.default(
    "Wygodne śledzenie statusu zamówień"
  ),
  button_element_3: themeScheme.MessageScheme.default(
    "Łatwy dostęp do historii zamówień"
  ),
  button_dropdown_text: themeScheme.MessageScheme.default("Zaloguj się"),
});

export const IBoxMyAccountHeaderSettingsScheme = z.object({
  title_typo: themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  title_color: themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  login_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  register_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  element_1_button:
    themeScheme.ButtonIdScheme.default("button$$8").catch("button$$8"),
  element_2_button:
    themeScheme.ButtonIdScheme.default("button$$8").catch("button$$8"),
  element_3_button:
    themeScheme.ButtonIdScheme.default("button$$8").catch("button$$8"),
  login_background:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  register_background:
    themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  gap: themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  title_button_gap: themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  button_dropdown:
    themeScheme.ButtonIdScheme.default("button$$8").catch("button$$8"),
  login_url: themeScheme.UrlScheme.default("/login").catch("/login"),
  register_url: themeScheme.UrlScheme.default("/register").catch("/register"),
  action_on_icon: z
    .nativeEnum(ButtonActionOption)
    .default(ButtonActionOption.CLICK)
    .catch(ButtonActionOption.CLICK),
  dropped_container_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  button_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
});
