import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxAddToCartQuantityB2BMessagesScheme = z.object({
  add_to_cart_quantity_minus: themeScheme.MessageScheme.default("-"),
  add_to_cart_quantity_plus: themeScheme.MessageScheme.default("+"),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
});

export const IBoxAddToCartQuantityB2BSettingsScheme = z.object({
  add_to_cart_quantity_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  add_to_cart_quantity_input_style:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  remove_from_cart_quantity_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  add_to_cart_quantity_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  add_to_cart_quantity_maks_value:
    themeScheme.SettingNumberScheme.default("100").catch("100"),
  add_to_cart_spacing: themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  b2b_add_to_cart_unit:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
});
