import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxAddToCartQuantityContentMessagesScheme = z.object({
  add_to_cart_quantity_minus: themeScheme.MessageScheme.default("-"),
  add_to_cart_quantity_plus: themeScheme.MessageScheme.default("+"),
});

export const IBoxAddToCartQuantityContentSettingsScheme = z.object({
  add_to_cart_quantity_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  add_to_cart_quantity_input_style:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  add_to_cart_quantity_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  add_to_cart_quantity_maks_value:
    themeScheme.SettingNumberScheme.default("100").catch("100"),
  add_to_cart_spacing: themeScheme.SizeCSSScheme.default("5px").catch("5px"),
});
