export const EVENT = {
  ADD_TO_CART: "add_to_cart",
  REMOVE_FROM_CART: "remove_from_cart",
  VIEW_PAGE: "view_page",
  PRODUCT_DETAILS: "product_details",
  VIEW_CART: "view_cart",
  LISTING_TRACKER: "listing_tracker",
  SALES_TRACKING: "sales_tracking",
  TRANSACTION: "transaction",
} as const;
export type TEvent = ObjectValues<typeof EVENT>;

export const EVENT_ORIGIN = {
  BOX_PRODUCT_SLIDER: "box_product_slider",
  BOX_SEARCH_RESULTS: "box_search_results",
  BOX_SEARCH_RESULTS_B2B: "box_search_results_b2b",
  BOX_ADD_TO_CART: "box_add_to_cart",
  BOX_CART_STEP_ONE: "box_cart_step_one",
  BOX_MINI_CART: "box_mini_cart",
  BOX_B2B_ADD_TO_CART_QUANTITY: "box_b2b_add_to_cart_quantity",
  BOX_CART_B2B: "box_cart_b2b",
  BOX_GET_TABLE_DATA: "box_get_table_data",
};
export type TEventOrigin = ObjectValues<typeof EVENT_ORIGIN>;
