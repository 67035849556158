import styled, {
  css,
  FlattenSimpleInterpolation,
  useTheme,
} from "styled-components";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import composeCss, {
  alignmentsOptionsToViewport,
  closeButtonAlignment,
} from "../../settingsPatterns/composeCss";
import { convertButtonIdToValues } from "../../settingsPatterns/settingsPatterns.methods";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isPortalSide } from "../../helpers/helpers";
import { useRowPopup } from "./useRowPopup";

export const StyledRowPopup = styled.div<{
  $settings: IDisplaySettingsSection;
}>(({ $settings, theme }) => {
  const { popup, width } = $settings;
  const { isEditMode } = useRowPopup();
  const buttonValue = convertButtonIdToValues(
    popup.closeButtonStyle,
    theme?.globalObjects?.buttons
  );
  const isPortal = isPortalSide();
  const themeSettings = useTheme() as IThemeState;

  let conditionalStyles: FlattenSimpleInterpolation | string = "";

  if (!isPortal && isEditMode) {
    conditionalStyles = css`
      margin-top: 36px;
    `;
  } else if (isEditMode) {
    conditionalStyles = css`
      height: 100%;
      max-width: ${themeSettings.stylePages.pages.maxWidth}px;
    `;
  }

  return css`
    position: absolute;
    pointer-events: all;
    width: ${width.percent}%;
    z-index: calc(var(--zindex-row-popup) + 1);
    ${alignmentsOptionsToViewport(popup)}
    ${conditionalStyles}

    .close-btn {
      z-index: calc(var(--zindex-row-popup) + 2);
      position: absolute;
      ${closeButtonAlignment(popup)};
      ${composeCss.buttonSettings.icon(buttonValue.icon)};
    }
  `;
});
