import React from "react";
import dynamic from "next/dynamic";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { isB2BClient } from "@ecp-redux/helpers";
import { IBoxAccordionProps } from "./BoxAccordion/BoxAccordion.types";
import { IBoxAddToCartProps } from "./BoxAddToCart/BoxAddToCart.types";
import { IBoxAddToCartQuantityB2BProps } from "./BoxAddToCartB2B/BoxAddToCartQuantityB2B.types";
import { TBoxAddToCartQuantityProps } from "./BoxAddToCartQuantity/BoxAddToCartQuantity.types";
import { TBoxArticleContentProps } from "./BoxArticleContent/BoxArticleContent.types";
import { IBoxArticleListProps } from "./BoxArticleList/BoxArticleList.types";
import { IBoxB2BAddToCartUnitProps } from "./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit.types";
import { IBoxBannerProps } from "./BoxBanner/BoxBanner.types";
import { IBoxBreadcrumbProps } from "./BoxBreadcrumb/BoxBreadcrumb.types";
import { IBoxButtonProps } from "./BoxButton/BoxButton.types";
import { TBoxContactFormProps } from "./BoxContactForm/BoxContactForm.types";
import { IBoxContractorsProps } from "./BoxContractors/BoxContractors.types";
import { IBoxDropdownProps } from "./BoxDropdown/BoxDropdown.types";
import { IBoxGetDataProps } from "./BoxGetData/BoxGetData.types";
import { IBoxHtmlProps } from "./BoxHtml/BoxHtml.types";
import { IBoxImageProps } from "./BoxImage/BoxImage.types";
import { IBoxListOfButtonsProps } from "./BoxListOfButtons/BoxListOfButtons.types";
import { IBoxLoginProps } from "./BoxLogin/BoxLogin.types";
import { TBoxMiniCartProps } from "./BoxMiniCart/BoxMiniCart.types";
import { TBoxMiniCartB2BProps } from "./BoxMiniCartB2B/BoxMiniCartB2B.types";
import { IBoxMultiMenuProps } from "./BoxMultiMenu/BoxMultiMenu.types";
import { IBoxMyAccountConsentsProps } from "./BoxMyAccountConsents/BoxMyAccountConsents.types";
import { IBoxMyAccountHeaderProps } from "./BoxMyAccountHeader/BoxMyAccountHeader.types";
import { IBoxMyAccountMyAddressListProps } from "./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import { TBoxMyAccountMyDataProps } from "./BoxMyAccountMyData/BoxMyAccountMyData.types";
import { TBoxMyAccountMyOrdersProps } from "./BoxMyAccountMyOrders/BoxMyAccountMyOrders.types";
import { TBoxMyAccountMyOrdersDetailsProps } from "./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails.types";
import { TBoxNewsletterProps } from "./BoxNewsletter/BoxNewsletter.types";
import { IBoxPhotoGalleryProps } from "./BoxPhotoGallery/BoxPhotoGallery.types";
import { BoxProductAttributesProps } from "./BoxProductAttributes/BoxProductAttributes.types";
import { IBoxProductSliderProps } from "./BoxProductSlider/BoxProductSlider.types";
import { BoxProductVariantsProps } from "./BoxProductVariants/BoxProductVariants.types";
import { TBoxRegisterProps } from "./BoxRegister/BoxRegister.types";
import { TBoxRemindProps } from "./BoxRemind/BoxRemind.types";
import { IBoxRichTextProps } from "./BoxRichText/BoxRichText.types";
import { IBoxSearchBarProps } from "./BoxSearchBar/BoxSearchBar.types";
import { BoxSearchFiltersProps } from "./BoxSearchFilters/BoxSearchFilters.types";
import { BoxSearchResultsProps } from "./BoxSearchResults/BoxSearchResults.types";
import { IBoxSearchResultsB2BProps } from "./BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import { IBoxSliderProps } from "./BoxSlider/BoxSlider.types";
import { IBoxStoreLocatorProps } from "./BoxStoreLocator/BoxStoreLocator.types";
import { IBoxTableDataProps } from "./BoxTableData/BoxTableData.types";
import { IBoxTextProps } from "./BoxText/BoxText.types";
import { IBoxTilesSliderProps } from "./BoxTilesSlider/BoxTilesSlider.types";
import { IBoxVideoProps } from "./BoxVideo/BoxVideo.types";
import { IBoxCartStepOneProps } from "./Cart/BoxCartStepOne/BoxCartStepOne.types";
import { IBoxCartStepTwoProps } from "./Cart/BoxCartStepTwo/BoxCartStepTwo.types";
import { IBoxOrderSummaryProps } from "./Cart/BoxOrderSummary/BoxOrderSummary.types";
import { IBoxCartB2BProps } from "./CartB2B/BoxCartB2B/BoxCartB2B.types";
import { IBoxSummaryB2BProps } from "./CartB2B/BoxSummaryB2B/BoxSummaryB2B.types";
import { IBoxCompareProps } from "./BoxCompare/BoxCompare.types";
import { IBoxCompareHeaderProps } from "./BoxCompareHeader/BoxCompareHeader.types";

const BoxAccordion = dynamic(() => import("./BoxAccordion/BoxAccordion"), {
  ssr: true,
});

const BoxAddToCart = dynamic(() => import("./BoxAddToCart/BoxAddToCart"), {
  ssr: true,
});

const BoxAddToCartQuantityB2B = dynamic(
  () => import("./BoxAddToCartB2B/BoxAddToCartQuantityB2B"),
  {
    ssr: true,
  }
);

const BoxAddToCartQuantity = dynamic(
  () => import("./BoxAddToCartQuantity/BoxAddToCartQuantity"),
  {
    ssr: true,
  }
);

const BoxArticleContent = dynamic(
  () => import("./BoxArticleContent/BoxArticleContent"),
  {
    ssr: true,
  }
);

const BoxArticleList = dynamic(
  () => import("./BoxArticleList/BoxArticleList"),
  {
    ssr: true,
  }
);

const BoxB2BAddToCartUnit = dynamic(
  () => import("./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit"),
  {
    ssr: true,
  }
);

const BoxBanner = dynamic(() => import("./BoxBanner/BoxBanner"), {
  ssr: true,
});

const BoxBreadcrumb = dynamic(() => import("./BoxBreadcrumb/BoxBreadcrumb"), {
  ssr: true,
});

const BoxButton = dynamic(() => import("./BoxButton/BoxButton"), {
  ssr: true,
});

const BoxContactForm = dynamic(
  () => import("./BoxContactForm/BoxContactForm"),
  {
    ssr: true,
  }
);

const BoxContractors = dynamic(
  () => import("./BoxContractors/BoxContractors"),
  {
    ssr: true,
  }
);

const BoxCompare = dynamic(() => import("./BoxCompare/BoxCompare"), {
  ssr: true,
});

const BoxCompareHeader = dynamic(
  () => import("./BoxCompareHeader/BoxCompareHeader"),
  {
    ssr: true,
  }
);

const BoxDropdown = dynamic(() => import("./BoxDropdown/BoxDropdown"), {
  ssr: true,
});

const BoxGetData = dynamic(() => import("./BoxGetData/BoxGetData"), {
  ssr: true,
});

const BoxHtml = dynamic(() => import("./BoxHtml/BoxHtml"), {
  ssr: true,
});

const BoxImage = dynamic(() => import("./BoxImage/BoxImage"), {
  ssr: true,
});

const BoxListOfButtons = dynamic(
  () => import("./BoxListOfButtons/BoxListOfButtons"),
  {
    ssr: true,
  }
);

const BoxLogin = dynamic(() => import("./BoxLogin/BoxLogin"), {
  ssr: true,
});

const BoxMiniCart = dynamic(() => import("./BoxMiniCart/BoxMiniCart"), {
  ssr: true,
});

const BoxMiniCartB2B = dynamic(
  () => import("./BoxMiniCartB2B/BoxMiniCartB2B"),
  {
    ssr: true,
  }
);

const BoxMultiMenu = dynamic(() => import("./BoxMultiMenu/BoxMultiMenu"), {
  ssr: true,
});

const BoxMyAccountConsents = dynamic(
  () => import("./BoxMyAccountConsents/BoxMyAccountConsents"),
  {
    ssr: true,
  }
);

const BoxMyAccountHeader = dynamic(
  () => import("./BoxMyAccountHeader/BoxMyAccountHeader"),
  {
    ssr: true,
  }
);

const BoxMyAccountMyAddressList = dynamic(
  () => import("./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList"),
  {
    ssr: true,
  }
);

const BoxMyAccountMyData = dynamic(
  () => import("./BoxMyAccountMyData/BoxMyAccountMyData"),
  {
    ssr: true,
  }
);

const BoxMyAccountMyOrders = dynamic(
  () => import("./BoxMyAccountMyOrders/BoxMyAccountMyOrders"),
  {
    ssr: true,
  }
);

const BoxMyOrderProductDelivery = dynamic(
  () => import("./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails"),
  {
    ssr: true,
  }
);

const BoxNewsletter = dynamic(() => import("./BoxNewsletter/BoxNewsletter"), {
  ssr: true,
});

const BoxPhotoGallery = dynamic(
  () => import("./BoxPhotoGallery/BoxPhotoGallery"),
  {
    ssr: true,
  }
);

const BoxProductAttributes = dynamic(
  () => import("./BoxProductAttributes/BoxProductAttributes"),
  {
    ssr: true,
  }
);

const BoxProductSlider = dynamic(
  () => import("./BoxProductSlider/BoxProductSlider"),
  {
    ssr: true,
  }
);

const BoxProductVariants = dynamic(
  () => import("./BoxProductVariants/BoxProductVariants"),
  {
    ssr: true,
  }
);

const BoxRegister = dynamic(() => import("./BoxRegister/BoxRegister"), {
  ssr: true,
});

const BoxRemind = dynamic(() => import("./BoxRemind/BoxRemind"), {
  ssr: true,
});

const BoxRichText = dynamic(() => import("./BoxRichText/BoxRichText"), {
  ssr: true,
});

const BoxSearchBar = dynamic(() => import("./BoxSearchBar/BoxSearchBar"), {
  ssr: true,
});

const BoxSearchFilters = dynamic(
  () => import("./BoxSearchFilters/BoxSearchFilters"),
  {
    ssr: true,
  }
);

const BoxSearchResults = dynamic(
  () => import("./BoxSearchResults/BoxSearchResults"),
  {
    ssr: true,
  }
);

const BoxSearchResultsB2B = dynamic(
  () => import("./BoxSearchResultsB2B/BoxSearchResultsB2B"),
  {
    ssr: true,
  }
);

const BoxSlider = dynamic(() => import("./BoxSlider/BoxSlider"), {
  ssr: true,
});

const BoxStoreLocator = dynamic(
  () => import("./BoxStoreLocator/BoxStoreLocator"),
  {
    ssr: true,
  }
);

const BoxTableData = dynamic(() => import("./BoxTableData/BoxTableData"), {
  ssr: true,
});

const BoxText = dynamic(() => import("./BoxText/BoxText"), {
  ssr: true,
});

const BoxTilesSlider = dynamic(
  () => import("./BoxTilesSlider/BoxTilesSlider"),
  {
    ssr: true,
  }
);

const BoxVideo = dynamic(() => import("./BoxVideo/BoxVideo"), {
  ssr: true,
});

const BoxCartStepOne = dynamic(
  () => import("./Cart/BoxCartStepOne/BoxCartStepOne"),
  {
    ssr: true,
  }
);

const BoxCartStepTwo = dynamic(
  () => import("./Cart/BoxCartStepTwo/BoxCartStepTwo"),
  {
    ssr: true,
  }
);

const BoxOrderSummary = dynamic(
  () => import("./Cart/BoxOrderSummary/BoxOrderSummary"),
  {
    ssr: true,
  }
);

const BoxCartB2B = dynamic(() => import("./CartB2B/BoxCartB2B/BoxCartB2B"), {
  ssr: true,
});

const BoxSummaryB2B = dynamic(
  () => import("./CartB2B/BoxSummaryB2B/BoxSummaryB2B"),
  {
    ssr: true,
  }
);

interface ISystemBoxTypes {
  MYACCOUNT_CONSENTS: React.ElementType<IBoxMyAccountConsentsProps>;
  MYACCOUNT_HEADER: React.ElementType<IBoxMyAccountHeaderProps>;
  LOGIN: React.ElementType<IBoxLoginProps>;
  REGISTER: React.ElementType<TBoxRegisterProps>;
  CONTACT_FORM: React.ElementType<TBoxContactFormProps>;
  REMIND_PASSWORD: React.ElementType<TBoxRemindProps>;
  PHOTO_GALLERY: React.ElementType<IBoxPhotoGalleryProps>;
  ARTICLE_CONTENT: React.ElementType<TBoxArticleContentProps>;
  CART_STEP_ONE: React.ElementType<IBoxCartStepOneProps>;
  CART_STEP_TWO: React.ElementType<IBoxCartStepTwoProps>;
  NEWSLETTER: React.ElementType<TBoxNewsletterProps>;
  SEARCH_BAR: React.ElementType<IBoxSearchBarProps>;
  ORDER_SUMMARY: React.ElementType<IBoxOrderSummaryProps>;
  MYACCOUNT_MYORDERS: React.ElementType<TBoxMyAccountMyOrdersProps>;
  MYACCOUNT_ORDER_DETAILS: React.ElementType<TBoxMyAccountMyOrdersDetailsProps>;
  MYACCOUNT_MYDATA: React.ElementType<TBoxMyAccountMyDataProps>;
  MINI_CART: React.ElementType<TBoxMiniCartProps>;
  ADD_TO_CART: React.ElementType<IBoxAddToCartProps>;
  MYACCOUNT_ADDRESSES: React.ElementType<IBoxMyAccountMyAddressListProps>;
  STORE_LOCATOR: React.ElementType<IBoxStoreLocatorProps>;
  ADD_TO_CART_QUANTITY: React.ElementType<TBoxAddToCartQuantityProps>;
  DROPDOWN: React.ElementType<IBoxDropdownProps>;
  CONTRACTORS: React.ElementType<IBoxContractorsProps>;
  CART_B2B: React.ElementType<IBoxCartB2BProps>;
  B2B_ADD_TO_CART_QUANTITY: React.ElementType<IBoxAddToCartQuantityB2BProps>;
  B2B_CART_SUMMARY: React.ElementType<IBoxSummaryB2BProps>;
  B2B_ADD_TO_CART_UNIT: React.ElementType<IBoxB2BAddToCartUnitProps>;
  MINI_CART_B2B: React.ElementType<TBoxMiniCartB2BProps>;
  COMPARE_HEADER: React.ElementType<IBoxCompareHeaderProps>;
}

type TSystemBoxMappedType = {
  [key in TSystemBoxType]: ISystemBoxTypes[key];
};
interface IRegularBoxTypes {
  HTML: React.ElementType<IBoxHtmlProps>;
  MULTI_MENU: React.ElementType<IBoxMultiMenuProps>;
  SLIDER: React.ElementType<IBoxSliderProps>;
  TEXT: React.ElementType<IBoxTextProps>;
  IMAGE: React.ElementType<IBoxImageProps>;
  VIDEO: React.ElementType<IBoxVideoProps>;
  BANNER: React.ElementType<IBoxBannerProps>;
  PRODUCT_SLIDER: React.ElementType<IBoxProductSliderProps>;
  ARTICLE_LIST: React.ElementType<IBoxArticleListProps>;
  RICH_TEXT: React.ElementType<IBoxRichTextProps>;
  BREADCRUMB: React.ElementType<IBoxBreadcrumbProps>;
  BUTTON: React.ElementType<IBoxButtonProps>;
  SEARCH_RESULTS: React.ElementType<BoxSearchResultsProps>;
  SYSTEM: TSystemBoxMappedType;
  SEARCH_FILTERS: React.ElementType<BoxSearchFiltersProps>;
  PRODUCT_ATTRIBUTES: React.ElementType<BoxProductAttributesProps>;
  PRODUCT_VARIANT: React.ElementType<BoxProductVariantsProps>;
  GET_DATA: React.ElementType<IBoxGetDataProps>;
  ACCORDION: React.ElementType<IBoxAccordionProps>;
  LIST_OF_BUTTONS: React.ElementType<IBoxListOfButtonsProps>;
  GET_TABLE_DATA: React.ElementType<IBoxTableDataProps>;
  B2B_CART_SUMMARY: React.ElementType<IBoxSummaryB2BProps>;
  SUMMARY_B2B: React.ElementType<IBoxSummaryB2BProps>;
  SEARCH_RESULTS_B2B: React.ElementType<IBoxSearchResultsB2BProps>;
  TILES_SLIDER: React.ElementType<IBoxTilesSliderProps>;
  COMPARE: React.ElementType<IBoxCompareProps>;
}

type TRegularBoxMappedType = {
  [key in TRegularBoxType]: IRegularBoxTypes[key];
};

export const boxTypes: TRegularBoxMappedType = {
  TEXT: BoxText,
  IMAGE: BoxImage,
  SLIDER: BoxSlider,
  VIDEO: BoxVideo,
  HTML: BoxHtml,
  MULTI_MENU: BoxMultiMenu,
  RICH_TEXT: BoxRichText,
  BUTTON: BoxButton,
  SEARCH_RESULTS: BoxSearchResults,
  BANNER: BoxBanner,
  PRODUCT_SLIDER: BoxProductSlider,
  ARTICLE_LIST: BoxArticleList,
  SEARCH_FILTERS: BoxSearchFilters,
  BREADCRUMB: BoxBreadcrumb,
  PRODUCT_ATTRIBUTES: BoxProductAttributes,
  PRODUCT_VARIANT: BoxProductVariants,
  GET_DATA: BoxGetData,
  ACCORDION: BoxAccordion,
  LIST_OF_BUTTONS: BoxListOfButtons,
  GET_TABLE_DATA: BoxTableData,
  SEARCH_RESULTS_B2B: BoxSearchResultsB2B,
  TILES_SLIDER: BoxTilesSlider,
  COMPARE: BoxCompare,

  SYSTEM: {
    MYACCOUNT_CONSENTS: BoxMyAccountConsents,
    MYACCOUNT_HEADER: BoxMyAccountHeader,
    LOGIN: BoxLogin,
    REGISTER: BoxRegister,
    REMIND_PASSWORD: BoxRemind,
    PHOTO_GALLERY: BoxPhotoGallery,
    ARTICLE_CONTENT: BoxArticleContent,
    CART_STEP_ONE: BoxCartStepOne,
    CART_STEP_TWO: BoxCartStepTwo,
    NEWSLETTER: BoxNewsletter,
    SEARCH_BAR: BoxSearchBar,
    CONTACT_FORM: BoxContactForm,
    ORDER_SUMMARY: BoxOrderSummary,
    MYACCOUNT_MYORDERS: BoxMyAccountMyOrders,
    MYACCOUNT_ORDER_DETAILS: BoxMyOrderProductDelivery,
    MYACCOUNT_MYDATA: BoxMyAccountMyData,
    MINI_CART: BoxMiniCart,
    ADD_TO_CART: BoxAddToCart,
    MYACCOUNT_ADDRESSES: BoxMyAccountMyAddressList,
    STORE_LOCATOR: BoxStoreLocator,
    ADD_TO_CART_QUANTITY: BoxAddToCartQuantity,
    DROPDOWN: BoxDropdown,
    CONTRACTORS: BoxContractors,
    CART_B2B: BoxCartB2B,
    B2B_ADD_TO_CART_QUANTITY: BoxAddToCartQuantityB2B,
    B2B_CART_SUMMARY: BoxSummaryB2B,
    B2B_ADD_TO_CART_UNIT: BoxB2BAddToCartUnit,
    MINI_CART_B2B: BoxMiniCartB2B,
    COMPARE_HEADER: BoxCompareHeader,
  },
};

const disabledOnB2B = ["CART_STEP_ONE", "CART_STEP_TWO"];

const disabledOnB2C = [
  "CART_B2B",
  "B2B_ADD_TO_CART_QUANTITY",
  "B2B_CART_SUMMARY",
  "B2B_ADD_TO_CART_UNIT",
  "MINI_CART_B2B",
];

export const SYSTEM_BOX_NAMES = () =>
  Object.keys(boxTypes.SYSTEM).filter((key) => {
    if (isB2BClient()) {
      return !disabledOnB2B.includes(key);
    } else {
      return !disabledOnB2C.includes(key);
    }
  });
