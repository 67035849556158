import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const BoxCartB2BMessagesScheme = z.object({
  basket_warning_feed_message_no_license: themeScheme.MessageScheme.default(
    "Brak aktywnej koncesji na wybrane produkty."
  ),
  basket_warning_feed_message_change_quantity:
    themeScheme.MessageScheme.default(
      "Ilość produktów dostępnych na stock uległa zmianie."
    ),
  basket_warning_feed_message_contractor_status:
    themeScheme.MessageScheme.default(
      "Ze względu na status kontrahenta zablokowana została możliwość złożenia zamówienia."
    ),
  basket_warning_feed_message_delivery_changed:
    themeScheme.MessageScheme.default("Wybrana data dostawy uległa zmianie."),
  basket_warning_feed_message_credit_limit: themeScheme.MessageScheme.default(
    "Wartość limitu kredytowego nie pokrywa wartości zamówienia."
  ),
  basket_header_orders_count: themeScheme.MessageScheme.default("zam."),
  basket_header_products_count: themeScheme.MessageScheme.default("art."),
  basket_header_title_label: themeScheme.MessageScheme.default("Koszyk"),
  basket_single_order_products_count: themeScheme.MessageScheme.default("art."),
  basket_single_order_title_label:
    themeScheme.MessageScheme.default("Zamówienie"),
  basket_single_order_delivery_term_label: themeScheme.MessageScheme.default(
    "Wybierz termin dostawy"
  ),
  basket_single_order_delivery_term_day_mon_label:
    themeScheme.MessageScheme.default("Pon."),
  basket_single_order_delivery_term_day_tue_label:
    themeScheme.MessageScheme.default("Wt."),
  basket_single_order_delivery_term_day_wed_label:
    themeScheme.MessageScheme.default("Śr."),
  basket_single_order_delivery_term_day_thu_label:
    themeScheme.MessageScheme.default("Czw."),
  basket_single_order_delivery_term_day_fri_label:
    themeScheme.MessageScheme.default("Pt."),
  basket_single_order_delivery_term_day_sat_label:
    themeScheme.MessageScheme.default("Sob."),
  basket_single_order_delivery_term_day_sun_label:
    themeScheme.MessageScheme.default("Niedz."),
  basket_single_order_delivery_term_month_january_label:
    themeScheme.MessageScheme.default("Sty"),
  basket_single_order_delivery_term_month_february_label:
    themeScheme.MessageScheme.default("Lut"),
  basket_single_order_delivery_term_month_march_label:
    themeScheme.MessageScheme.default("Mar"),
  basket_single_order_delivery_term_month_april_label:
    themeScheme.MessageScheme.default("Kwi"),
  basket_single_order_delivery_term_month_may_label:
    themeScheme.MessageScheme.default("Maj"),
  basket_single_order_delivery_term_month_june_label:
    themeScheme.MessageScheme.default("Cze"),
  basket_single_order_delivery_term_month_july_label:
    themeScheme.MessageScheme.default("Lip"),
  basket_single_order_delivery_term_month_august_label:
    themeScheme.MessageScheme.default("Sie"),
  basket_single_order_delivery_term_month_september_label:
    themeScheme.MessageScheme.default("Wrz"),
  basket_single_order_delivery_term_month_october_label:
    themeScheme.MessageScheme.default("Paź"),
  basket_single_order_delivery_term_month_november_label:
    themeScheme.MessageScheme.default("Lis"),
  basket_single_order_delivery_term_month_december_label:
    themeScheme.MessageScheme.default("Gru"),
  basket_header_prices_switch_label:
    themeScheme.MessageScheme.default("Ceny w koszyku:"),
  basket_header_prices_switch_price_netto_label:
    themeScheme.MessageScheme.default("Netto"),
  basket_header_prices_switch_price_gross_label:
    themeScheme.MessageScheme.default("Brutto"),
  basket_logistic_bar_minimum_label:
    themeScheme.MessageScheme.default("Min. logistyczne:"),
  basket_logistic_bar_minimum_left_label:
    themeScheme.MessageScheme.default("zostało"),
  basket_summary_label: themeScheme.MessageScheme.default("Podsumowanie"),
  basket_summary_gross_price_label:
    themeScheme.MessageScheme.default("Cena brutto:"),
  basket_summary_netto_price_label:
    themeScheme.MessageScheme.default("Cena netto:"),
  basket_summary_credit_bar_payment_method_label:
    themeScheme.MessageScheme.default("Metoda płatności:"),
  basket_summary_credit_bar_dropdown_credit_limit:
    themeScheme.MessageScheme.default("Limit kredytowy"),
  basket_summary_credit_bar_dropdown_cash_on_delivery:
    themeScheme.MessageScheme.default("Gotówka przy odbiorze"),
  basket_summary_credit_bar_left_label:
    themeScheme.MessageScheme.default("pozostało"),
  basket_summary_credit_bar_usage_label:
    themeScheme.MessageScheme.default("wykorzystane"),
  basket_summary_delivery_point_label:
    themeScheme.MessageScheme.default("Adresat:"),
  basket_summary_to_pay_label: themeScheme.MessageScheme.default("Do zapłaty:"),
  basket_summary_ordering_button_label:
    themeScheme.MessageScheme.default("Złóż zamówienie"),
  basket_single_order_table_sku_label: themeScheme.MessageScheme.default("SKU"),
  basket_single_order_table_image_label:
    themeScheme.MessageScheme.default("Zdjęcie"),
  basket_single_order_table_product_name_label:
    themeScheme.MessageScheme.default("Nazwa produktu"),
  basket_single_order_table_unit_label:
    themeScheme.MessageScheme.default("Jednostka"),
  basket_single_order_table_product_added_label:
    themeScheme.MessageScheme.default("Dodane produkty"),
  basket_single_order_table_netto_price_label:
    themeScheme.MessageScheme.default("Cena netto"),
  basket_single_order_table_netto_value_label:
    themeScheme.MessageScheme.default("Wartość netto"),
  basket_single_order_table_gross_price_label:
    themeScheme.MessageScheme.default("Cena brutto"),
  basket_single_order_table_gross_value_label:
    themeScheme.MessageScheme.default("Wartość brutto"),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  basket_deleting_popup_title: themeScheme.MessageScheme.default(
    "Czy na pewno chcesz wyczyścić koszyk?"
  ),
  order_deleting_popup_title: themeScheme.MessageScheme.default(
    "Czy na pewno chcesz usunąć zamówienie ?"
  ),
  basket_deleting_popup_description: themeScheme.MessageScheme.default(
    "Wszystkie dodane produkty zostaną usunięte."
  ),
  order_deleting_popup_description: themeScheme.MessageScheme.default(
    "Wybrane zamówienie zostanie usunięte."
  ),
  basket_deleting_popup_success_button_message:
    themeScheme.MessageScheme.default("Tak"),
  order_deleting_popup_success_button_message:
    themeScheme.MessageScheme.default("Tak"),
  basket_deleting_popup_cancel_button_message:
    themeScheme.MessageScheme.default("Nie"),
  order_deleting_popup_cancel_button_message:
    themeScheme.MessageScheme.default("Nie"),
  basket_summary_credit_bar_error_credit_limit_exceeded:
    themeScheme.MessageScheme.default("Limit kredytowy został przekroczony o"),
  basket_summary_credit_bar_error_account_limited:
    themeScheme.MessageScheme.default("Twój status konta jest ograniczony."),
  mobile_basket_remove_cart:
    themeScheme.MessageScheme.default("Wyczyść koszyk"),
});

export const BoxCartB2BSettingsScheme = z.object({
  basket_header_prices_switch_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_header_prices_switch_border_size:
    themeScheme.SizeCSSScheme.default("2px").catch("2px"),
  basket_header_prices_switch_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_header_prices_switch_drop_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_warning_feed_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_remove_icon_size:
    themeScheme.SizeCSSScheme.default("16px").catch("16px"),
  basket_remove_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_header_line_size:
    themeScheme.SizeCSSScheme.default("1px").catch("1px"),
  basket_header_line_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_orders_list_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_quantity_plus_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_single_order_quantity_input_style:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  basket_single_order_quantity_minus_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_single_order_unit_dropdown_style:
    themeScheme.DropdownIdScheme.default("dropdown$$1").catch("dropdown$$1"),
  basket_orders_list_spacing:
    themeScheme.SizeCSSScheme.default("17px").catch("17px"),
  basket_single_order_delivery_term_background_color_active:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_delivery_term_background_color_inactive:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_delivery_term_background_color_disabled:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_delivery_term_border_color_active:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_delivery_term_border_color_inactive:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_single_order_delivery_term_border_color_disabled:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_logistic_bar_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_logistic_bar_progress_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_logistic_bar_progress_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_logistic_bar_progress_success_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_column_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_credit_payment_method_droplist_style:
    themeScheme.DropdownIdScheme.default("dropdown$$1").catch("dropdown$$1"),
  basket_summary_credit_bar_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_credit_bar_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_credit_bar_progress_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_credit_bar_progress_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_credit_bar_progress_success_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_deleting_popup_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  order_deleting_popup_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_deleting_popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  order_deleting_popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_geo_icon_size:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
  basket_summary_geo_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_summary_ordering_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").default("snackbar$$1"),
  basket_deleting_popup_cancel_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  order_deleting_popup_cancel_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_deleting_popup_approve_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  order_deleting_popup_approve_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_empty_redirect_url:
    themeScheme.UrlScheme.default("/empty_cart").catch("/empty_cart"),
  basket_order_success_redirect_url:
    themeScheme.UrlScheme.default("/cart_summary").catch("/cart_summary"),
  basket_summary_credit_bar_error_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_text_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  stock_message_warning:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").default("snackbar$$1"),
  basket_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_header_section_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_header_section_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_data_header_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_deleting_popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  order_deleting_popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_deleting_popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  order_deleting_popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_data_header_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_data_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_data_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_data_typo2:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_data_color2:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  basket_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mobile_basket_data_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mobile_basket_data_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mobile_basket_data_label_background:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mobile_basket_remove_cart_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mobile_basket_remove_cart_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mobile_basket_remove_cart_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  mobile_basket_delivery_icon_URL: themeScheme.UrlScheme.default("").catch(""),
  mobile_basket_delivery_icon_size:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  mobile_basket_delivery_date_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  mobile_basket_delivery_date_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mobile_basket_delivery_edit_icon_URL:
    themeScheme.UrlScheme.default("").catch(""),
  mobile_basket_delivery_edit_icon_size:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
});
