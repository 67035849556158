import { memo, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { isEqual } from "lodash";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import styled, { DefaultTheme, css, useTheme } from "styled-components";
import { IPortalPage, SectionType } from "@ecp-pageTypes";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  isPortalSide,
  transformPageTypeToSectionType,
} from "../../helpers/helpers";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { CartB2BProvider } from "../../shared/components/domain/CartB2B/useCartB2BContext";
import { CommunicationBetweenBoxesProvider } from "../Contexts/CommunicationBetweenBoxes";
import { LayoutControllerProvider } from "../Contexts/LayoutControllerContext";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import RowPopupContainer from "../RowPopup/RowPopupContainer";
import { prepereSections } from "./DynamicPage.methods";
import { useTooltipContext } from "../Contexts/TooltipContext";
import { StyledTooltip } from "@ecp-boxes/shared/styleElements/StyledTooltip/StyledTooltip";

export interface IDynamicPageProps {
  data: IPortalPage;
  activePathExtraSettings: string;
}

export const StyledPage = styled.div<{
  theme: DefaultTheme;
  $isEditMode?: boolean;
}>(({ theme, $isEditMode = false }) => {
  return `
    background:  ${convertColorIdToHex(
      theme.stylePages.pages.page.background.color,
      theme.colorPalette
    )}};
    position: relative;
    height: 100%;
    
    ${
      !$isEditMode &&
      isPortalSide() &&
      !theme.stylePages.pages.headerFooter.stickyHeader
        ? "overflow-y: auto; height: 100vh;"
        : ""
    }
  `;
});

export const StyledMain = styled.main<{
  theme: DefaultTheme;
}>(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    margin: auto;
    row-gap: ${theme.stylePages.section.spacingBetweenSections}px;
    width: 100%;
    ${theme.advanceSettings.customCss};
  `;
});

const ScrollWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

const DynamicPage: React.FC<IDynamicPageProps> = ({
  data,
  activePathExtraSettings,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { asPath: url } = useRouter();
  const { googleRecaptchaSiteKey } = (useTheme() as IThemeState).advanceSettings
    .settings;
  const type = transformPageTypeToSectionType(data?.type);

  const { tooltipData } = useTooltipContext();

  const headerSections = data?.header?.sections;
  const footerSections = data?.footer?.sections;

  useEffect(() => {
    isPortalSide() &&
      scrollRef.current?.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
      });
  }, [url]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={
        googleRecaptchaSiteKey === "" ? " " : googleRecaptchaSiteKey
      }
    >
      <CartB2BProvider>
        <LayoutControllerProvider boxSliderActiveId={activePathExtraSettings}>
          <CommunicationBetweenBoxesProvider>
            <StyledPage className="dynamic-page">
              <ScrollWrapper id="scroll-wrapper">
                <RowPopupContainer />
                <div id="scrollTopElement" ref={scrollRef} />
                {headerSections && <Header sections={headerSections} />}

                <StyledTooltip
                  id={`tooltip-${tooltipData?.imageId}`}
                  $tooltipStyle={tooltipData?.tooltipStyle ?? "tooltip$$1"}
                  offset={tooltipData?.spacing}
                  isOpen={tooltipData !== null}
                >
                  {tooltipData?.label}
                </StyledTooltip>

                <StyledMain id="portal">
                  {data?.sections?.length > 0 &&
                    data.sections.reduce(
                      prepereSections(data?.sections, type ?? SectionType.MAIN),
                      []
                    )}
                </StyledMain>
                {footerSections && <Footer sections={footerSections} />}
              </ScrollWrapper>
            </StyledPage>
          </CommunicationBetweenBoxesProvider>
        </LayoutControllerProvider>
      </CartB2BProvider>
    </GoogleReCaptchaProvider>
  );
};

export default memo(DynamicPage, isEqual);
