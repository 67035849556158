import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { BoxTableDataEndpointType } from "@ecp-redux/dto/getDataTable";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import { GlobalTableCellReadoutValueScheme } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  Border_v2Scheme,
  ColorReadoutValueScheme,
  SpacingScheme,
  TypoReadoutValueScheme,
  defaultBorder,
} from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  TColorReadoutValue,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export type IBoxTableDataData = GenericBoxData<
  IBoxTableDataContent,
  IBoxTableDataContentSettings,
  IBoxTableDataDisplaySettings,
  IBoxTableDataSettings,
  IBoxTableDataMessages
>;

export type IBoxTableDataContent = z.infer<typeof BoxTableDataContentScheme>;

export type IBoxTableDataContentSettings = z.infer<
  typeof BoxTableDataContentSettingsScheme
>;

export enum TableDataColumnType {
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export const TableAttributeSettingsScheme = z
  .object({
    columnType: z
      .nativeEnum(TableDataColumnType)
      .default(TableDataColumnType.TEXT),
    translationText: z.string().default("label"),
    width: z.number().default(300),
    headerStyle: GlobalTableCellReadoutValueScheme,
    prefixText: z.string().default("prefix"),
    suffixText: z.string().default("suffix"),
    dataStyle: GlobalTableCellReadoutValueScheme,
    prefix: z.boolean().default(false),
    prefixTextStyle: TypoReadoutValueScheme,
    prefixColor: ColorReadoutValueScheme,
    suffix: z.boolean().default(false),
    suffixTextStyle: TypoReadoutValueScheme,
    suffixColor: ColorReadoutValueScheme,
    imageFit: z.boolean().default(false),
    alignment: z
      .object({
        horizontal: z
          .nativeEnum(AlignmentHorizontalOption)
          .default(AlignmentHorizontalOption.CENTER),
        vertical: z
          .nativeEnum(AlignmentVerticalOption)
          .default(AlignmentVerticalOption.CENTER),
      })
      .default({
        horizontal: AlignmentHorizontalOption.CENTER,
        vertical: AlignmentVerticalOption.CENTER,
      }),
    spaceBetweenPrefixSuffix: z.number().default(5),
  })
  .default({
    translationText: "label",
    prefixText: "prefix",
    suffixText: "suffix",
    columnType: TableDataColumnType.TEXT,
    width: 300,
    headerStyle: "tableCell$$1",
    dataStyle: "tableCell$$1",
    prefix: true,
    prefixTextStyle: { id: "typo$$1", custom: {} },
    prefixColor: { id: "color$$1", custom: "#FFFFFF" },
    suffix: true,
    suffixTextStyle: { id: "typo$$1", custom: {} },
    suffixColor: { id: "color$$1", custom: "#FFFFFF" },
    imageFit: false,
    alignment: {
      horizontal: AlignmentHorizontalOption.CENTER,
      vertical: AlignmentVerticalOption.CENTER,
    },
    spaceBetweenPrefixSuffix: 5,
  });

export const TableAttributeIdScheme = z.string();
export type ITableAttributeId = z.infer<typeof TableAttributeIdScheme>;
export type ITableAttributeData = z.infer<typeof TableAttributeSettingsScheme>;

export const BoxTableDataContentSettingsScheme = z
  .object({
    tableAttributes: z.record(TableAttributeSettingsScheme).default({}),
  })
  .default({ tableAttributes: {} });

export type IBoxTableDataDisplaySettings = z.infer<
  typeof BoxTableDataDisplaySettingsScheme
>;

export const BoxTableDataDisplaySettingsScheme = z
  .object({
    fixedTableSize: z.boolean().default(false),
    fixedWidth: z.number().default(1000),
    fixedMaxHeight: z.number().default(600),
    fixedMaxColumnWidth: z.number().default(400),
    autoMaxWidth: z.number().default(1000),
    autoMaxHeight: z.number().default(600),
    tableBackground: ColorReadoutValueScheme,
    tableBorder: Border_v2Scheme.default({
      radius: 0,
      width: { left: 1, right: 1, top: 1, bottom: 1 },
      style: "SOLID",
      color: {
        id: "color$$1",
        custom: "#FFFFFF",
      },
    }),
    padding: SpacingScheme.default({ top: 5, bottom: 5, left: 5, right: 5 }),
    columnsSpacing: z.number().default(5),
    rowsSpacing: z.number().default(5),
    spacingBetweenHeaderAndData: z.number().default(5),
    stickyHeader: z.boolean().default(true),
    headerHeight: z.number().default(80),
    rowHeight: z.number().default(60),
    sorting: z
      .object({
        show: z.boolean().default(true),
        iconColor: ColorReadoutValueScheme,
        iconColorActive: ColorReadoutValueScheme,
        iconSize: z.number().default(24),
      })
      .default({
        show: true,
        iconColor: { id: "color$$1", custom: "#FFFFFF" },
        iconColorActive: { id: "color$$1", custom: "#FFFFFF" },
        iconSize: 24,
      }),
    pagination: z
      .object({
        show: z.boolean().default(true),
        afterRows: z.number().default(100),
        alignment: z
          .object({
            horizontal: z
              .nativeEnum(AlignmentHorizontalOption)
              .default(AlignmentHorizontalOption.CENTER),
            vertical: z
              .nativeEnum(AlignmentVerticalOption)
              .default(AlignmentVerticalOption.CENTER),
          })
          .default({
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          }),
        textStyle: TypoReadoutValueScheme,
        textColor: ColorReadoutValueScheme,
      })
      .default({
        show: true,
        afterRows: 100,
        alignment: {
          horizontal: AlignmentHorizontalOption.CENTER,
          vertical: AlignmentVerticalOption.CENTER,
        },
        textStyle: { id: "typo$$1", custom: {} },
        textColor: { id: "color$$1", custom: "#FFFFFF" },
      }),
  })
  .default({
    fixedTableSize: false,
    fixedWidth: 1000,
    fixedMaxHeight: 600,
    fixedMaxColumnWidth: 400,
    autoMaxWidth: 1000,
    autoMaxHeight: 600,
    tableBackground: "color$$1",
    tableBorder: defaultBorder,
    padding: { top: 5, bottom: 5, left: 5, right: 5 },
    columnsSpacing: 10,
    rowsSpacing: 10,
    spacingBetweenHeaderAndData: 5,
    stickyHeader: true,
    headerHeight: 80,
    rowHeight: 60,
    sorting: {
      show: true,
      iconColor: { id: "color$$1", custom: "#FFFFFF" },
      iconColorActive: { id: "color$$1", custom: "#FFFFFF" },
      iconSize: 24,
    },
    pagination: {
      show: true,
      afterRows: 100,
      alignment: {
        horizontal: AlignmentHorizontalOption.CENTER,
        vertical: AlignmentVerticalOption.CENTER,
      },
      textStyle: { id: "typo$$1", custom: {} },
      textColor: { id: "color$$1", custom: "#FFFFFF" },
    },
  });

export const BoxTableDataContentScheme = z
  .object({
    contentType: z
      .nativeEnum(BoxTableDataEndpointType)
      .default(BoxTableDataEndpointType.NONE),
    paginationLabel: z.string().default("Pokaż na stronie"),
    columnsOrder: z.array(z.string()).default([]),
  })
  .default({
    paginationLabel: "Pokaż na stronie",
    columnsOrder: [],
    contentType: BoxTableDataEndpointType.NONE,
  });

export type IBoxTableDataProps = IPortalBox<IBoxTableDataData>;

export type IBoxTableDataSettings = z.infer<typeof BoxTableDataSettingsScheme>;

export type IBoxTableDataMessages = z.infer<typeof BoxTableDataMessagesScheme>;

export interface IPrefixSuffixSettings {
  textStyle: TTypoReadoutValue;
  textColor: TColorReadoutValue;
  label: string;
  show: boolean;
}

export const BoxTableDataSettingsScheme = z.object({
  srp_mobile_sorting_parameter_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_parameter_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  srp_mobile_sorting_parameter_top_background_color:
    themeScheme.ColorIdScheme.default("color$$4").catch("color$$4"),
  srp_mobile_sorting_parameter_value_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_sorting_parameter_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_sorting_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_expand_button_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  srp_mobile_expand_button_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});

export const BoxTableDataMessagesScheme = z.object({
  srp_mobile_sorting_label:
    themeScheme.MessageScheme.default("Sortuj").catch("Sortuj"),
  srp_mobile_sorting_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki").catch("Pokaż wyniki"),
  scoreSortText:
    themeScheme.MessageScheme.default("Polecane").catch("Polecane"),
  created_atSortText:
    themeScheme.MessageScheme.default("Najnowsze").catch("Najnowsze"),
  ascPriceSortText:
    themeScheme.MessageScheme.default("Najniższa cena").catch("Najniższa cena"),
  descPriceSortText:
    themeScheme.MessageScheme.default("Najwyższa cena").catch("Najwyższa cena"),
  srp_mobile_expand_button_label:
    themeScheme.MessageScheme.default("Sortowanie").catch("Sortowanie"),
});
