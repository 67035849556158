import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { VerticalPosition } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { FacetStyle, RANGE_DISPLAY_STYLE } from "./BoxSearchFilters.types";
import {
  createAccordionStyleSettingScheme,
  createInputStyleSettingScheme,
  createLinkStyleSettingScheme,
  createShowMoreTextStyleShowSettingScheme,
  createTypoStyleSettingScheme,
} from "../../settingsSchemes/settingsSchemes.utils";

export const IBoxSearchFiltersMessagesScheme = z.object({
  filters_mobile_header_label:
    themeScheme.MessageScheme.default("Kategorie i filtry"),
  filters_mobile_show_result_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki"),
  input_range_from: themeScheme.MessageScheme.default("Od:"),
  input_range_to: themeScheme.MessageScheme.default("Do:"),
  confirm_button_text: themeScheme.MessageScheme.default("OK"),
});

export const IBoxSearchFiltersSettingsScheme = z.object({
  filters_mobile_header_label_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  filters_mobile_header_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_header_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_categoryandfilters_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_close_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_clear_filters_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  filters_mobile_show_result_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
});

export const BoxSearchFiltersDisplaySettingsScheme = z
  .object({
    spaceBetweenFacets: z.number().default(5).catch(5),
    spaceBetweenCategoriesAndFilters: z.number().default(5).catch(5),
    facetNameStyle: themeScheme
      .createTextStyleShowSettingScheme("typo$$5", "color$$1")
      .default(themeScheme.textStyleShowSettingFallback("typo$$5", "color$$1"))
      .catch(themeScheme.textStyleShowSettingFallback("typo$$5", "color$$1")),
    facetValueStyle: themeScheme
      .createTextStyleShowSettingScheme("typo$$7", "color$$1")
      .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$1"))
      .catch(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$1")),
    facetStyle: z
      .object({
        displayType: z.nativeEnum(FacetStyle),
        accordionStyle: createAccordionStyleSettingScheme("accordion$$1"),
      })
      .default({
        displayType: FacetStyle.LIST,
        accordionStyle: createAccordionStyleSettingScheme("accordion$$1").parse(
          {}
        ),
      })
      .catch({
        displayType: FacetStyle.LIST,
        accordionStyle: createAccordionStyleSettingScheme("accordion$$1").parse(
          {}
        ),
      }),
    categoryLinkStyle: z
      .object({
        link: createLinkStyleSettingScheme("link$$1"),
        text: createTypoStyleSettingScheme("typo$$7"),
      })
      .default({
        link: createLinkStyleSettingScheme("link$$1").parse({}),
        text: createTypoStyleSettingScheme("typo$$7").parse({}),
      })
      .catch({
        link: createLinkStyleSettingScheme("link$$1").parse({}),
        text: createTypoStyleSettingScheme("typo$$7").parse({}),
      }),
    rangeStyle: z
      .object({
        rangeType: z
          .nativeEnum(RANGE_DISPLAY_STYLE)
          .default(RANGE_DISPLAY_STYLE.SLIDER_INPUTS)
          .catch(RANGE_DISPLAY_STYLE.SLIDER_INPUTS),
        pointerColor: themeScheme.ColorReadoutValueScheme.default({
          id: "color$$1",
          custom: "#000000",
        }).catch({ id: "color$$1", custom: "#000000" }),
        pointerWidth: z.number().default(20).catch(20),
        pointerHeight: z.number().default(20).catch(20),
        pointerRadius: z.number().default(50).catch(50),
        pointerBorderSize: z.number().default(1).catch(1),
        pointerBorderColor: themeScheme.ColorReadoutValueScheme.default({
          id: "color$$1",
          custom: "#000000",
        }).catch({ id: "color$$1", custom: "#000000" }),
        lineHeight: z.number().default(5).catch(5),
        emptyLineColor: themeScheme.ColorReadoutValueScheme.default({
          id: "color$$1",
          custom: "#000000",
        }).catch({ id: "color$$1", custom: "#000000" }),
        inputStyle: themeScheme.InputReadoutValueScheme,
        spaceBetweenInputs: z.number().default(10).catch(10),
        spaceBetweenPointerAndInputs: z.number().default(10).catch(10),
        spaceBetweenInputsAndButton: z.number().default(10).catch(10),
        showConfirmButton: z.boolean().default(true).catch(true),
        confirmButtonStyle: themeScheme.ButtonReadoutValueScheme.default(
          themeScheme.ButtonReadoutValueScheme.parse({})
        ).catch(themeScheme.ButtonReadoutValueScheme.parse({})),
      })
      .default({
        rangeType: RANGE_DISPLAY_STYLE.SLIDER_INPUTS,
        pointerColor: { id: "color$$1", custom: "#000000" },
        pointerWidth: 20,
        pointerHeight: 20,
        pointerRadius: 50,
        pointerBorderSize: 1,
        pointerBorderColor: { id: "color$$1", custom: "#000000" },
        lineHeight: 5,
        emptyLineColor: { id: "color$$1", custom: "#000000" },
        inputStyle: themeScheme.InputReadoutValueScheme.parse({}),
        spaceBetweenInputs: 10,
        spaceBetweenPointerAndInputs: 10,
        spaceBetweenInputsAndButton: 10,
        showConfirmButton: true,
        confirmButtonStyle: themeScheme.ButtonReadoutValueScheme.parse({}),
      }),
  })
  .default({
    spaceBetweenFacets: 5,
    spaceBetweenCategoriesAndFilters: 5,
    facetNameStyle: themeScheme.textStyleShowSettingFallback(
      "typo$$5",
      "color$$1"
    ),
    facetValueStyle: themeScheme.textStyleShowSettingFallback(
      "typo$$7",
      "color$$1"
    ),
    facetStyle: {
      displayType: FacetStyle.LIST,
      accordionStyle: createAccordionStyleSettingScheme("accordion$$1").parse(
        {}
      ),
    },
    categoryLinkStyle: {
      link: createLinkStyleSettingScheme("link$$1").parse({}),
      text: createTypoStyleSettingScheme("typo$$7").parse({}),
    },
    rangeStyle: {
      rangeType: RANGE_DISPLAY_STYLE.SLIDER_INPUTS,
      pointerColor: { id: "color$$1", custom: "#000000" },
      pointerWidth: 20,
      pointerHeight: 20,
      pointerRadius: 50,
      pointerBorderSize: 1,
      pointerBorderColor: { id: "color$$1", custom: "#000000" },
      lineHeight: 5,
      emptyLineColor: { id: "color$$1", custom: "#000000" },
      inputStyle: themeScheme.InputReadoutValueScheme.parse({}),
      spaceBetweenInputs: 10,
      spaceBetweenPointerAndInputs: 10,
      spaceBetweenInputsAndButton: 10,
      showConfirmButton: true,
      confirmButtonStyle: themeScheme.ButtonReadoutValueScheme.parse({}),
    },
  });

export const BoxSearchFiltersContentScheme = z
  .object({
    facetsSelection: z.array(z.any()).default([]).catch([]),
    facetsTitle: z.string().default("Filters").catch("Filters"),
    showMore: z.string().default("Show more").catch("Show more"),
    showLess: z.string().default("Show less").catch("Show less"),
    clear: z.string().default("Clear").catch("Clear"),
    categoriesTitle: z.string().default("Categories").catch("Categories"),
    searchPlaceholder: z.string().default("Search").catch("Search"),
  })
  .default({
    facetsSelection: [],
    facetsTitle: "Filters",
    showMore: "Show more",
    showLess: "Show less",
    clear: "Clear",
    categoriesTitle: "Categories",
    searchPlaceholder: "Search",
  });

export const BoxSearchFiltersContentSettingsScheme = z
  .object({
    title: themeScheme
      .createTextStyleShowSettingScheme("typo$$3", "color$$1")
      .default(themeScheme.textStyleShowSettingFallback("typo$$3", "color$$1"))
      .catch(themeScheme.textStyleShowSettingFallback("typo$$3", "color$$1")),
    showMore: createShowMoreTextStyleShowSettingScheme("typo$$7", "color$$1", 4)
      .default(
        createShowMoreTextStyleShowSettingScheme(
          "typo$$7",
          "color$$1",
          4
        ).parse({})
      )
      .catch(
        createShowMoreTextStyleShowSettingScheme(
          "typo$$7",
          "color$$1",
          4
        ).parse({})
      ),
    clear: themeScheme
      .createTextStyleShowSettingScheme("typo$$7", "color$$5")
      .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$5"))
      .catch(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$5")),
    count: themeScheme
      .createTextStyleShowSettingScheme("typo$$7", "color$$2")
      .default(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$2"))
      .catch(themeScheme.textStyleShowSettingFallback("typo$$7", "color$$2")),
    search: createInputStyleSettingScheme("input$$2")
      .default(createInputStyleSettingScheme("input$$2").parse({}))
      .catch(createInputStyleSettingScheme("input$$2").parse({})),
    categories: z
      .object({
        show: z.boolean().default(true).catch(true),
        position: z
          .nativeEnum(VerticalPosition)
          .default(VerticalPosition.TOP)
          .catch(VerticalPosition.TOP),
      })
      .default({ show: true, position: VerticalPosition.TOP })
      .catch({
        show: true,
        position: VerticalPosition.TOP,
      }),
  })
  .default({
    title: themeScheme.textStyleShowSettingFallback("typo$$3", "color$$1"),
    showMore: createShowMoreTextStyleShowSettingScheme(
      "typo$$7",
      "color$$1",
      4
    ).parse({}),
    clear: themeScheme.textStyleShowSettingFallback("typo$$7", "color$$5"),
    count: themeScheme.textStyleShowSettingFallback("typo$$7", "color$$2"),
    search: createInputStyleSettingScheme("input$$2").parse({}),
    categories: { show: true, position: VerticalPosition.TOP },
  });
