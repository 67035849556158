import { TTooltipReadoutValue } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { createContext, useContext, useMemo, useState } from "react";

interface ITooltipData {
  label: string;
  tooltipStyle: TTooltipReadoutValue;
  spacing: number;
  imageId: number;
}

interface ITooltipContext {
  tooltipData: ITooltipData | null;
  setTooltipData: (data: ITooltipData) => void;
  resetTooltipData: () => void;
}
export const TooltipContext = createContext({} as ITooltipContext);
export function useTooltipContext() {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error(
      "useTooltipContext must be used within TooltipContext.Provider"
    );
  }
  return context;
}
export const TooltipContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tooltipContextData, setTooltipContextData] =
    useState<ITooltipData | null>(null);

  const setTooltipData = (data: ITooltipData) => {
    setTooltipContextData(data);
  };
  const resetTooltipData = () => {
    setTooltipContextData(null);
  };
  const values = useMemo(
    () => ({
      tooltipData: tooltipContextData,
      setTooltipData,
      resetTooltipData,
    }),
    [tooltipContextData]
  );

  return (
    <TooltipContext.Provider value={values}>{children}</TooltipContext.Provider>
  );
};
