import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  AlignmentHorizontalOption,
  ISpacing,
  TButtonReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { PortalPath } from "../../shared/portalPath/portalPath";
import {
  TBoxListOfButtonsContent,
  TBoxListOfButtonsContentSettings,
  TBoxListOfButtonsDisplaySettings,
} from "./BoxListOfButtons.scheme";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { z } from "zod";

export type IBoxListOfButtonsData = GenericBoxData<
  TBoxListOfButtonsContent,
  TBoxListOfButtonsContentSettings,
  TBoxListOfButtonsDisplaySettings,
  IBoxListOfButtonsMessages,
  IBoxListOfButtonsSettings
>;

export type IBoxListOfButtonsProps = IPortalBox<IBoxListOfButtonsData> & {
  path: PortalPath;
};
export interface IBoxListOfButtonsContent {
  buttonsLabels: string[];
}

export interface IBoxListOfButtonsDisplaySettings {
  buttonsStyle: TButtonReadoutValue;
  boxWidth: number;
  spaceBetweenButtons: number;
  spaceBetweenRows: number;
  alignment: AlignmentHorizontalOption;
  padding: ISpacing;
  vertical: boolean;
}

export const BoxListOfButtonsMessagesScheme = z.object({
  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),

  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),

  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
});

export const BoxListOfButtonsSettingsScheme = z.object({
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
});

export type IBoxListOfButtonsMessages = z.infer<
  typeof BoxListOfButtonsMessagesScheme
>;
export type IBoxListOfButtonsSettings = z.infer<
  typeof BoxListOfButtonsSettingsScheme
>;
