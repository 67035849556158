import React, { useRef } from "react";
import { IPortalPublication } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Box from "../Box/Box";
import { StyledPublication } from "./Publication.styled";

export interface IPublicationProps {
  publicationData: IPortalPublication;
  isEditMode?: boolean;
  path: PortalPath;
  pathExtraSettings?: string;
}

const Publication: React.FC<IPublicationProps> = ({
  publicationData,
  isEditMode = true,
  path,
  pathExtraSettings,
}) => {
  const publicationRef = useRef<HTMLDivElement>(null);

  if (
    publicationData?.box === undefined ||
    publicationData.box.content === ({} as any)
  ) {
    // eslint-disable-next-line no-console
    console.log(`Publication ${publicationData?.id} has no box content!`);
    return null;
  }

  return (
    <StyledPublication
      ref={publicationRef}
      boxType={publicationData.box.type}
      alignment={publicationData.alignment}
      publicationWidth={publicationData.width}
      data-testid="publication"
      id={`PUBLICATION=${publicationData.id}`}
    >
      <Box
        boxData={publicationData.box}
        isEditMode={isEditMode}
        publicationRef={publicationRef}
        path={path}
        pathExtraSettings={pathExtraSettings}
      />
    </StyledPublication>
  );
};

export default Publication;
