import { IPortalSection, SectionType } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Section from "../Section/Section";

export const prepereSections =
  (sections: IPortalSection[], type: SectionType) =>
  (
    readyJsxSections: JSX.Element[],
    currentSection: IPortalSection,
    index: number
  ): JSX.Element[] => {
    const nextSection = sections[index + 1];
    // if next sections has mobileSubSection=true, then into the current section insert mobileSlots
    if (nextSection && nextSection.mobileSubSection) {
      const sectionWithMobileSecondLine = {
        ...currentSection,
        mobileSlots: nextSection.slots,
      };
      readyJsxSections.push(
        <Section
          path={
            new PortalPath([
              {
                type: "MAIN",
                elementId: "x",
                elementIndex: "x",
              },
              {
                type: "SECTION",
                elementId: sectionWithMobileSecondLine.id,
                elementIndex: sectionWithMobileSecondLine.sectionOrder,
              },
            ])
          }
          key={
            sectionWithMobileSecondLine.uuid +
            sectionWithMobileSecondLine.mobileSubSection
          }
          sectionData={sectionWithMobileSecondLine}
          type={type}
        />
      );
      return readyJsxSections;
    }
    // slots from mobileSubSection has been already inserted into previous section so we skip this section
    if (currentSection.mobileSubSection) return readyJsxSections;
    // standard behavior
    readyJsxSections.push(
      <Section
        path={
          new PortalPath([
            {
              type: "MAIN",
              elementId: "x",
              elementIndex: "x",
            },
            {
              type: "SECTION",
              elementId: currentSection.id,
              elementIndex: currentSection.sectionOrder,
            },
          ])
        }
        key={currentSection.uuid + currentSection.mobileSubSection}
        sectionData={currentSection}
        type={type}
      />
    );
    return readyJsxSections;
  };
