import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const BoxStoreLocatorMessagesScheme = z.object({
  store_locator_open_hours:
    themeScheme.MessageScheme.default("Godziny otwarcia: "),
  storelocator_view_label: themeScheme.MessageScheme.default("Widok: "),
  store_search_bar_search_input_placeholder_text:
    themeScheme.MessageScheme.default("Wpisz miejscowość, kod pocztowy"),
  storelocator_select_address_label:
    themeScheme.MessageScheme.default("Wybierz adres"),
  storelocator_not_found: themeScheme.MessageScheme.default(
    "Nie znaleziono sklepu"
  ),
  storelocator_mobile_show_map: themeScheme.MessageScheme.default("Pokaż mapę"),
  storelocator_mobile_show_shop_list:
    themeScheme.MessageScheme.default("Lista sklepów"),
  store_locator_open_hours_monday:
    themeScheme.MessageScheme.default("Poniedziałek"),
  store_locator_open_hours_tuesday: themeScheme.MessageScheme.default("Wtorek"),
  store_locator_open_hours_wednesday:
    themeScheme.MessageScheme.default("Środa"),
  store_locator_open_hours_thursday:
    themeScheme.MessageScheme.default("Czwartek"),
  store_locator_open_hours_friday: themeScheme.MessageScheme.default("Piątek"),
  store_locator_open_hours_saturday:
    themeScheme.MessageScheme.default("Sobota"),
  store_locator_open_hours_sunday:
    themeScheme.MessageScheme.default("Niedziela"),
});

export const BoxStoreLocatorSettingsScheme = z.object({
  storelocator_store_adress_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_store_adress_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_open_typography:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  storelocator_store_open_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_store_www_typography:
    themeScheme.TypoIdScheme.default("typo$$10").catch("typo$$10"),
  storelocator_store_www_color:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  storelocator_store_picked_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  storelocator_image_width:
    themeScheme.SettingNumberScheme.default("232").catch("232"),
  store_search_bar_search_input_settings:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  store_search_bar_search_button_settings:
    themeScheme.ButtonIdScheme.default("button$$3").catch("button$$3"),
  store_search_bar_wrapper_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  storelocator_view_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_view_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_select_address_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  storelocator_no_image_plug: themeScheme.UrlScheme.default(
    "/ecp-media-cms/store_locator.png"
  ).catch("/ecp-media-cms/store_locator.png"),
  storelocator_mobile_show_map_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_map_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_mobile_show_shop_list_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_mobile_show_shop_list_background_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  storelocator_store_email_typography:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  storelocator_store_email_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  storelocator_not_found_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  storelocator_not_found_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
