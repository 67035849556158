import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxBaseSettings,
  IElementButton,
  IElementImage,
  IElementInnerBox,
  IElementTextContent,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ISpacing } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IButtonContent,
  IImageContent,
  ILinkContent,
} from "../../contentPatterns.types";

export enum SliderSizeOptions {
  CUSTOM = "CUSTOM",
  SMALL = "350",
  MEDIUM = "500",
  LARGE = "650",
}

export enum SliderPaginationOptions {
  DOTS = "DOTS",
  NUMBERS = "NUMBERS",
  ARROWS = "ARROWS",
  NONE = "NONE",
}

export type IBoxSliderData = GenericBoxData<
  IBoxSliderContent,
  IBoxSliderContentSettings,
  IBoxSliderDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export interface ISlideContent {
  id: string;
  title: string;
  description: string;
  slideUrl: ILinkContent;
  mainButton: IButtonContent;
  secondaryButton: IButtonContent;
  image: IImageContent;
  seoDescription: string;
}

export interface IBoxSliderContent {
  slides: ISlideContent[];
}

export interface IBoxSliderDisplaySettings {
  size: SliderSizeOptions;
  paginationStyle: SliderPaginationOptions;
  padding: ISpacing;
  margin: ISpacing;
  slidesRotate: number[];
  customHeight: number;
}

export interface ISlideContentSettings {
  image: IElementImage;
  box: IElementInnerBox;
  title: IElementTextContent;
  description: IElementTextContent;
  mainButton: IElementButton;
  secondaryButton: IElementButton;
}
export interface IBoxSliderContentSettings {
  slides: ISlideContentSettings[];
}

export type IBoxSliderSettings = IBoxBaseSettings<
  IBoxSliderDisplaySettings & IBoxSliderContentSettings
>;
