import { DeepPartial } from "@udecode/plate";
import { EVENT, TEvent } from "./const";
import {
  AddToCartEventSchema,
  IAddToCartEvent,
  IListingTrackerEvent,
  IProductDetailsEvent,
  IViewCartEvent,
  IViewPageEvent,
  ListingTrackerEventSchema,
  ProductDetailsEventSchema,
  ViewCartEventSchema,
  ViewPageEventSchema,
  IRemoveFromCartEvent,
  RemoveFromCartEventSchema,
  ITransactionEvent,
  TransactionEventSchema,
  ISalesTrackingEvent,
  SalesTrackingEventSchema,
} from "./schema";

const pushEvent = <T extends { event: TEvent }>(event: T) => {
  const collectEvents = sessionStorage.getItem("collect_events") === "true";
  if (window.dataLayer !== undefined && collectEvents) {
    window.dataLayer.push(event);
  }
};

export const eventAddToCart = (eventData: Partial<IAddToCartEvent>) => {
  const parsedEventData = AddToCartEventSchema.parse(eventData);
  pushEvent({ event: EVENT.ADD_TO_CART, ...parsedEventData });
};

export const eventRemoveFromCart = (
  eventData: Partial<IRemoveFromCartEvent>
) => {
  const parsedEventData = RemoveFromCartEventSchema.parse(eventData);
  pushEvent({ event: EVENT.REMOVE_FROM_CART, ...parsedEventData });
};

export const eventViewPage = (eventData: Partial<IViewPageEvent>) => {
  const parsedEventData = ViewPageEventSchema.parse(eventData);
  pushEvent({ event: EVENT.VIEW_PAGE, ...parsedEventData });
};

export const eventProductDetails = (
  eventData: Partial<IProductDetailsEvent>
) => {
  const parsedEventData = ProductDetailsEventSchema.parse(eventData);
  pushEvent({ event: EVENT.PRODUCT_DETAILS, ...parsedEventData });
};

export const eventViewCart = (eventData: DeepPartial<IViewCartEvent>) => {
  const parsedEventData = ViewCartEventSchema.parse(eventData);
  pushEvent({ event: EVENT.VIEW_CART, ...parsedEventData });
};

export const eventListingTracker = (
  eventData: Partial<IListingTrackerEvent>
) => {
  const parsedEventData = ListingTrackerEventSchema.parse(eventData);
  pushEvent({ event: EVENT.LISTING_TRACKER, ...parsedEventData });
};

export const eventTransaction = (eventData: Partial<ITransactionEvent>) => {
  const parsedEventData = TransactionEventSchema.parse(eventData);
  pushEvent({ event: EVENT.TRANSACTION, ...parsedEventData });
};

export const eventSalesTracking = (eventData: Partial<ISalesTrackingEvent>) => {
  const parsedEventData = SalesTrackingEventSchema.parse(eventData);
  pushEvent({ event: EVENT.SALES_TRACKING, ...parsedEventData });
};
