import styled from "styled-components";

interface IStyledPublication {
  boxType: string;
  alignment: string;
  publicationWidth: string;
}

export const StyledPublication = styled.div<IStyledPublication>`
  position: relative;
  z-index: ${({ boxType }) =>
    boxType === "MULTI_MENU" ? "auto" : "var(--zindex-publication)"};

  ${({ alignment, publicationWidth }) => {
    if (publicationWidth === "CONTENT") {
      return alignment === "RIGHT"
        ? "justify-self: end;"
        : "justify-self: start;";
    } else {
      return alignment === "RIGHT"
        ? "display: flex; justify-content: flex-end;"
        : "display: flex; justify-content: flex-start;";
    }
  }};
`;
