import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMyAccountConsentsMessagesScheme = z.object({
  customer_data_consent_select_all:
    themeScheme.MessageScheme.default("Zaznacz wszystkie"),
  customer_data_consent_title: themeScheme.MessageScheme.default("Prywatność"),
  customer_data_email_consent: themeScheme.MessageScheme.default(
    "Zgoda na komunikacje email (treść zgody)"
  ),
  customer_data_sms_consent: themeScheme.MessageScheme.default(
    "Zgoda na komunikacje sms (treść zgody)"
  ),
  customer_data_profile_consent: themeScheme.MessageScheme.default(
    "Zgoda na profilowanie (treść zgody)"
  ),
  customer_data_email_consent_heading:
    themeScheme.MessageScheme.default("E-mail"),
  customer_data_sms_consent_heading:
    themeScheme.MessageScheme.default("SMS/MMS"),
  customer_data_profile_consent_heading: themeScheme.MessageScheme.default(
    "Zgoda na dopasowanie ofert"
  ),
  customer_data_heading_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  customer_data_placeholder_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  form_syntax_verification_phone: themeScheme.MessageScheme.default(
    "Numer musi mieć od 9 do 13 cyfr"
  ),
  form_empty_verification_phone: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  customer_data_delete_button: themeScheme.MessageScheme.default("Usuń konto"),
  customer_data_consent_popup_title: themeScheme.MessageScheme.default(
    "Czy napewno chcesz usunąć konto?"
  ),
  customer_data_consent_popup_description: themeScheme.MessageScheme.default(
    "W przypadku usunięcia konta utracisz całą historię swoich transakcji"
  ),
  customer_data_consent_popup_button_save:
    themeScheme.MessageScheme.default("Tak"),
  customer_data_consent_popup_button_cancel:
    themeScheme.MessageScheme.default("Nie"),
  customer_data_save_button: themeScheme.MessageScheme.default("Zapisz"),
  customer_data_cancel_button: themeScheme.MessageScheme.default("Anuluj"),
  customer_data_consent_success: themeScheme.MessageScheme.default(
    "Zmieniliśmy Twoje zgody"
  ),
  customer_data_consent_error: themeScheme.MessageScheme.default(
    "Nie udało się zmienić Twoich zgód"
  ),
  change_phone: themeScheme.MessageScheme.default("Zmień"),
  customer_email_address_prefix: themeScheme.MessageScheme.default("Adres"),
  sms_terms_link: themeScheme.MessageScheme.default(
    "Zgoda na komunikacje sms (treść zgody)"
  ),
  email_terms_link: themeScheme.MessageScheme.default(
    "Zgoda na komunikacje email (treść zgody)"
  ),
  profile_terms_link: themeScheme.MessageScheme.default(
    "Zgoda na profilowanie (treść zgody)"
  ),
});

export const IBoxMyAccountConsentsSettingsScheme = z.object({
  title_typo: themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  title_color: themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  title_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  phone_input: themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  checkbox_description_typo:
    themeScheme.TypoIdScheme.default("typo$$7").catch("typo$$7"),
  checkbox_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  checkbox_title_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  show_sms_consent:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  show_email_consent:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  show_profiling_consent:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  save_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  cancel_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  delete_account_button:
    themeScheme.ButtonIdScheme.default("button$$9").catch("button$$9"),
  popup_yes_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  popup_no_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  gap: themeScheme.SizeCSSScheme.default("15px").catch("15px"),
  background: themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  padding: themeScheme.SizeCSSScheme.default("15px").catch("15px"),
  terms_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  popup_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  redirect_after_delete_link: themeScheme.UrlScheme.default("/").catch("/"),
  error_typo: themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  error_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  error_background_color:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  success_typo: themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  success_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  success_background_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
});
