import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMyAccountMyDataMessagesScheme = z.object({
  customer_data_title: themeScheme.MessageScheme.default("Moje dane"),
  customer_data_heading_email:
    themeScheme.MessageScheme.default("Login (e-mail):"),
  customer_data_heading_name: themeScheme.MessageScheme.default("Imię:"),
  customer_data_heading_surname: themeScheme.MessageScheme.default("Nazwisko:"),
  customer_data_heading_password: themeScheme.MessageScheme.default("Hasło:"),
  customer_data_edit_password: themeScheme.MessageScheme.default("Zmień hasło"),
  customer_data_heading_phone:
    themeScheme.MessageScheme.default("Nr telefonu:"),
  customer_data_edit_name_title: themeScheme.MessageScheme.default("Moje dane"),
  customer_data_edit_heading_email:
    themeScheme.MessageScheme.default("Login (e-mail):"),
  customer_data_edit_heading_name: themeScheme.MessageScheme.default("Imię:"),
  customer_data_edit_placeholder_name:
    themeScheme.MessageScheme.default("Imię"),
  customer_data_edit_heading_surname:
    themeScheme.MessageScheme.default("Nazwisko:"),
  customer_data_edit_placeholder_surname:
    themeScheme.MessageScheme.default("Nazwisko"),
  customer_data_edit_heading_password:
    themeScheme.MessageScheme.default("Hasło:"),
  customer_data_edit_placeholder_password:
    themeScheme.MessageScheme.default("Hasło"),
  customer_data_edit_heading_phone:
    themeScheme.MessageScheme.default("Numer telefonu:"),
  customer_data_edit_placeholder_phone:
    themeScheme.MessageScheme.default("Numer telefonu"),
  customer_data_edit_name_save_button:
    themeScheme.MessageScheme.default("Zapisz"),
  customer_data_edit_name_cancel_button:
    themeScheme.MessageScheme.default("Anuluj"),
  customer_data_edit_success: themeScheme.MessageScheme.default(
    "Twoje dane zostały zmienione"
  ),
  customer_data_empty_verification_name: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  customer_data_short_input_length_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono maksymalną ilość znaków (20)"
  ),
  customer_data_empty_verification_surname: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  customer_data_verification_phone: themeScheme.MessageScheme.default(
    "Numer telefonu musi mieć 9 cyfr"
  ),
  customer_data_save_button: themeScheme.MessageScheme.default("Zapisz"),
  customer_data_cancel_button: themeScheme.MessageScheme.default("Anuluj"),
  customer_data_edit_password_info:
    themeScheme.MessageScheme.default("Zmień hasło"),
  edit_customer_data_validation_error: themeScheme.MessageScheme.default(
    "Nie udało się zmienić danych"
  ),
});

export const IBoxMyAccountMyDataSettingsScheme = z.object({
  customer_data_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  customer_data_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  customer_data_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  customer_data_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_redirect_change_password:
    themeScheme.UrlScheme.default("/").catch("/"),
  customer_data_redirect_change_password_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  customer_data_success_info_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_success_info_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_success_info_background_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  customer_data_edit_title:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  customer_data_edit_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_edit_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  customer_data_confirm_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_cancel_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  edit_customer_data_error_background_color:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  edit_customer_data_error_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  edit_customer_data_error_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
