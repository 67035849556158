import { memo, useEffect, useState } from "react";
import type { GetServerSidePropsContext, NextPage, PreviewData } from "next";
import { isEqual } from "lodash";
import absoluteUrl from "next-absolute-url";
import { ParsedUrlQuery } from "querystring";
import { getSelectorsByUserAgent } from "react-device-detect";
import { ThemeProvider } from "styled-components";
import { boxesEndpointSSR } from "@ecp-boxes/boxes/boxSSR";
import { MOBILE_BREAKPOINT } from "@ecp-boxes/global/global";
import { getModifiedSectionUuid } from "@ecp-boxes/helpers/getModifiedSectionUuid";
import { isClientSide } from "@ecp-boxes/helpers/helpers";
import { portalStoreWrapper } from "@ecp-boxes/redux/store";
import { DeviceTypeMobileContext } from "@ecp-boxes/structure/Contexts/DeviceTypeContext";
import { DynamicPageContextProvider } from "@ecp-boxes/structure/Contexts/DynamicPageContext";
import { RowPopupContextProvider } from "@ecp-boxes/structure/Contexts/RowPopupContext";
import { SectionEffectsProvider } from "@ecp-boxes/structure/Contexts/SectionEffectsContext";
import DynamicPage from "@ecp-boxes/structure/DynamicPage/DynamicPage";
import MetaData, { IMetaData } from "@ecp-boxes/structure/MetaData/MetaData";
import * as myData from "@ecp-redux/api/myData";

import {
  ILayoutPageBuilder,
  IPortalPage,
  IPortalSection,
  PageBuilderLayoutToPage,
  PageCategoryType,
  TPlatform,
  TRegularBoxType,
  TSystemBoxType,
} from "@ecp-pageTypes";
import { api } from "@ecp-redux/api/api";
import * as articles from "@ecp-redux/api/articles";
import * as lastSeen from "@ecp-redux/api/lastSeen";
import * as pages from "@ecp-redux/api/pages";
import * as searchResults from "@ecp-redux/api/searchResults";
import * as tenantAPI from "@ecp-redux/api/tenant";
import * as theme from "@ecp-redux/api/themeSettings";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import Error from "./Error";
import useSetSessionStorage from "@ecp-boxes/shared/hooks/useSetSessionStorage";
import { useViewPageEvent } from "@ecp-boxes/boxes/analytics/hooks/useViewPageEvent";
import { useProductDetailsEvent } from "@ecp-boxes/boxes/analytics/hooks/useProductDetailsEvent";
import { TooltipContextProvider } from "@ecp-boxes/structure/Contexts/TooltipContext";

const logPerformance = (startTime: number, stage: string): void => {
  const endTime = performance.now();
  const elapsedTimeInSeconds = ((endTime - startTime) / 1000).toFixed(1);
  console.log(`TIMER - ${stage} - ${elapsedTimeInSeconds} sec`);
};

const log = (message: string, withNewLine?: boolean) =>
  process.stdout.write(message + (withNewLine ? "\n" : ""));

const specifyPlatform = (isMobile: boolean): TPlatform =>
  isMobile ? "MOBILE" : "DESKTOP";

const useSpecifyPlatform = (platformByUserAgent: TPlatform): TPlatform => {
  const initState = isClientSide()
    ? window.innerWidth <= MOBILE_BREAKPOINT
    : undefined;
  const [isMobile, setIsMobile] = useState(initState);

  useEffect(() => {
    const resizeWindow = () => {
      setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    };
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return isClientSide()
    ? specifyPlatform(isMobile ?? false)
    : platformByUserAgent;
};

export const getPlatformByUserAgent = (
  props: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
): TPlatform => {
  const userAgent = props.req.headers["user-agent"];
  const { isMobile } = getSelectorsByUserAgent(userAgent ?? "");
  return specifyPlatform(isMobile);
};

const getPageQuery = (
  props: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
  const url =
    (Array.isArray(props.params?.standartPage)
      ? props.params?.standartPage.join("/")
      : props.params?.standartPage) ?? "";

  const previewMode = props.query.preview === "true" ? "&preview=true" : "";

  return { pageQuery: `url=/${url}${previewMode}`, url };
};

interface ServerSideProps {
  pageQuery: string;
  platformByUserAgent: TPlatform;
  metaData: IMetaData;
  errorMessage?: string;
  isThemePreview?: boolean;
}

export const getServerSideProps =
  portalStoreWrapper.getServerSideProps<ServerSideProps>(
    (store) => async (props) => {
      const fs = await import("fs");
      const path = await import("path");
      const startTime = performance.now();
      let redirect = undefined;
      const { host } = absoluteUrl(props.req);

      const { pageQuery, url } = getPageQuery(props);
      const platformByUserAgent = getPlatformByUserAgent(props);
      log(
        `PAGE INFO: ${host}${props.resolvedUrl}, ${platformByUserAgent}`,
        true
      );

      if (process.env.NEXT_THEME_PREVIEW_HOST === host) {
        log("IS THEME PREVIEW", true);
        return {
          props: {
            pageQuery,
            platformByUserAgent,
            metaData: { title: "", description: "", keywords: "" },
            isThemePreview: true,
          },
        };
      }

      //--- VERIFY DOMAIN ---//
      if (
        !host.startsWith("localhost") &&
        process.env.NODE_ENV === "production"
      ) {
        log(`VERIFY DOMAIN: ${host} ... `);
        const verifyDomainData = await store.dispatch(
          tenantAPI.verifyDomain.initiate({ domain: host })
        );

        if (verifyDomainData.isError) {
          // Log verifyDomain response errors
          log(`ERROR: ${JSON.stringify(verifyDomainData, null, 2)} `);
          //  cannot set flag notFound: true, because this does not allow an error message to be displayed
          return {
            props: {
              pageQuery,
              platformByUserAgent,
              metaData: { title: "", description: "", keywords: "" },
              errorMessage: (verifyDomainData.error as any)?.data?.errors
                .map((e: any) => e.message)
                .join(", "),
            },
          };
        }
        log("GOOD", true);
      }
      //--- END VERIFY DOMAIN ---//

      //--- DISABLE SSR FOR BETTER DEBUGGING ---//
      if (props.query["ssr"] === "false") {
        log("SSR DISABLED", true);
        return {
          props: {
            pageQuery,
            platformByUserAgent,
            metaData: { title: "", description: "", keywords: "" },
          },
        };
      }
      //--- END DISABLE SSR FOR BETTER DEBUGGING ---//

      logPerformance(startTime, "before ThemeSettings and Page");
      const [themeSettings, page] = await Promise.all([
        store.dispatch(
          theme.getThemeSettingsPortal.initiate(platformByUserAgent)
        ),
        store.dispatch(
          pages.getPagePortal.initiate({
            pageQuery,
            platform: platformByUserAgent,
          })
        ),
      ]);
      logPerformance(startTime, "after ThemeSettings and Page");

      log(
        `FETCHING THEME SETTINGS: ${
          themeSettings.isSuccess ? "SUCCESS" : "ERROR"
        }`,
        true
      );
      log(`FETCHING PAGE: ${page.isSuccess ? "SUCCESS" : "ERROR"} `, true);
      const globalThemeTypography =
        themeSettings?.data?.typography?.map((typo) => {
          return {
            family:
              typo.family.charAt(0).toUpperCase() +
              typo.family.slice(1).toLowerCase(),
            weight: typo.weight,
          };
        }) ?? null;
      //--- PAGE 404 HANDLING ---//
      const pageErrorStatus = (page?.error as FetchBaseQueryError)?.status;
      let notFound = false;
      if (pageErrorStatus === 404 || pageErrorStatus === 403) {
        if (
          themeSettings.data !== undefined &&
          themeSettings.data.systemPages.page404.id !== -1
        ) {
          const url404 = await store.dispatch(
            pages.getPageUrlById.initiate(
              themeSettings.data.systemPages.page404.id
            )
          );

          if (
            url404.data !== undefined &&
            !url404.data.draft &&
            `/${url}` !== url404.data.url
          ) {
            redirect = { destination: url404.data.url, permanent: false };
          } else {
            notFound = true;
          }
        } else {
          notFound = true;
        }
      }
      //--- END PAGE 404 HANDLING  ---//

      // --- SET META DATA ---//
      const metaData: IMetaData = { title: "", description: "", keywords: "" };

      const loadAvailableFontWeights = () => {
        const cssPath = path.join(process.cwd(), "public/styles/fonts.css");
        const cssText = fs.readFileSync(cssPath, "utf-8");
        const fontWeights: {
          [family: string]: number[];
        } = {};

        const fontFaceRegex =
          /@font-face\s*{[^}]*font-family:\s*['"]?([^'";]*)['"]?;[^}]*font-weight:\s*([^;]*)/g;
        let match;
        while ((match = fontFaceRegex.exec(cssText)) !== null) {
          const family = match[1].trim();
          const weight = parseInt(match[2].trim(), 10);
          if (!fontWeights[family]) fontWeights[family] = [];
          fontWeights[family].push(weight);
        }
        return fontWeights;
      };
      const fontWeights = loadAvailableFontWeights();
      if (!page?.data?.page) {
        const returnProps1 = {
          props: {
            pageQuery,
            platformByUserAgent,
            metaData,
            globalThemeTypography,
            fontWeights,
          },
          notFound,
          redirect,
        };
        log(
          `SSR FINISHED_1 WITH: ${JSON.stringify(returnProps1, null, 2)}`,
          true
        );
        return returnProps1;
      }

      if (
        page.data.page.type === PageCategoryType.PRODUCT &&
        page.data.page.productSku !== null
      ) {
        log(`IS PRODUCT PAGE (sku: ${page.data.page.productSku}), FETCHING...`);
        const product = await store.dispatch(
          searchResults.getPostProducts.initiate({
            skus: [page.data.page.productSku],
            projection: [],
          })
        );
        metaData.title =
          "data" in product ? (product.data?.products?.[0].name ?? "") : "";
        log("data" in product ? "SUCCESS" : "ERROR", true);
      } else if (page.data.page.type === PageCategoryType.ARTICLE) {
        log(
          `IS ARTICLE PAGE (articleId: ${page.data.page.articleId}) , FETCHING...`
        );
        const article = await store.dispatch(
          articles.getArticles.initiate({
            page: { number: 1, size: 1 },
            filter: [
              {
                name: "id",
                operator: "EQ",
                value: page.data.page.articleId,
              },
            ],
            sort: [],
          })
        );
        log("data" in article ? "SUCCESS" : "ERROR", true);

        metaData.title =
          "data" in article && article.data
            ? article.data.result[0].metaTitle
            : "";
        metaData.description =
          "data" in article && article.data
            ? article.data.result[0].metaDescription
            : "";
        metaData.keywords =
          "data" in article && article.data
            ? article.data.result[0].keywords
            : "";
      } else {
        metaData.title = page.data.page.content?.[0]?.title ?? "";
        metaData.description = page.data.page.content?.[0]?.description ?? "";
        metaData.keywords = page.data.page.content?.[0]?.keywords ?? "";
      }
      // --- END SET META DATA ---//

      //--- LOAD BOXES DATA IN SSR ---//
      logPerformance(startTime, "before Boxes");
      if (page?.data?.boxes) {
        log("LOADING BOXES DATA ...", true);
        Object.values(page.data.boxes).forEach((box) => {
          return (
            boxesEndpointSSR[
              box.type === "SYSTEM"
                ? (box.content.component as TSystemBoxType)
                : (box.type as TRegularBoxType)
            ] ?? []
          ).forEach((e) => {
            store
              .dispatch(
                e.action({
                  url: props.resolvedUrl ?? "",
                  box,
                  page: page.data?.page as ILayoutPageBuilder,
                })
              )
              .then((res: any) => {
                log(
                  ` BOX ${box.id}: ${res.endpointName}(${JSON.stringify(
                    res.originalArgs
                  )}) ${res.status === "fulfilled" ? "SUCCESS" : "ERROR"}`,
                  true
                );
                if (themeSettings.data !== undefined && e.redirect) {
                  const destination = e.redirect(res, themeSettings.data, box);

                  if (destination) {
                    redirect = {
                      destination,
                      permanent: false,
                    };
                  }
                }

                if (
                  res?.error &&
                  "data" in res.error &&
                  res.error.status === 401 &&
                  url !== "login"
                ) {
                  redirect = {
                    destination:
                      themeSettings.data?.advanceSettings.settings.loginUrl,
                    permanent: false,
                  };
                }
              });
          });
        });

        try {
          await Promise.all(store.dispatch(api.util.getRunningQueriesThunk()));
          await Promise.all(
            store.dispatch(api.util.getRunningMutationsThunk())
          );
        } catch (e) {
          log(
            `ERROR IN FETCH DATA FOR BOXES IN SSR: ${JSON.stringify(
              e,
              null,
              2
            )}`,
            true
          );
        }
        logPerformance(startTime, "after Boxes");
        log("LOADING BOXES DATA FINISHED", true);
      }
      //--- END LOAD BOXES DATA IN SSR ---//

      const returnProps2 = {
        props: {
          pageQuery,
          platformByUserAgent,
          metaData,
          globalThemeTypography,
          fontWeights,
        },
        notFound,
        redirect,
      };
      log(
        `SSR FINISHED_2 WITH: ${JSON.stringify(returnProps2, null, 2)}`,
        true
      );
      return returnProps2;
    }
  );

const getPageSections = (
  page: IPortalPage,
  isMobile: boolean,
  stickyHeader: boolean
) => {
  let currentZIndex = 0;

  const getSectionOffset = (
    section: IPortalSection,
    isHeaderSection: boolean
  ) => ({
    sectionUuid: getModifiedSectionUuid(section, isMobile),
    sectionId: section.id,
    isHeaderSection,
    sticky:
      section.displaySettings.effects?.stickyRow?.enabled || isHeaderSection,
    zIndex: 900 - currentZIndex++,
  });

  return [
    ...(page.header?.sections ?? []).map((sec) =>
      getSectionOffset(sec, stickyHeader)
    ),
    ...[...page.sections, ...(page.footer?.sections ?? [])].map((sec) =>
      getSectionOffset(sec, false)
    ),
  ];
};

export const StandardPage: NextPage<ServerSideProps> = ({
  errorMessage,
  pageQuery,
  platformByUserAgent,
  metaData,
  isThemePreview,
}) => {
  const platform = useSpecifyPlatform(platformByUserAgent);
  const [addLastSeen, { isUninitialized }] =
    lastSeen.usePostAddLastSeenMutation();
  const { setSessionStorageItem, getSessionStorageItem } =
    useSetSessionStorage();

  const { data: themeSettings } =
    theme.useGetThemeSettingsPortalQuery(platform);
  const [trigger] = myData.useLazyGetCustomerDataWithNoValidationQuery();
  const { data } = pages.useGetPagePortalQuery({
    pageQuery,
    platform,
  });

  useEffect(() => {
    const loginStateValidated = getSessionStorageItem(
      "is_customer_state_validated"
    );
    if (loginStateValidated !== "true") {
      trigger()
        .unwrap()
        .then((customerData) => {
          if (customerData) {
            setSessionStorageItem(
              "customer_id",
              String(customerData.customerId)
            );
            setSessionStorageItem("email", String(customerData.email));
            setSessionStorageItem("is_customer_state_validated", "true");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useViewPageEvent({ platform });
  useProductDetailsEvent({ platform });

  if (errorMessage) {
    return <Error statusCode={errorMessage} />;
  }

  if (!data?.page) {
    console.log("Standard page: No page data");
    return null;
  }

  if (!themeSettings) {
    return <div>Theme settings not loaded</div>;
  }

  if (
    data?.page.type === PageCategoryType.PRODUCT &&
    data?.page.productSku &&
    isUninitialized
  ) {
    addLastSeen({ productSku: data.page.productSku });
  }

  const d = PageBuilderLayoutToPage(
    data?.page,
    data?.boxes,
    data?.headerBoxes,
    data?.footerBoxes
  );

  const contextSections = getPageSections(
    d,
    platform === "MOBILE",
    themeSettings.stylePages.pages.headerFooter.stickyHeader
  );

  return (
    <DynamicPageContextProvider {...(isThemePreview && { key: data.page.url })}>
      <RowPopupContextProvider
        sections={[
          ...(d.header?.sections ?? []),
          ...(d.footer?.sections ?? []),
          ...d.sections,
        ]}
      >
        <SectionEffectsProvider effects={contextSections}>
          <DeviceTypeMobileContext.Provider value={platform}>
            <MetaData metaData={metaData} />

            <ThemeProvider theme={themeSettings}>
              <TooltipContextProvider>
                <DynamicPage data={d} activePathExtraSettings="" />
              </TooltipContextProvider>
            </ThemeProvider>
          </DeviceTypeMobileContext.Provider>
        </SectionEffectsProvider>
      </RowPopupContextProvider>
    </DynamicPageContextProvider>
  );
};

export default memo(StandardPage, isEqual);
