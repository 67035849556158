import styled, { css } from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  alignmentOptionsToValue,
} from "../../settingsPatterns/composeCss";

export const StyledButtonAlignment = styled.div(
  ({
    alignment,
    padding,
  }: {
    alignment: AlignmentHorizontalOption;
    padding?: ISpacing;
  }) => {
    return css`
      display: flex;
      justify-content: ${alignmentOptionsToValue(alignment)};
      ${padding
        ? css`
            padding: ${composeCss.padding(padding)};
          `
        : ""}
    `;
  }
);
