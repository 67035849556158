import styled, { css } from "styled-components";
import composeCss from "../../../settingsPatterns/composeCss";
import { Tooltip } from "react-tooltip";
import {
  IThemeState,
  TTooltipId,
  TTooltipReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertTooltipIdToValues } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";

export const StyledTooltip = styled(Tooltip).attrs(
  (props: {
    $tooltipStyle: TTooltipReadoutValue | TTooltipId;
    theme: IThemeState;
  }) => {
    const arrowEnabled = convertTooltipIdToValues(
      props.$tooltipStyle,
      props.theme.globalObjects.tooltips
    ).arrow;

    return {
      noArrow: !arrowEnabled,
    };
  }
)<{
  $tooltipStyle: TTooltipReadoutValue | TTooltipId;
}>(({ $tooltipStyle, theme }) => {
  return css`
    &.react-tooltip {
      z-index: 999;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;

      ${$tooltipStyle !== undefined
        ? composeCss.tooltip(
            $tooltipStyle,
            theme.globalObjects.tooltips,
            theme.colorPalette,
            theme.typography
          )
        : ""}
    }
  `;
});
