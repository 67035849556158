import { LinkContentScheme } from "./../../settingsPatterns/contentPatterns.scheme";
import { SelectedGoToOption } from "./../../settingsPatterns/contentPatterns.types";
import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  SettingPositionOptions,
  TRowWidthOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  MultiMenuItemType,
  MultiMenuStyleOptions,
  MultiMenuType,
  MultimenuHorizontalExpandOptions,
} from "@ecp-boxes/settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";

export const IBoxMultiMenuMessagesScheme = z.object({});

export const IBoxMultiMenuSettingsScheme = z.object({
  multimenu_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_background_color:
    themeScheme.ColorIdScheme.default("color$$3").catch("color$$3"),
  multimenu_mobile_hamburger_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_hamburger_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  multimenu_mobile_hamburger_view:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  multimenu_mobile_hamburger_left_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_right_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_bottom_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_top_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_close_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_items_left_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  multimenu_mobile_items_top_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  multimenu_mobile_hamburger_size:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
});

// !! contentSettings

// type TMenuItemContentSettings \/
export const MenuItemContentSettingsScheme = z.object({
  type: z
    .nativeEnum(MultiMenuItemType)
    .default(MultiMenuItemType.LINK)
    .catch(MultiMenuItemType.LINK),
  linkType: z
    .object({
      style: z
        .nativeEnum(MultiMenuStyleOptions)
        .default(MultiMenuStyleOptions.STYLE_1)
        .catch(MultiMenuStyleOptions.STYLE_1),
      icon: z
        .object({
          position: z
            .nativeEnum(SettingPositionOptions)
            .default(SettingPositionOptions.LEFT)
            .catch(SettingPositionOptions.LEFT),
          size: z.number().default(16).catch(16),
          spacing: z.number().default(5).catch(5),
        })
        .default({
          position: SettingPositionOptions.LEFT,
          size: 16,
          spacing: 5,
        })
        .catch({
          position: SettingPositionOptions.LEFT,
          size: 16,
          spacing: 5,
        }),
    })
    .default({
      style: MultiMenuStyleOptions.STYLE_1,
      icon: {
        position: SettingPositionOptions.LEFT,
        size: 16,
        spacing: 5,
      },
    })
    .catch({
      style: MultiMenuStyleOptions.STYLE_1,
      icon: {
        position: SettingPositionOptions.LEFT,
        size: 16,
        spacing: 5,
      },
    }),
});

//TMenuColumnContentSettings \/
export const MenuColumnContentSettingsScheme = z.object({
  items: z
    .array(MenuItemContentSettingsScheme)
    .default([
      {
        type: MultiMenuItemType.LINK,
        linkType: {
          style: MultiMenuStyleOptions.STYLE_1,
          icon: {
            position: SettingPositionOptions.LEFT,
            size: 16,
            spacing: 5,
          },
        },
      },
    ])
    .catch([
      {
        type: MultiMenuItemType.LINK,
        linkType: {
          style: MultiMenuStyleOptions.STYLE_1,
          icon: {
            position: SettingPositionOptions.LEFT,
            size: 16,
            spacing: 5,
          },
        },
      },
    ]),
});

// type TMenuGroupContentSettings \/
export const MenuGroupContentSettingsScheme = z.object({
  linkType: z.object({
    style: z
      .nativeEnum(MultiMenuStyleOptions)
      .default(MultiMenuStyleOptions.STYLE_1)
      .catch(MultiMenuStyleOptions.STYLE_1),
    icon: z
      .object({
        position: z
          .nativeEnum(SettingPositionOptions)
          .default(SettingPositionOptions.LEFT)
          .catch(SettingPositionOptions.LEFT),
        size: z.number().default(16).catch(16),
        spacing: z.number().default(5).catch(5),
      })
      .default({
        position: SettingPositionOptions.LEFT,
        size: 16,
        spacing: 5,
      })
      .catch({
        position: SettingPositionOptions.LEFT,
        size: 16,
        spacing: 5,
      }),
  }),
  width: themeScheme.RowWidthScheme.default({
    selectedOption: TRowWidthOption.PERCENT,
    percent: 100,
    margin: {
      right: 20,
      left: 20,
    },
  }).catch({
    selectedOption: TRowWidthOption.PERCENT,
    percent: 100,
    margin: {
      right: 20,
      left: 20,
    },
  }),
  editColumnsWidth: z
    .object({
      expandType: z
        .nativeEnum(MultimenuHorizontalExpandOptions)
        .default(MultimenuHorizontalExpandOptions.DROPDOWN)
        .catch(MultimenuHorizontalExpandOptions.DROPDOWN),
      dropdownPercentage: z.number().default(100).catch(100),
      fullPercentage: z.number().default(100).catch(100),
    })
    .default({
      expandType: MultimenuHorizontalExpandOptions.DROPDOWN,
      dropdownPercentage: 100,
      fullPercentage: 100,
    })
    .catch({
      expandType: MultimenuHorizontalExpandOptions.DROPDOWN,
      dropdownPercentage: 100,
      fullPercentage: 100,
    }),
  padding: themeScheme.SpacingScheme.default(
    themeScheme.SpacingScheme.parse({})
  ).catch(themeScheme.SpacingScheme.parse({})),
  columns: z
    .array(MenuColumnContentSettingsScheme)
    .default([
      {
        items: [
          {
            type: MultiMenuItemType.LINK,
            linkType: {
              style: MultiMenuStyleOptions.STYLE_1,
              icon: {
                position: SettingPositionOptions.LEFT,
                size: 16,
                spacing: 5,
              },
            },
          },
        ],
      },
    ])
    .catch([
      {
        items: [
          {
            type: MultiMenuItemType.LINK,
            linkType: {
              style: MultiMenuStyleOptions.STYLE_1,
              icon: {
                position: SettingPositionOptions.LEFT,
                size: 16,
                spacing: 5,
              },
            },
          },
        ],
      },
    ]),
});

// !! contentSettings
export const BoxMultimenuContentSettingsScheme = z.object({
  groups: z.array(MenuGroupContentSettingsScheme),
  type: z
    .nativeEnum(MultiMenuType)
    .default(MultiMenuType.MENU_HORIZONTAL)
    .catch(MultiMenuType.MENU_HORIZONTAL),
});

//_____________________________________________

// !! content

export const MenuItemContentScheme = z.object({
  heading: z.string().default("Item").catch("Item"),
  linkType: z
    .object({
      iconUrl: themeScheme.UrlScheme.default("").catch(""),
      link: LinkContentScheme.default({
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        goToRowSetting: {},
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      }).catch({
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      }),
    })
    .default({
      iconUrl: "",
      link: {
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        goToRowSetting: {},
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      },
    })
    .catch({
      iconUrl: "",
      link: {
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      },
    }),
  imageType: z
    .object({
      url: themeScheme.UrlScheme.default("").catch(""),
      seoDescription: z.string().default("").catch(""),
      link: LinkContentScheme.default({
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        goToRowSetting: {},
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      }).catch({
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      }),
    })
    .default({
      url: "",
      seoDescription: "",
      link: {
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        goToRowSetting: {},
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      },
    })
    .catch({
      url: "",
      seoDescription: "",
      link: {
        goToPageSetting: {
          link: "",
          openInNewTab: true,
        },
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        showLinkOptions: false,
      },
    }),

  textType: z
    .string()
    .default(
      `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`
    )
    .catch(
      `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`
    ),
});

export const MenuColumnContentScheme = z.object({
  columnSpan: z.number().default(12).catch(12),
  items: z
    .array(MenuItemContentScheme)
    .default([
      {
        heading: "Item",
        linkType: {
          iconUrl: "",
          link: {
            goToPageSetting: {
              link: "",
              openInNewTab: true,
            },
            goToRowSetting: {},
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            showLinkOptions: false,
          },
        },
        imageType: {
          url: "",
          seoDescription: "",
          link: {
            goToPageSetting: {
              link: "",
              openInNewTab: true,
            },
            goToRowSetting: {},
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            showLinkOptions: false,
          },
        },
        textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
      },
    ])
    .catch([
      {
        heading: "Item",
        linkType: {
          iconUrl: "",
          link: {
            goToPageSetting: {
              link: "",
              openInNewTab: true,
            },
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            showLinkOptions: false,
          },
        },
        imageType: {
          url: "",
          seoDescription: "",
          link: {
            goToPageSetting: {
              link: "",
              openInNewTab: true,
            },
            selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
            showLinkOptions: false,
          },
        },
        textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
      },
    ]),
});

export const MenuGroupContentScheme = z.object({
  heading: z.string().default("Item").catch("Item"),
  link: LinkContentScheme.default({
    goToPageSetting: {
      link: "",
      openInNewTab: true,
    },
    goToRowSetting: {},
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    showLinkOptions: false,
  }).catch({
    goToPageSetting: {
      link: "",
      openInNewTab: true,
    },
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    showLinkOptions: false,
  }),
  linkType: z
    .object({ iconUrl: themeScheme.UrlScheme.default("").catch("") })
    .default({ iconUrl: "" })
    .catch({ iconUrl: "" }),
  columns: z
    .array(MenuColumnContentScheme)
    .default([
      {
        columnSpan: 12,
        items: [
          {
            heading: "Item",
            linkType: {
              iconUrl: "",
              link: {
                goToPageSetting: {
                  link: "",
                  openInNewTab: true,
                },
                goToRowSetting: {},
                selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                showLinkOptions: false,
              },
            },
            imageType: {
              url: "",
              seoDescription: "",
              link: {
                goToPageSetting: {
                  link: "",
                  openInNewTab: true,
                },
                goToRowSetting: {},
                selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                showLinkOptions: false,
              },
            },
            textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
          },
        ],
      },
    ])
    .catch([
      {
        columnSpan: 12,
        items: [
          {
            heading: "Item",
            linkType: {
              iconUrl: "",
              link: {
                goToPageSetting: {
                  link: "",
                  openInNewTab: true,
                },
                selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                showLinkOptions: false,
              },
            },
            imageType: {
              url: "",
              seoDescription: "",
              link: {
                goToPageSetting: {
                  link: "",
                  openInNewTab: true,
                },
                selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                showLinkOptions: false,
              },
            },
            textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
          },
        ],
      },
    ]),
});

export const BoxMultiMenuContentScheme = z.object({
  groups: z
    .array(MenuGroupContentScheme)
    .default([
      {
        heading: "Item",
        link: {
          goToPageSetting: {
            link: "",
            openInNewTab: true,
          },
          goToRowSetting: {},
          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          showLinkOptions: false,
        },
        linkType: { iconUrl: "" },
        columns: [
          {
            columnSpan: 12,
            items: [
              {
                heading: "Item",
                linkType: {
                  iconUrl: "",
                  link: {
                    goToPageSetting: {
                      link: "",
                      openInNewTab: true,
                    },
                    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                    showLinkOptions: false,
                  },
                },
                imageType: {
                  url: "",
                  seoDescription: "",
                  link: {
                    goToPageSetting: {
                      link: "",
                      openInNewTab: true,
                    },
                    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                    showLinkOptions: false,
                  },
                },
                textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
              },
            ],
          },
        ],
      },
    ])
    .catch([
      {
        heading: "Item",
        link: {
          goToPageSetting: {
            link: "",
            openInNewTab: true,
          },

          selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
          showLinkOptions: false,
        },
        linkType: { iconUrl: "" },
        columns: [
          {
            columnSpan: 12,
            items: [
              {
                heading: "Item",
                linkType: {
                  iconUrl: "",
                  link: {
                    goToPageSetting: {
                      link: "",
                      openInNewTab: true,
                    },
                    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                    showLinkOptions: false,
                  },
                },
                imageType: {
                  url: "",
                  seoDescription: "",
                  link: {
                    goToPageSetting: {
                      link: "",
                      openInNewTab: true,
                    },
                    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                    showLinkOptions: false,
                  },
                },
                textType: `[{"type":"paragraph","children":[{"text":"text"}],"align":"left"}]`,
              },
            ],
          },
        ],
      },
    ]),
});
