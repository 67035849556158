import { IPortalSection, IPortalSlot } from "@ecp-pageTypes";

const isSlotEmpty = (slot: IPortalSlot) => slot.stacks.length === 0;

const isSubSectionEmpty = (slot: IPortalSlot) =>
  slot.sections.every((section: IPortalSection) =>
    section.slots.every(isSlotEmpty)
  );

export const everySlotsEmpty = (
  section: IPortalSection & { mobileSlots?: IPortalSlot[] }
): boolean => {
  const emptySection = section.slots.every(isSlotEmpty);
  const emptySubSection = section.slots.every(isSubSectionEmpty);

  const emptyMobileSection = section.mobileSlots?.every(isSlotEmpty) ?? true;
  const emptyMobileSubSection =
    section.mobileSlots?.every(isSubSectionEmpty) ?? true;
  return (
    emptySection &&
    emptySubSection &&
    emptyMobileSection &&
    emptyMobileSubSection
  );
};
