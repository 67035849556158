import { z } from "zod";
import {
  ButtonReadoutValueScheme,
  ColorReadoutValueScheme,
  SpacingScheme,
} from "./themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  OutlineOptions,
} from "./themeSettings.types";
import {
  EFFECT_VARIANTS,
  HoverRowColumnEffectOption,
  StickyRowEffectOption,
} from "./visualEffects.types";

export const StickyRowEffectOptionScheme = z
  .nativeEnum(StickyRowEffectOption)
  .default(StickyRowEffectOption.NONE)
  .catch(StickyRowEffectOption.NONE);

export const HoverRowEffectOptionScheme = z
  .nativeEnum(HoverRowColumnEffectOption)
  .default(HoverRowColumnEffectOption.NONE)
  .catch(HoverRowColumnEffectOption.NONE);

export const StickyRowEffects = z.object({
  enabled: z.boolean(),
  settings: z.object({
    name: StickyRowEffectOptionScheme,
    revealOnScrollUp: z.boolean(),
    topOffset: z.number(),
    duration: z.number(),
    padding: SpacingScheme,
    backgroundColor: ColorReadoutValueScheme,
  }),
});
export const FoldedRowEffect = z
  .object({
    enabled: z.boolean(),
    settings: z.object({
      height: z.number(),
      effectHeight: z.number(),
      fadeOpacity: z.number(),
      button: z.object({
        expandLabel: z.string(),
        collapseLabel: z.string(),
        horizontalAlignment: z.nativeEnum(AlignmentHorizontalOption),
        padding: SpacingScheme,
        style: ButtonReadoutValueScheme,
      }),
    }),
  })
  .default({
    enabled: false,
    settings: {
      effectHeight: 100,
      fadeOpacity: 0,
      height: 600,
      button: {
        collapseLabel: "Pokaż mniej",
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        horizontalAlignment: AlignmentHorizontalOption.CENTER,
        expandLabel: "Pokaż więcej",
        style: ButtonReadoutValueScheme.parse({}),
      },
    },
  })
  .catch({
    enabled: false,
    settings: {
      effectHeight: 100,
      fadeOpacity: 0,
      height: 600,
      button: {
        collapseLabel: "Pokaż mniej",
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        horizontalAlignment: AlignmentHorizontalOption.CENTER,
        expandLabel: "Pokaż więcej",
        style: ButtonReadoutValueScheme.parse({}),
      },
    },
  });

const BorderStylesVariantsScheme = z.nativeEnum(OutlineOptions);

export const BuzzEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  infinity: z.boolean().default(false).catch(false),
});

export const PulseEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  variant: z
    .enum([EFFECT_VARIANTS.GROW, EFFECT_VARIANTS.SHRINK])
    .default(EFFECT_VARIANTS.GROW)
    .catch(EFFECT_VARIANTS.GROW),
});

export const SkewEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  variant: z
    .enum([EFFECT_VARIANTS.BACKWARD, EFFECT_VARIANTS.FORWARD])
    .default(EFFECT_VARIANTS.FORWARD)
    .catch(EFFECT_VARIANTS.FORWARD),
});

export const MoveEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  offset: z.number().default(10).catch(10),
  variant: z
    .enum([
      EFFECT_VARIANTS.UP,
      EFFECT_VARIANTS.RIGHT,
      EFFECT_VARIANTS.DOWN,
      EFFECT_VARIANTS.LEFT,
    ])
    .default(EFFECT_VARIANTS.UP)
    .catch(EFFECT_VARIANTS.UP),
});

export const FadeEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  delay: z.number().default(1).catch(1),
  infinity: z.boolean().default(false).catch(false),
});

export const FlashEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  infinity: z.boolean().default(false).catch(false),
});

export const RotateEffectDetailsScheme = z.object({
  angle: z.number().default(5).catch(5),
  duration: z.number().default(1).catch(1),
  infinity: z.boolean().default(false).catch(false),
  variant: z
    .enum([EFFECT_VARIANTS.LEFT, EFFECT_VARIANTS.RIGHT])
    .default(EFFECT_VARIANTS.LEFT)
    .catch(EFFECT_VARIANTS.LEFT),
});

export const ScaleEffectDetailsScheme = z.object({
  duration: z.number().default(1).catch(1),
  variant: z
    .enum([EFFECT_VARIANTS.IN, EFFECT_VARIANTS.OUT])
    .default(EFFECT_VARIANTS.IN)
    .catch(EFFECT_VARIANTS.IN),
});

export const OverlayEffectDetailsScheme = z.object({
  color: ColorReadoutValueScheme,
  duration: z.number().default(1).catch(1),
  infinity: z.boolean().default(false).catch(false),
  opacity: z.number().default(1).catch(1),
});

export const ColorEffectDetailsScheme = z.object({
  backgroundColor: ColorReadoutValueScheme,
  borderColor: ColorReadoutValueScheme,
  borderStyle: BorderStylesVariantsScheme.default(OutlineOptions.NONE).catch(
    OutlineOptions.NONE
  ),
  borderSize: z
    .object({
      top: z.number(),
      right: z.number(),
      bottom: z.number(),
      left: z.number(),
    })
    .default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    })
    .catch({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }),
  duration: z.number().default(1).catch(1),
});

export const ShadowEffectDetailsScheme = z.object({
  blur: z.number().default(10).catch(10),
  shadowColor: ColorReadoutValueScheme,
  spreading: z.number().default(10).catch(10),
  shadowPosition: z
    .object({
      ["x-axis"]: z.number(),
      ["y-axis"]: z.number(),
    })
    .default({
      ["x-axis"]: 0,
      ["y-axis"]: 0,
    })
    .catch({
      ["x-axis"]: 0,
      ["y-axis"]: 0,
    }),
});

export const EffectDetailScheme = z.union([
  BuzzEffectDetailsScheme,
  PulseEffectDetailsScheme,
  SkewEffectDetailsScheme,
  MoveEffectDetailsScheme,
  FadeEffectDetailsScheme,
  FlashEffectDetailsScheme,
  RotateEffectDetailsScheme,
  ScaleEffectDetailsScheme,
  OverlayEffectDetailsScheme,
  ColorEffectDetailsScheme,
  ShadowEffectDetailsScheme,
]);

export const HoverRowAndColumnEffectsScheme = z.discriminatedUnion("name", [
  z.object({
    name: z.literal("BUZZ"),
    effectDetails: BuzzEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("PULSE"),
    effectDetails: PulseEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("SKEW"),
    effectDetails: SkewEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("MOVE"),
    effectDetails: MoveEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("FADE"),
    effectDetails: FadeEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("FLASH"),
    effectDetails: FlashEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("ROTATE"),
    effectDetails: RotateEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("SCALE"),
    effectDetails: ScaleEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("OVERLAY"),
    effectDetails: OverlayEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("COLOR"),
    effectDetails: ColorEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("SHADOW"),
    effectDetails: ShadowEffectDetailsScheme,
  }),
  z.object({
    name: z.literal("NONE"),
    effectDetails: z.object({}),
  }),
]);

export const RowEffectsScheme = z.object({
  hoverEffect: HoverRowAndColumnEffectsScheme,
  stickyRow: StickyRowEffects,
  foldedRow: FoldedRowEffect,
});

export const ColumnEffectsScheme = z.object({
  hoverEffect: HoverRowAndColumnEffectsScheme,
});

export type hoverEffectType = z.infer<typeof HoverRowAndColumnEffectsScheme>;
