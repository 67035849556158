import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxCompareHeaderMessagesScheme = z.object({
  compare_product_header_label: themeScheme.MessageScheme.default(""),
});

export const IBoxCompareHeaderSettingsScheme = z.object({
  compare_product_header_icon: themeScheme.UrlScheme.default("").catch(""),
  compare_product_header_quantity_bubble_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  compare_product_header_quantity_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  compare_product_header_quantity_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  compare_product_header_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  compare_product_header_label_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  compare_product_on_action_redirect_url:
    themeScheme.UrlScheme.default("/compare").catch("/compare"),
});
