import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxSearchBarMessagesScheme = z.object({
  search_bar_search_input_placeholder_text:
    themeScheme.MessageScheme.default("czego szukasz ?"),
  search_bar_search_button_label_text:
    themeScheme.MessageScheme.default("Szukaj"),
  search_bar_categories_input_placeholder_text:
    themeScheme.MessageScheme.default("Wszystkie kategorie"),
  search_bar_in_category_label:
    themeScheme.MessageScheme.default("w kategorii"),
});

export const IBoxSearchBarSettingsScheme = z.object({
  search_bar_search_input_settings:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  search_bar_search_button_settings:
    themeScheme.ButtonIdScheme.default("button$$3").catch("button$$3"),

  search_bar_wrapper_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  search_bar_input_loupe_active:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),

  search_bar_search_results_path:
    themeScheme.UrlScheme.default("/srp").catch("/srp"),

  search_bar_input_search_autocomplete_on:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),

  search_bar_categories_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),

  searchboks_input_search_autocomplete_typography:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),

  searchboks_input_search_autocomplete_typography_normal_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),

  searchboks_input_search_autocomplete_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),

  searchboks_input_search_autocomplete_typography_bold_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),

  searchboks_input_search_autocomplete_typography_hover_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),

  searchboks_input_search_autocomplete_background_hover_color:
    themeScheme.ColorIdScheme.default("color$$4").catch("color$$4"),

  searchboks_input_search_autocomplete_category_typography:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),

  searchboks_input_search_autocomplete_category_typography_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),

  search_bar_mobile_loupe_size:
    themeScheme.SettingNumberScheme.default("24").catch("24"),

  search_bar_mobile_loupe_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  remove_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
