import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxNewsletterMessagesScheme = z.object({
  newsletter_form_title: themeScheme.MessageScheme.default(
    "Zapisz się na newsletter"
  ),
  newsletter_form_description: themeScheme.MessageScheme.default(
    "Jeśli chcesz być na bieżąco ze wszystkimi nowościami i trendami lub odkryć ekskluzywne treści, subskrybuj nasz newsletter już teraz i wybierz swoje ulubione fasony."
  ),
  newsletter_form_heading_email: themeScheme.MessageScheme.default("Email"),
  newsletter_form_placeholder_email: themeScheme.MessageScheme.default("Email"),
  newsletter_form_save_button: themeScheme.MessageScheme.default("Subskrypcja"),
  newsletter_form_email_consent: themeScheme.MessageScheme.default(
    "Przeczytałem(am) i zrozumiałem(am) informacje dotyczące korzystania z moich danych osobowych wyjaśnione wpolitykę prywatności...."
  ),
  newsletter_form_empty_verification_email: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  newsletter_form_syntax_verification_email: themeScheme.MessageScheme.default(
    "Wprowadź poprawny adres e-mail, np. kowalski@domena.pl"
  ),
  newsletter_required_verification_consent: themeScheme.MessageScheme.default(
    "Zaznacz wymagane zgody"
  ),
  newsletter_form_error: themeScheme.MessageScheme.default(
    "Nie udało się wysłać zgłoszenia"
  ),
  newsletter_form_success: themeScheme.MessageScheme.default(
    "Poprawnie zapisałeś się na Newslleter"
  ),
  newsletter_form_regulations: themeScheme.MessageScheme.default(
    "Przeczytałem(am) i zrozumiałem(am) informacje dotyczące korzystania z moich danych osobowych wyjaśnione wpolitykę prywatności...."
  ),
  newsletter_form_regulations_link: themeScheme.MessageScheme.default(
    "Polityka prywatności"
  ),
});

export const IBoxNewsletterSettingsScheme = z.object({
  newsletter_form_wrapper_margin:
    themeScheme.MarginSizeScheme.default("30px").catch("30px"),
  newsletter_form_elements_gap:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  newsletter_form_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  newsletter_form_submit_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  newsletter_form_submit_button_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  newsletter_form_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  newsletter_form_wrapper_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  newsletter_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  newsletter_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  newsletter_form_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  newsletter_form_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_description_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  newsletter_form_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  newsletter_form_checkbox_error_color:
    themeScheme.ColorIdScheme.default("color$$12").catch("color$$12"),
  newsletter_form_checkbox_error_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  newsletter_form_checkbox_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_checkbox_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  newsletter_form_error_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  newsletter_form_alert_text_error_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_background_error_color:
    themeScheme.ColorIdScheme.default("color$$12").catch("color$$12"),
  newsletter_form_success_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  newsletter_form_alert_text_success_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_background_success_color:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  newsletter_form_consent_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  newsletter_form_consent_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  newsletter_form_consent_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  newsletter_form_consent_link_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
