import React, { useMemo, memo } from "react";
import Script from "next/script";
import cleanDeep from "clean-deep";
import { IPortalBox, TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { boxScheme } from "../../boxes/boxScheme";
import { boxTypes } from "../../boxes/boxTypes";
import { isPortalSide } from "../../helpers/helpers";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { MessagesSettingsContext } from "../Contexts/MessagesSettingsContext";
import { CustomCssWrapper } from "../CustomCssWrapper/CustomCssWrapper.styled";
import { StyledBox } from "./Box.styled";
import { isEqual } from "lodash";

export interface IBoxProps {
  boxData: IPortalBox;
  isEditMode: boolean;
  path: PortalPath;
  publicationRef?: React.RefObject<HTMLDivElement>;
  pathExtraSettings?: string;
}

const Box: React.FC<IBoxProps> = ({
  boxData,
  isEditMode,
  path,
  publicationRef,
  pathExtraSettings,
}: IBoxProps): JSX.Element => {
  const isSystem =
    boxData?.type === "SYSTEM" && boxData.content["component"] != null;

  const boxType = isSystem
    ? (boxData.content["component"] as TSystemBoxType)
    : boxData?.type;

  const messages = useMemo(
    () => boxScheme[boxType]?.messages.parse(boxData.messages),
    [boxData.messages, boxType]
  );

  const settings = useMemo(
    () => boxScheme[boxType]?.settings.parse(boxData.settings),
    [boxData.settings, boxType]
  );

  const BoxType: any = isSystem
    ? boxTypes.SYSTEM[boxType as TSystemBoxType]
    : boxTypes[boxType as TRegularBoxType];

  const contentSettings = useMemo(
    () =>
      cleanDeep(boxData.contentSettings, {
        emptyArrays: false,
        emptyStrings: false,
        emptyObjects: false,
      }),
    [boxData.contentSettings]
  );

  const content = useMemo(
    () =>
      cleanDeep(boxData.content, {
        emptyArrays: false,
        emptyStrings: false,
        emptyObjects: false,
      }),
    [boxData.content]
  );

  const displaySettings = useMemo(
    () =>
      cleanDeep(boxData.displaySettings, {
        emptyArrays: false,
        emptyStrings: false,
        emptyObjects: false,
      }),
    [boxData.displaySettings]
  );

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!BoxType) {
    return (
      <div>
        No box found for type {boxData?.type} {boxData.content["component"]}
      </div>
    );
  }

  return (
    <CustomCssWrapper
      data-testid="customCss"
      id="custom-Css"
      customCss={boxData.customCss}
    >
      <StyledBox id="box-container">
        <MessagesSettingsContext.Provider
          value={{ messages, settings, boxType }}
        >
          <BoxType
            {...{
              ...boxData,
              messages,
              settings,
              contentSettings,
              content,
              displaySettings,
            }}
            editMode={isEditMode}
            path={path}
            publicationRef={publicationRef}
            pathExtraSettings={pathExtraSettings}
          />
        </MessagesSettingsContext.Provider>
        {isPortalSide() && boxData.customJs && (
          <Script
            id={`box-${boxData.id}-${Math.random() * 999}`}
            strategy="lazyOnload"
            nonce={process.env["NEXT_PUBLIC_CSP_NONCE"]}
          >
            {boxData.customJs}
          </Script>
        )}
      </StyledBox>
    </CustomCssWrapper>
  );
};

export default memo(Box, isEqual);
