import {
  AddToWishlistSettingSchema,
  ComparisonSettingSchema,
} from "../../settingsPatterns/contentPatterns.scheme";

import { PIMInputTypes } from "@ecp-redux/dto/productList.types";
import {
  SortingItemType,
  SortingItemVariant,
} from "@ecp-redux/dto/searchResults.types";
import {
  HoverEffects,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { z } from "zod";
import { createTextStyleShowSettingScheme } from "./../../../../../redux/src/lib/dto/themeSettings/themeSettings.schemes";
import {
  ProductSliderBoxType,
  ProductSliderBoxViewType,
  SlideRotateOptions,
  dynamicProductListOption,
} from "./BoxProductSlider.types";
import { SliderPaginationOptions } from "@ecp-boxes/settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import {
  AddToCartQuantityScheme,
  ProductButtonActions,
} from "@ecp-boxes/shared/components/domain/Product/ProductModule/ProductModule.types";
import {
  MainButtonContentSettingScheme,
  createLinkStyleSettingScheme,
  createTypoStyleSettingScheme,
} from "@ecp-boxes/settingsSchemes/settingsSchemes.utils";
import {
  BoxSrpProductSliderCustomAttributeScheme,
  createSecondaryButtonScheme,
} from "../BoxSearchResults/BoxSearchResults.scheme";

export const IBoxProductSliderMessagesScheme = z.object({
  no_photo_text: themeScheme.MessageScheme.default("Brak zdjęcia"),
  unknown_product_text: themeScheme.MessageScheme.default("unknownName"),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
  no_content_message: themeScheme.MessageScheme.default(
    "No content to display"
  ),
  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
  add_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Dodano produkt do porównania"
  ),
  remove_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Usunięto produkt z porównania"
  ),
  error_message: themeScheme.MessageScheme.default(
    "Błąd dodawania do porównania"
  ),
});

export const IBoxProductSliderSettingsScheme = z.object({
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  add_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  remove_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  error_message_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  attribiute_in_row:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
  attribute_row_1: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_2: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_3: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_4: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_5: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_6: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_7: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_8: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_9: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_10: themeScheme.SettingAttributesScheme.default("").catch(""),
});

const productSRPModuleElement = [
  "productName",
  "actualPrice",
  "oldPrice",
  "netPrice",
  "rating",
  "button",
  "secondaryButton",
] as const;

const productElementInModule = [
  "productName",
  "actualPrice",
  "oldPrice",
  "netPrice",
  "rating",
  "button",
  "secondaryButton",
];

export const BoxProductSliderDisplaySettingsScheme = z.object({
  paginationStyle: z
    .nativeEnum(SliderPaginationOptions)
    .default(SliderPaginationOptions.ARROWS)
    .catch(SliderPaginationOptions.ARROWS),
  paginationStyleGrid: z
    .nativeEnum(SliderPaginationOptions)
    .default(SliderPaginationOptions.NUMBERS)
    .catch(SliderPaginationOptions.NUMBERS),
  slidesRotate: z.array(z.number()).default([10]).catch([10]),
  slideRotateType: z
    .nativeEnum(SlideRotateOptions)
    .default(SlideRotateOptions.SINGLE)
    .catch(SlideRotateOptions.SINGLE),
  pageStyle: z
    .object({
      spaceBetweenRows: z.number().default(0).catch(0),
      spaceBetweenProducts: z.number().default(0).catch(0),
    })
    .default({
      spaceBetweenRows: 0,
      spaceBetweenProducts: 0,
    })
    .catch({
      spaceBetweenRows: 0,
      spaceBetweenProducts: 0,
    }),
  module: z
    .object({
      alignment: z
        .nativeEnum(AlignmentHorizontalOption)
        .default(AlignmentHorizontalOption.LEFT)
        .catch(AlignmentHorizontalOption.LEFT),
      padding: z.object({
        top: z.number().default(0).catch(0),
        right: z.number().default(0).catch(0),
        bottom: z.number().default(0).catch(0),
        left: z.number().default(0).catch(0),
      }),
      spaceBetweenPhotoAndContent: z.number().default(0).catch(0),
      spaceBetweenTextElements: z.number().default(0).catch(0),
      background: themeScheme.ColorReadoutValueScheme.default({
        id: "color$$9",
        custom: "#000000",
      }).catch({
        id: "color$$9",
        custom: "#000000",
      }),
      border: z
        .object({
          style: z
            .nativeEnum(OutlineOptions)
            .default(OutlineOptions.NONE)
            .catch(OutlineOptions.NONE),
          width: z.number().default(0).catch(0),
          color: themeScheme.ColorReadoutValueScheme.default(
            themeScheme.ColorReadoutValueScheme.parse({})
          ).catch(themeScheme.ColorReadoutValueScheme.parse({})),
        })
        .default({
          style: OutlineOptions.NONE,
          width: 0,
          color: themeScheme.ColorReadoutValueScheme.parse({}),
        })
        .catch({
          style: OutlineOptions.NONE,
          width: 0,
          color: themeScheme.ColorReadoutValueScheme.parse({}),
        }),
      spaceBetweenSlides: z.number().default(5).catch(5),
      spaceBetweenPhotoAndAttributes: z.number().default(5).catch(5),
      pictureAlignment: z
        .nativeEnum(AlignmentVerticalOption)
        .default(AlignmentVerticalOption.TOP)
        .catch(AlignmentVerticalOption.TOP),
      attributesAlignment: z
        .nativeEnum(AlignmentVerticalOption)
        .default(AlignmentVerticalOption.TOP)
        .catch(AlignmentVerticalOption.TOP),
    })
    .default({
      alignment: AlignmentHorizontalOption.LEFT,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      spaceBetweenPhotoAndContent: 0,
      spaceBetweenTextElements: 0,
      background: {
        id: "color$$9",
        custom: "#000000",
      },
      border: {
        style: OutlineOptions.NONE,
        width: 0,
        color: themeScheme.ColorReadoutValueScheme.parse({}),
      },
      spaceBetweenSlides: 5,
      spaceBetweenPhotoAndAttributes: 5,
      pictureAlignment: AlignmentVerticalOption.TOP,
      attributesAlignment: AlignmentVerticalOption.TOP,
    })
    .catch({
      alignment: AlignmentHorizontalOption.LEFT,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      spaceBetweenPhotoAndContent: 0,
      spaceBetweenTextElements: 0,
      background: {
        id: "color$$9",
        custom: "#000000",
      },
      border: {
        style: OutlineOptions.NONE,
        width: 0,
        color: themeScheme.ColorReadoutValueScheme.parse({}),
      },
      spaceBetweenSlides: 5,
      spaceBetweenPhotoAndAttributes: 5,
      pictureAlignment: AlignmentVerticalOption.TOP,
      attributesAlignment: AlignmentVerticalOption.TOP,
    }),
  orderInModule: z
    .array(z.union([z.enum(productSRPModuleElement), z.string()]))
    .transform((value) => [...new Set([...value, ...productSRPModuleElement])])
    .default(productElementInModule)
    .catch(productElementInModule),
});

export const BoxProductSliderContentSchema = z.object({
  ratingText: z.string().default("Reviews"),
  productButtonText: z.string().default("Add to cart"),
  headerText: z.string().default("Heading"),
});

const textStyleShowSettingSchema = z.object({
  show: z.boolean().default(true).catch(true),
  font: themeScheme.TypoReadoutValueScheme.default({
    id: "typo$$2",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 2,
      lineHeight: 2,
      letterSpacing: 2,
    },
  }).catch({
    id: "typo$$2",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 2,
      lineHeight: 2,
      letterSpacing: 2,
    },
  }),
  text: z
    .object({
      color: themeScheme.ColorReadoutValueScheme,
    })
    .default({
      color: { id: "color$$2", custom: "#000000" },
    })
    .catch({ color: { id: "color$$2", custom: "#000000" } }),

  heading: z.string().default(""),
  spaceBetweenHeadingAndAttribute: z.number().default(5),
});

const productAttributeSchema = z.object({
  ...textStyleShowSettingSchema.shape,
  prefix: z.object({
    ...textStyleShowSettingSchema.shape,
    heading: z.string().default(""),
    spaceBetweenHeadingAndAttribute: z.number().default(5),
  }),
  suffix: z.object({
    ...textStyleShowSettingSchema.shape,
    heading: z.string().default(""),
    spaceBetweenHeadingAndAttribute: z.number().default(5),
  }),
});

export const getDefaultProductCustomAttributeSettings = () => {
  return productAttributeSchema.parse({
    ...textStyleShowSettingSchema.parse({
      id: "typo$$1",
      custom: { id: "color$$1", custom: "#000000" },
    }),
    prefix: {
      ...textStyleShowSettingSchema.parse({
        id: "typo$$1",
        custom: { id: "color$$1", custom: "#000000" },
        show: false,
      }),
      heading: "",
      spaceBetweenHeadingAndAttribute: 5,
    },
    suffix: {
      ...textStyleShowSettingSchema.parse({
        id: "typo$$1",
        custom: { id: "color$$1", custom: "#000000" },
        show: false,
      }),
      heading: "",
      spaceBetweenHeadingAndAttribute: 5,
    },
  });
};

export const BoxProductSliderContentSettingsSchema = z.object({
  sliderSettings: z
    .object({
      type: z
        .nativeEnum(ProductSliderBoxType)
        .default(ProductSliderBoxType.SLIDER)
        .catch(ProductSliderBoxType.SLIDER),
      viewType: z
        .nativeEnum(ProductSliderBoxViewType)
        .default(ProductSliderBoxViewType.TOP_PICTURE)
        .catch(ProductSliderBoxViewType.TOP_PICTURE),
      maxNumberOfProducts: z.number().default(12).catch(12),
      maxNumberOfProductsGrid: z.number().default(60).catch(60),
      numberOfProductsPerSlide: z.number().default(3).catch(3),
      numberOfProductsPerRow: z.number().default(2).catch(2),
      numberOfRows: z.number().default(10).catch(10),
      sorting: z
        .nativeEnum(SortingItemType)
        .default(SortingItemType.DATE_ADDED_DESC)
        .catch(SortingItemType.DATE_ADDED_DESC),
      sortingAttributeCode: z.string().default("").catch(""),
      sortingAttributeName: z.string().default("").catch(""),
      sortingAttributeVariant: z
        .nativeEnum(SortingItemVariant)
        .default(SortingItemVariant.ASC)
        .catch(SortingItemVariant.ASC),
      sortingAttributeType: z
        .union([z.nativeEnum(PIMInputTypes), z.literal("")])
        .default("")
        .catch(""),
    })
    .default({
      type: ProductSliderBoxType.SLIDER,
      viewType: ProductSliderBoxViewType.TOP_PICTURE,
      maxNumberOfProducts: 12,
      maxNumberOfProductsGrid: 60,
      numberOfProductsPerSlide: 3,
      numberOfProductsPerRow: 2,
      numberOfRows: 10,
      sorting: SortingItemType.DATE_ADDED_DESC,
      sortingAttributeCode: "",
      sortingAttributeName: "",
      sortingAttributeVariant: SortingItemVariant.ASC,
      sortingAttributeType: "",
    })
    .catch({
      type: ProductSliderBoxType.SLIDER,
      viewType: ProductSliderBoxViewType.TOP_PICTURE,
      maxNumberOfProducts: 12,
      maxNumberOfProductsGrid: 60,
      numberOfProductsPerSlide: 3,
      numberOfProductsPerRow: 2,
      numberOfRows: 10,
      sorting: SortingItemType.DATE_ADDED_DESC,
      sortingAttributeCode: "",
      sortingAttributeName: "",
      sortingAttributeVariant: SortingItemVariant.ASC,
      sortingAttributeType: "",
    }),
  product: z
    .object({
      productName: z
        .object({
          link: createLinkStyleSettingScheme("link$$1"),
          text: createTypoStyleSettingScheme("typo$$7"),
          show: z.boolean().default(true).catch(true),
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .default({
          link: createLinkStyleSettingScheme("link$$1").parse({}),
          text: createTypoStyleSettingScheme("typo$$7").parse({}),
          show: true,
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
        })
        .catch({
          link: createLinkStyleSettingScheme("link$$1").parse({}),
          text: createTypoStyleSettingScheme("typo$$7").parse({}),
          show: true,
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
        }),
      actualPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              themeScheme.createTextStyleShowSettingScheme(
                "typo$$1",
                "color$$1",
                false
              )
            )
            .default(themeScheme.affixFallback),
        })
        .merge(
          themeScheme.createTextStyleShowSettingScheme("typo$$4", "color$$1")
        )
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$4", "color$$1").parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$4", "color$$1").parse({}),
        }),
      oldPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
        })
        .merge(createTextStyleShowSettingScheme("typo$$7", "color$$2"))
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$7", "color$$2").parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$7", "color$$2").parse({}),
        }),
      netPrice: z
        .object({
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
        })
        .merge(createTextStyleShowSettingScheme("typo$$1", "color$$1", true))
        .default({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}),
        })
        .catch({
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$1", "color$$1").parse({}),
        }),
      rating: z
        .object({
          filledPointsColor: themeScheme.ColorReadoutValueScheme.removeCatch()
            .removeDefault()
            .default({ id: "color$$1", custom: "#000000" })
            .catch({ id: "color$$1", custom: "#000000" }),
          unfilledPointsColor: themeScheme.ColorReadoutValueScheme.removeCatch()
            .removeDefault()
            .default({ id: "color$$2", custom: "#000000" })
            .catch({ id: "color$$2", custom: "#000000" }),
          prefix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
          suffix: z
            .object({
              heading: z.string().default("").catch(""),
              spaceBetweenHeadingAndAttribute: z.number().default(5).catch(5),
            })
            .merge(
              createTextStyleShowSettingScheme("typo$$1", "color$$1", false)
            )
            .default(themeScheme.affixFallback),
        })
        .merge(createTextStyleShowSettingScheme("typo$$7", "color$$1"))
        .default({
          filledPointsColor: { id: "color$$1", custom: "#000000" },
          unfilledPointsColor: { id: "color$$2", custom: "#000000" },
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$7", "color$$1").parse({}),
        })
        .catch({
          filledPointsColor: { id: "color$$1", custom: "#000000" },
          unfilledPointsColor: { id: "color$$2", custom: "#000000" },
          prefix: themeScheme.affixFallback,
          suffix: themeScheme.affixFallback,
          ...createTextStyleShowSettingScheme("typo$$7", "color$$1").parse({}),
        }),
      button: createSecondaryButtonScheme().default({
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.COMPARISON,
        url: "",
        comparisonSetting: ComparisonSettingSchema.parse({}),
        addToWishlistSetting: AddToWishlistSettingSchema.parse({}),
        ...MainButtonContentSettingScheme.parse({}),
        show: false,
        labelText: "Button",
      }),
      secondaryButton: createSecondaryButtonScheme().default({
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.COMPARISON,
        url: "",
        comparisonSetting: ComparisonSettingSchema.parse({}),
        addToWishlistSetting: AddToWishlistSettingSchema.parse({}),
        ...MainButtonContentSettingScheme.parse({}),
        show: false,
        labelText: "Add to cart",
      }),
      custom: z
        .record(z.string(), BoxSrpProductSliderCustomAttributeScheme)
        .default({})
        .catch({}),
      customActionAttributes: z
        .object({
          addToCartQuantity: AddToCartQuantityScheme.optional(),
          customButton: createSecondaryButtonScheme().optional(),
          addToCartUnit: themeScheme.DropdownReadoutValueScheme.optional(),
        })
        .default({}),
    })
    .default({
      productName: {
        ...themeScheme.productAffixFallback,
        link: createLinkStyleSettingScheme("link$$1").parse({}),
        text: createTypoStyleSettingScheme("typo$$7").parse({}),
      },
      actualPrice: themeScheme.productFromProductSliderAffixFallback(
        "typo$$7",
        "color$$1"
      ),
      oldPrice: themeScheme.productAffixFallback,
      netPrice: themeScheme.productFromProductSliderAffixFallback(
        "typo$$1",
        "color$$1"
      ),
      rating: {
        ...themeScheme.productFromProductSliderAffixFallback(
          "typo$$7",
          "color$$1"
        ),
        filledPointsColor: { id: "color$$1", custom: "#000000" },
        unfilledPointsColor: { id: "color$$2", custom: "#000000" },
      },
      custom: {},
      customActionAttributes: {},
      button: {
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.ADD_TO_CART,
        url: "",
        comparisonSetting: ComparisonSettingSchema.default(
          ComparisonSettingSchema.parse({})
        ).catch(ComparisonSettingSchema.parse({})),
        addToWishlistSetting: AddToWishlistSettingSchema.default(
          AddToWishlistSettingSchema.parse({})
        ).catch(AddToWishlistSettingSchema.parse({})),
        ...MainButtonContentSettingScheme.parse({}),
      },
    })
    .catch({
      productName: {
        ...themeScheme.productAffixFallback,
        link: createLinkStyleSettingScheme("link$$1").parse({}),
        text: createTypoStyleSettingScheme("typo$$7").parse({}),
      },
      actualPrice: themeScheme.productAffixFallback,
      oldPrice: themeScheme.productAffixFallback,
      netPrice: themeScheme.productAffixFallback,
      rating: {
        ...themeScheme.productAffixFallback,
        filledPointsColor: { id: "color$$1", custom: "#000000" },
        unfilledPointsColor: { id: "color$$2", custom: "#000000" },
      },
      custom: {},
      customActionAttributes: {},
      button: {
        isCustomWidth: false,
        customWidth: 100,
        labelText: "Button",
        type: ProductButtonActions.ADD_TO_CART,
        url: "",
        comparisonSetting: ComparisonSettingSchema.parse({}),
        addToWishlistSetting: AddToWishlistSettingSchema.parse({}),
        ...MainButtonContentSettingScheme.parse({}),
      },
      secondaryButton: {
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.COMPARISON,
        url: "",
        comparisonSetting: ComparisonSettingSchema.parse({}),
        addToWishlistSetting: AddToWishlistSettingSchema.parse({}),
        labelText: "Add to cart",
        ...MainButtonContentSettingScheme.parse({}),
      },
    }),
  productPhoto: z
    .object({
      height: z.number().default(364).catch(364),
      hover: z
        .union([z.nativeEnum(HoverEffects), z.nativeEnum(ProductHoverEffects)])
        .default(HoverEffects.ZOOMIN)
        .catch(HoverEffects.ZOOMIN),
      photoSize: z.number().default(30).catch(30),
    })
    .default({ height: 364, hover: HoverEffects.ZOOMIN, photoSize: 30 })
    .catch({
      height: 364,
      hover: HoverEffects.ZOOMIN,
      photoSize: 30,
    }),
  badge: z
    .object({
      show: z.boolean().default(true).catch(true),
    })
    .default({ show: true }),
  favorites: z
    .object({
      show: z.boolean().default(true).catch(true),
    })
    .default({ show: true }),
  searchQuery: z.string().default("").catch(""),
  dynamicProductListOption: z.union([
    z.nativeEnum(dynamicProductListOption),
    z.null(),
    z.undefined(),
  ]),
  selectedAssociation: z.union([z.string(), z.undefined(), z.null()]),
  header: z
    .object({
      padding: themeScheme.SpacingScheme.default(
        themeScheme.SpacingScheme.parse({})
      ).catch(themeScheme.SpacingScheme.parse({})),
      contentAlignment: z
        .nativeEnum(AlignmentHorizontalOption)
        .default(AlignmentHorizontalOption.LEFT)
        .catch(AlignmentHorizontalOption.LEFT),
      font: themeScheme.TypoReadoutValueScheme.default(
        themeScheme.TypoReadoutValueScheme.parse({})
      ).catch(themeScheme.TypoReadoutValueScheme.parse({})),
      text: z
        .object({
          color: themeScheme.ColorReadoutValueSchemeBlackDefault,
        })
        .default({
          color: themeScheme.ColorReadoutValueSchemeBlackDefault.parse({}),
        })
        .catch({
          color: themeScheme.ColorReadoutValueSchemeBlackDefault.parse({}),
        }),
      show: z.boolean().default(false).catch(false),
    })
    .default({
      padding: themeScheme.SpacingScheme.parse({}),
      contentAlignment: AlignmentHorizontalOption.LEFT,
      font: themeScheme.TypoReadoutValueScheme.parse({}),
      text: {
        color: themeScheme.ColorReadoutValueSchemeBlackDefault.parse({}),
      },
      show: false,
    })
    .catch({
      padding: themeScheme.SpacingScheme.parse({}),
      contentAlignment: AlignmentHorizontalOption.LEFT,
      font: themeScheme.TypoReadoutValueScheme.parse({}),
      text: {
        color: themeScheme.ColorReadoutValueSchemeBlackDefault.parse({}),
      },
      show: false,
    }),
  variants: z.array(z.any()).default([]).catch([]),
  attributes: z.array(z.any()).default([]).catch([]),
});
