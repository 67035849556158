import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  SettingActive,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxLoginMessagesScheme = z.object({
  login_form_title: themeScheme.MessageScheme.default("Zaloguj się"),
  login_form_heading_email: themeScheme.MessageScheme.default("Email"),
  login_form_placeholder_email: themeScheme.MessageScheme.default("Email"),
  login_form_heading_password: themeScheme.MessageScheme.default("Hasło"),
  login_form_placeholder_password: themeScheme.MessageScheme.default("Hasło"),
  login_form_empty_verification_email: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  login_form_syntax_verification_email: themeScheme.MessageScheme.default(
    "Wprowadź poprawny adres e-mail, np. kowalski@domena.pl"
  ),
  login_form_empty_verification_password: themeScheme.MessageScheme.default(
    "Uzupełnienie pola jest wymagane"
  ),
  login_form_syntax_verification_password:
    themeScheme.MessageScheme.default("Hasło jest błędne"),
  login_form_save_button: themeScheme.MessageScheme.default("Zaloguj"),
  login_form_error: themeScheme.MessageScheme.default(
    "Niepoprawny login lub hasło"
  ),
  login_form_account_blocked: themeScheme.MessageScheme.default(
    "Konto zostało chwilowo zablokowane"
  ),
  login_form_remind_password:
    themeScheme.MessageScheme.default("Przypomnij hasło"),
  login_form_remind_password_success: themeScheme.MessageScheme.default(
    "Twoje hasło zostało zmienione. Zaloguj się ponownie."
  ),
  login_form_privacy_policy: themeScheme.MessageScheme.default(
    `Klikając zaloguj akceptujesz nasz regulamin i <a href="#"> Politykę prywatności </a>`
  ),
  login_form_redirect_button:
    themeScheme.MessageScheme.default("Zarejestruj się"),
  login_form_error_not_actived: themeScheme.MessageScheme.default(
    "Twoje konto nie jest aktywne"
  ),
  shopping_without_register_next_button:
    themeScheme.MessageScheme.default("Dalej"),
  order_without_login_privacy_policy: themeScheme.MessageScheme.default(
    "Klikając Dalej akceptujesz nasz regulamin i "
  ),
  shopping_without_register_subtitle: themeScheme.MessageScheme.default(
    "Potrzebujemy Twojego adresu e-mail, aby wysłać aktualizacje dotyczące Twojego zamówienia"
  ),
  shopping_without_register_title: themeScheme.MessageScheme.default(
    "Wpisz swój adres e-mail, aby kontynuować"
  ),
  login_form_shopping_without_register: themeScheme.MessageScheme.default(
    "Zakupy bez rejestracji"
  ),
  register_code_success: themeScheme.MessageScheme.default(
    "Twoje konto jest juz aktywne, mozesz się zalogować"
  ),
  register_code_failed_header: themeScheme.MessageScheme.default(
    "Link aktywacyjny utracił swoją ważność. Wygeneruj link ponownie"
  ),
  register_code_failed_description: themeScheme.MessageScheme.default(
    "Możesz z łatwością cofnąć każdą z wyrażonych zgód, w każdej chwili, poprzez jeden z poniższych sposobów: zmianę ustawień Twojego konta na stronie www.hennes.com, wysyłając wiadomość na adres mojedane@hennes.com, poprzez formularz kontaktowy na stronie https://www.hennes.com/contacts wybierając temat :ochrona danych osobowych, lub wysyłając list na adres: Hennes S.A., ul. Domaniewska 48, 02-672 Warszawa (najlepiej z dopiskiem „Dane Osobowe”)."
  ),
  register_code_failed_button_text:
    themeScheme.MessageScheme.default("Wygeneruj Token"),
  register_code_resend_token_success: themeScheme.MessageScheme.default(
    "Link resetujący hasło został wysłany na podany adres e-mail."
  ),
  account_already_exist: themeScheme.MessageScheme.default(
    "Konto o podanym adresie już istnieje"
  ),
  login_execute_recaptcha_error: themeScheme.MessageScheme.default(
    "Błąd w działaniu captcha. Spróbuj jeszcze raz lub skontaktuj się z administratorem."
  ),
});

export const IBoxLoginSettingsScheme = z.object({
  login_form_redirect_after_login:
    themeScheme.UrlScheme.default("/").catch("/"),
  login_form_redirect_remind_password:
    themeScheme.UrlScheme.default("/").catch("/"),
  login_form_redirect_remind_password_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  login_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  login_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  login_form_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  login_form_paragraph_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  login_form_input_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  login_form_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  login_form_login_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  login_form_redirect_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  login_form_redirect_after_button_2:
    themeScheme.UrlScheme.default("/register").catch("/register"),
  login_form_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  login_form_login_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  login_form_login_button_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  login_form_redirect_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  login_form_redirect_button_alignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  login_form_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  login_form_terms_link_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  login_form_wrapper_margin:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  login_form_privacy_policy_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  login_form_privacy_policy_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  login_form_color_error:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  login_form_color_main:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  login_form_error_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  shopping_without_register:
    themeScheme.ButtonIdScheme.default("button$$9").catch("button$$9"),
  shopping_step_2_url: themeScheme.UrlScheme.default(
    "/dostawa-i-platnosc"
  ).catch("/dostawa-i-platnosc"),
  register_code_success_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"), //do potwierdzenia
  register_code_success_color_main:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  register_code_success_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  register_code_failed_color_header:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  register_code_failed_color_description:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  register_code_failed_header_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  register_code_failed_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  register_code_failed_button_typo:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  register_code_failed_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  register_code_failed_header_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  register_code_failed_description_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  register_code_resend_token_info_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  register_code_resend_token_info_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  login_form_wrapper_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  login_form_remind_password_active: z
    .nativeEnum(SettingActive)
    .default(SettingActive.ON)
    .catch(SettingActive.ON),
  login_form_remind_password_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  login_form_button_2_active: z
    .nativeEnum(SettingActive)
    .default(SettingActive.ON)
    .catch(SettingActive.ON),
});
