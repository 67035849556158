import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxMyAccountMyOrdersMessagesScheme = z.object({
  customer_data_orders_title:
    themeScheme.MessageScheme.default("Moje zamówienia"),
  customer_data_orders_count:
    themeScheme.MessageScheme.default("Ilość zamówień:"),
  customer_data_orders_heading_number:
    themeScheme.MessageScheme.default("Numer zamówienia"),
  customer_data_orders_heading_date:
    themeScheme.MessageScheme.default("Data zamówienia"),
  customer_data_orders_heading_final_value:
    themeScheme.MessageScheme.default("Koszt całkowity"),
  customer_data_orders_heading_status:
    themeScheme.MessageScheme.default("Status zamówienia"),
  customer_data_orders_heading_product_status:
    themeScheme.MessageScheme.default("Status produktu:"),
  customer_data_orders_heading_product_quantity:
    themeScheme.MessageScheme.default("Liczba sztuk"),
  customer_data_orders_details_button:
    themeScheme.MessageScheme.default("Zobacz szczegóły"),
  customer_data_orders_reorder_button:
    themeScheme.MessageScheme.default("Ponów zamówienie"),
  customer_data_reorder_unavailable_description:
    themeScheme.MessageScheme.default(
      "Nie udało się dodać produktów do koszyka. Produkty są obecnie niedostępne"
    ),
  customer_data_reorder_unavailable_button_save:
    themeScheme.MessageScheme.default("Zamknij"),
  customer_data_reorder_popup_title:
    themeScheme.MessageScheme.default("Ponów zamówienie"),
  customer_data_reorder_popup_description: themeScheme.MessageScheme.default(
    "W Twoim koszyku są już inne produkty. Czy chcesz je dodać do zamówienia?"
  ),
  customer_data_reorder_popup_button_save:
    themeScheme.MessageScheme.default("Potwierdź"),
  customer_data_reorder_popup_button_cancel:
    themeScheme.MessageScheme.default("Anuluj"),
  customer_data_order_repeat_payment_button:
    themeScheme.MessageScheme.default("Ponów płatność"),
  customer_data_orders_cancel_button:
    themeScheme.MessageScheme.default("Anuluj zamówienie"),
  customer_data_cancel_order_popup_title: themeScheme.MessageScheme.default(
    "Anulacja zamówienia"
  ),
  customer_data_cancel_order_popup_description:
    themeScheme.MessageScheme.default(
      "Czy na pewno chcesz anulować zamówienie?"
    ),
  customer_data_cancel_order_popup_button_save:
    themeScheme.MessageScheme.default("Potwierdź"),
  customer_data_cancel_order_popup_button_cancel:
    themeScheme.MessageScheme.default("Anuluj"),
  customer_data_orders_heading_product_price:
    themeScheme.MessageScheme.default("Cena"),
  paginationFrom: themeScheme.MessageScheme.default("from"),
});

export const ITBoxMyAccountMyOrdersSettingsScheme = z.object({
  customer_data_orders_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_orders_heading_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_orders_heading_buttons_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  customer_data_orders_order_details_url:
    themeScheme.UrlScheme.default("/order-details").catch("/order-details"),
  customer_data_orders_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  customer_data_orders_title_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_count_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_count_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_background_color:
    themeScheme.ColorIdScheme.default("color$$7").catch("color$$7"),
  customer_data_orders_heading_number_label_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  customer_data_orders_heading_number_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_number_value_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  customer_data_orders_heading_number_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_status_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_status_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_status_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_orders_heading_status_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_date_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_date_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_date_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_orders_heading_date_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_final_value_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_final_value_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_final_value_typo:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  customer_data_orders_heading_final_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_details_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_orders_repeat_payment_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  customer_data_orders_cancel_button_style:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  customer_data_orders_reorder_button_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  customer_data_orders_reorder_button_typo:
    themeScheme.TypoIdScheme.default("typo$$10").catch("typo$$10"),
  customer_data_orders_heading_product_name_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_name_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_quantity_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_quantity_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_quantity_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_quantity_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_pagination_text_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_pagination_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  popup_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_description_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  popup_description_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  popup_yes_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  popup_no_button:
    themeScheme.ButtonIdScheme.default("button$$2").catch("button$$2"),
  redirect_after_cancel_link: themeScheme.UrlScheme.default("/").catch("/"),
  customer_data_reorder_success_link:
    themeScheme.UrlScheme.default("/").catch("/"),
  customer_data_order_repeat_payment_redirect_link:
    themeScheme.UrlScheme.default("/").catch("/"),
  customer_data_orders_heading_product_status_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_status_label_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  customer_data_orders_heading_product_status_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  customer_data_orders_heading_product_status_value_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
});
