import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxDropdownMessagesScheme = z.object({
  dropdown_labels: themeScheme.MessageArrayScheme.default(
    "label1;label2;label3"
  ),
  dropdown_main_label: themeScheme.MessageScheme.default("main_label"),
});

export const IBoxDropdownSettingsScheme = z.object({
  dropdown_label_main_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  dropdown_label_main_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  dropdown_label_typography:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  dropdown_label_link_style:
    themeScheme.LinkIdScheme.default("link$$2").catch("link$$2"),
  close_dropdown_icon_url: themeScheme.UrlScheme.default("").catch(""),
  close_dropdown_icon_size:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
  open_dropdown_icon_url: themeScheme.UrlScheme.default("").catch(""),
  open_dropdown_icon_size:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
  dropdown_min_width: themeScheme.SizeCSSScheme.default("100px").catch("100px"),
  dropdown_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  open_dropdown_left_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  open_dropdown_right_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  open_dropdown_top_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  open_dropdown_bottom_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  close_dropdown_left_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  close_dropdown_right_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  close_dropdown_top_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  close_dropdown_bottom_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  close_dropdown_border_size:
    themeScheme.SizeCSSScheme.default("3px").catch("3px"),
  close_dropdown_border_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  close_dropdown_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  open_dropdown_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  dropdown_label_redirect_urls: themeScheme.UrlArrayScheme.default("/;/;/"),
  items_in_dropdown_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  box_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
});
