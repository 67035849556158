import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import { IAddToCartQuantity } from "@ecp-redux/dto/cart.types";

const addToCartQuantityInit: IAddToCartQuantity = { status: "OK", quantity: 1 };

export const CommunicationBetweenBoxesContext = createContext<{
  addToCartQuantity: IAddToCartQuantity;
  changeAddToCartQuantity: (v: IAddToCartQuantity | number) => void;
  chosenUnit: string;
  setChosenUnit: React.Dispatch<React.SetStateAction<string>>;
}>({
  addToCartQuantity: addToCartQuantityInit,
  changeAddToCartQuantity: (v: IAddToCartQuantity | number) => null,
  chosenUnit: "",
  setChosenUnit: (v) => null,
});

export function useCommunicationBetweenBoxesContext() {
  const context = useContext(CommunicationBetweenBoxesContext);
  if (!context) {
    throw new Error(
      "useCommunicationBetweenBoxesContext must be used under CommunicationBetweenBoxesContext.Provider"
    );
  }
  return context;
}

export const CommunicationBetweenBoxesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { asPath: url } = useRouter();
  const [addToCartQuantity, setAddToCartQuantity] =
    useState<IAddToCartQuantity>(addToCartQuantityInit);

  const [chosenUnit, setChosenUnit] = useState("");
  useEffect(() => {
    setAddToCartQuantity(addToCartQuantityInit);
  }, [url]);

  const changeAddToCartQuantity = (v: IAddToCartQuantity | number) =>
    typeof v === "number"
      ? setAddToCartQuantity({ status: "OK", quantity: v })
      : setAddToCartQuantity(v);

  return (
    <CommunicationBetweenBoxesContext.Provider
      value={{
        addToCartQuantity,
        changeAddToCartQuantity,
        chosenUnit,
        setChosenUnit,
      }}
    >
      {children}
    </CommunicationBetweenBoxesContext.Provider>
  );
};
