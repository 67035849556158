import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxOrderSummaryMessagesScheme = z.object({
  email: themeScheme.MessageScheme.default("E-MAIL"),
  payment_method: themeScheme.MessageScheme.default("Metoda płatności"),
  order_date: themeScheme.MessageScheme.default("Data zamówienia"),
  phone: themeScheme.MessageScheme.default("Numer kontaktowy"),
  status: themeScheme.MessageScheme.default("Status"),
  delivery_time: themeScheme.MessageScheme.default("Dostawa"),
  delivery_address: themeScheme.MessageScheme.default("Adres dostawy"),
  header: themeScheme.MessageScheme.default("DANA ZAMÓWIENIA - "),
  expected_delivery_time: themeScheme.MessageScheme.default(
    "Przewidywany czas dostawy: "
  ),
  order_status_NEW: themeScheme.MessageScheme.default("Nowe"),
  order_status_WAITING_FOR_PAYMENT: themeScheme.MessageScheme.default(
    "Oczekuje na płatność"
  ),
  order_status_NOT_PAID: themeScheme.MessageScheme.default("Niezapłacone"),
  order_status_COMPLETED: themeScheme.MessageScheme.default("Zakończone"),
  payment_name_CASH_ON_DELIVERY: themeScheme.MessageScheme.default(
    "Płatność przy odbiorze"
  ),
  payment_name_E_WALLET: themeScheme.MessageScheme.default("Portfel e-wallet"),
  payment_name_GIFT_CARD:
    themeScheme.MessageScheme.default("Karta podarunkowa"),
  payment_name_PAYMENT_IN_ADVANCE:
    themeScheme.MessageScheme.default("Płatność z góry"),
});

export const IBoxOrderSummarySettingsScheme = z.object({
  basket_step_2_3_typography_header_1:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  basket_step_2_3_typography_paragraph_4:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  basket_step_all_typography_paragraph_2:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  basket_step_3_color_background:
    themeScheme.ColorIdScheme.default("color$$6").catch("color$$6"),
  basket_step_all_color_main:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_3_url_step_0:
    themeScheme.UrlScheme.default("/cart").catch("/cart"),
});
