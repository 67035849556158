import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import React from "react";
import { useTheme } from "styled-components";
import { convertButtonIdToValues } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButtonWrapper, {
  IButtonSettings,
} from "./StyledButtonWrapper.styled";

interface IStyledButtonProps extends IButtonSettings {
  renderAs?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  role?: string;
  show?: boolean;
  type?: string;
  target?: boolean;
  disabled?: boolean;
  [key: PropertyKey]: any;
}

const StyledButton = React.forwardRef<HTMLButtonElement, IStyledButtonProps>(
  (
    {
      $settings,
      $uniqueIcon,
      className,
      onClick,
      renderAs,
      children,
      role,
      show = true,
      type,
      target,
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme() as IThemeState;
    const buttonSettings = convertButtonIdToValues(
      $settings,
      theme?.globalObjects?.buttons
    );

    const icon = $uniqueIcon ?? buttonSettings.icon;

    const isSvg = icon.iconUrl?.endsWith(".svg");

    return (
      <StyledButtonWrapper
        $settings={$settings}
        className={className}
        onClick={() => onClick && onClick()}
        renderAs={renderAs}
        $uniqueIcon={$uniqueIcon}
        data-testid="styled-button-component"
        role={role}
        show={show}
        type={type}
        target={target}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        <>
          {isSvg ? (
            <div
              style={{
                width: icon.size,
                height: icon.size,
                order: icon.position === "LEFT" ? 0 : 1,
              }}
              className="svg"
            >
              <i />
            </div>
          ) : (
            <i />
          )}

          {children}
        </>
      </StyledButtonWrapper>
    );
  }
);

StyledButton.displayName = "StyledButton";

export default StyledButton;
