import { IAddLastSeenRequest } from "../dto/lastSeen.types";
import { TSkusResponse } from "../dto/searchResults.types";
import { API, api } from "./api";

export const lastSeen = api.injectEndpoints({
  endpoints: (builder) => ({
    postAddLastSeen: builder.mutation<void, IAddLastSeenRequest>({
      query: ({ productSku }) => {
        return {
          url: API.postAddLastSeen.endpoint(),
          method: "POST",
          body: { productSku },
        };
      },
    }),

    getLastSeen: builder.query<TSkusResponse, void>({
      query: () => {
        return API.getLastSeen.endpoint();
      },
      providesTags: ["PORTAL_USER_AUTH"],
    }),
    getLastSeenCount: builder.query<number, void>({
      query: () => {
        return API.getLastSeenCount.endpoint();
      },
    }),
  }),
});
export const {
  usePostAddLastSeenMutation,
  useGetLastSeenQuery,
  useGetLastSeenCountQuery,
} = lastSeen;
export const { postAddLastSeen, getLastSeenCount } = lastSeen.endpoints;
