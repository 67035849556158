import { FlattenSimpleInterpolation, css, keyframes } from "styled-components";
import { useTheme } from "styled-components";
import {
  IThemeState,
  OutlineOptions,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { hoverEffectType } from "@ecp-redux/dto/themeSettings/visualEffects.schemes";
import {
  EFFECT_VARIANTS,
  HoverRowColumnEffectOption,
} from "@ecp-redux/dto/themeSettings/visualEffects.types";
import {
  convertColorIdToHex,
  convertHexToRGBA,
} from "../../../../settingsPatterns/settingsPatterns.methods";

export const createHoverEffect = (
  hoverEffect: hoverEffectType,
  placeOfUse: string
): FlattenSimpleInterpolation | string => {
  switch (hoverEffect.name) {
    case HoverRowColumnEffectOption.BUZZ:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationBuzz} ${hoverEffect.effectDetails.duration}s
            ease-in-out ${hoverEffect.effectDetails.infinity ? "infinite" : ""};
        }
      `;
    case HoverRowColumnEffectOption.FADE:
      return css`
        opacity: 0;

        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationFade} ${hoverEffect.effectDetails.duration}s
            ease-in-out ${hoverEffect.effectDetails.infinity ? "infinite" : ""};
          animation-delay: ${hoverEffect.effectDetails.delay}s;
          animation-fill-mode: forwards;
        }
      `;
    case HoverRowColumnEffectOption.FLASH:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationFlash} ${hoverEffect.effectDetails.duration}s
            ease-in-out ${hoverEffect.effectDetails.infinity ? "infinite" : ""};
        }
      `;
    case HoverRowColumnEffectOption.ROTATE:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationRotate(
              hoverEffect.effectDetails.angle,
              hoverEffect.effectDetails.variant
            )}
            ${hoverEffect.effectDetails.duration}s ease-in-out
            ${hoverEffect.effectDetails.infinity ? "infinite" : ""};
          animation-fill-mode: forwards;
        }
      `;
    case HoverRowColumnEffectOption.SKEW:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationSkew(hoverEffect.effectDetails.variant)}
            ${hoverEffect.effectDetails.duration}s ease-in-out;
          animation-fill-mode: forwards;
        }
      `;

    case HoverRowColumnEffectOption.MOVE:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationMove(
              hoverEffect.effectDetails.variant,
              hoverEffect.effectDetails.offset
            )}
            ${hoverEffect.effectDetails.duration}s ease-in-out;
          animation-fill-mode: forwards;
        }
      `;

    case HoverRowColumnEffectOption.PULSE:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationPulse(hoverEffect.effectDetails.variant)}
            ${hoverEffect.effectDetails.duration}s ease-in-out;
        }
      `;
    case HoverRowColumnEffectOption.COLOR:
      return css`
        &:hover,
        &:focus,
        &:active {
          #slot-line::before,
          #slots-line::before,
          &::before {
            animation: ${AnimationColor(
                hoverEffect.effectDetails.backgroundColor,
                hoverEffect.effectDetails.duration,
                hoverEffect.effectDetails.borderStyle,
                hoverEffect.effectDetails.borderColor,
                hoverEffect.effectDetails.borderSize
              )}
              ${hoverEffect.effectDetails.duration}s ease-in-out;
            animation-fill-mode: forwards;
          }
        }
      `;

    case HoverRowColumnEffectOption.OVERLAY:
      return css`
        &:hover,
        &:focus,
        &:active {
          #overlay-${placeOfUse} {
            animation: ${AnimationOverlay(
                hoverEffect.effectDetails.opacity,
                hoverEffect.effectDetails.color,
                hoverEffect.effectDetails.duration
              )}
              ${hoverEffect.effectDetails.duration}s ease-in-out
              ${hoverEffect.effectDetails.infinity ? "infinite" : ""};
            animation-fill-mode: forwards;
          }
        }
      `;
    case HoverRowColumnEffectOption.SCALE:
      return css`
        &:hover,
        &:focus,
        &:active {
          animation: ${AnimationScale(hoverEffect.effectDetails.variant)}
            ${hoverEffect.effectDetails.duration}s ease-in-out;
          animation-fill-mode: forwards;
        }
      `;
    case HoverRowColumnEffectOption.SHADOW:
      const theme = useTheme() as IThemeState;
      return css`
        &:hover,
        &:focus,
        &:active {
          box-shadow: ${hoverEffect.effectDetails.shadowPosition["x-axis"]}px
            ${hoverEffect.effectDetails.shadowPosition["y-axis"]}px
            ${hoverEffect.effectDetails.blur}px
            ${hoverEffect.effectDetails.spreading}px
            ${convertColorIdToHex(
              hoverEffect.effectDetails.shadowColor,
              theme.colorPalette
            )};
        }
      `;
    default:
      return "";
  }
};

const AnimationBuzz = keyframes`
  10% {
    -webkit-transform: translateX(3px) rotate(3.5deg);
    transform: translateX(3px) rotate(3.5deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-3.5deg);
    transform: translateX(-3px) rotate(-3.5deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(3.5deg);
    transform: translateX(3px) rotate(3.5deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-3.5deg);
    transform: translateX(-3px) rotate(-3.5deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1.75deg);
    transform: translateX(2px) rotate(1.75deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1.75deg);
    transform: translateX(-2px) rotate(-1.75deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1.75deg);
    transform: translateX(2px) rotate(1.75deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1.75deg);
    transform: translateX(-2px) rotate(-1.75deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }`;

const AnimationFade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    z-index: var(--zindex-hover-tongue-in-effect);
  }`;

const AnimationFlash = keyframes`
  0% { opacity: 1; } 
	50% { opacity: 0; } 
	100% { opacity: 1; }
  `;

const AnimationRotate = (
  angle: number,
  direction: EFFECT_VARIANTS
) => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    z-index: var(--zindex-hover-tongue-in-effect);
    ${
      direction === EFFECT_VARIANTS.LEFT
        ? `transform: rotate(-${angle}deg);`
        : `transform: rotate(${angle}deg);`
    }
   
  }
`;

const AnimationMove = (direction: EFFECT_VARIANTS, offset: number) => keyframes`
  to {
    z-index: var(--zindex-hover-tongue-in-effect);
    ${getTransformValue(direction, offset)}
  }`;

const AnimationSkew = (direction: EFFECT_VARIANTS) => keyframes`
from {
    transform: rotate(0deg);
  }
  to {
    z-index: var(--zindex-hover-tongue-in-effect);
    ${
      direction === EFFECT_VARIANTS.BACKWARD
        ? "transform: skew(10deg);"
        : "transform: skew(-10deg);"
    }
   
  }`;

const AnimationOverlay = (
  opacity: number,
  color: TColorReadoutValue,
  duration: number
) => {
  const theme = useTheme() as IThemeState;
  return keyframes`
  100% {
    position: absolute;
    right: 0;
    z-index: var(--zindex-hover-tongue-in-effect);
      background-color: ${convertHexToRGBA(
        convertColorIdToHex(color, theme.colorPalette),
        opacity
      )};
      transition: background-color z-index ${duration}s ease-in-out;
  
  }`;
};
const AnimationPulse = (direction: EFFECT_VARIANTS) => keyframes`
  0% {  
    transform: scale(1);
    }
  20% { 
    ${
      direction === EFFECT_VARIANTS.GROW
        ? "transform: scale(1.06);"
        : "transform: scale(0.94);"
    }
   
    }
  
  40% { 
    transform: scale(1);
   
    }

  60% { 
     ${
       direction === EFFECT_VARIANTS.GROW
         ? "transform: scale(1.06);"
         : "transform: scale(0.94);"
     }
   
    }

  80% {
      transform: scale(1); 
    }
  90% {
    ${
      direction === EFFECT_VARIANTS.GROW
        ? "transform: scale(1.06);"
        : "transform: scale(0.94);"
    }
  }
  100% {
      transform: scale(1); 
    }

  `;

const AnimationScale = (direction: EFFECT_VARIANTS) => keyframes`
  100% { 
    z-index: var(--zindex-hover-tongue-in-effect);
    ${
      direction === EFFECT_VARIANTS.OUT
        ? "transform: scale(0.9);"
        : "transform: scale(1.1);"
    }
    }
  `;

const AnimationColor = (
  color: TColorReadoutValue,
  duration: number,
  borderStyle: OutlineOptions,
  borderColor: TColorReadoutValue,
  borderSize: { top: number; right: number; bottom: number; left: number } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
) => {
  const theme = useTheme() as IThemeState;
  return keyframes`
    to {

      background-color: ${convertColorIdToHex(color, theme.colorPalette)};
      border-color: ${convertColorIdToHex(borderColor, theme.colorPalette)};
      border-style: ${borderStyle};
      border-top-width: ${borderSize.top}px;
      border-right-width: ${borderSize.right}px;
      border-bottom-width: ${borderSize.bottom}px;
      border-left-width: ${borderSize.left}px;
      transition: background-color ${duration}s ease-in-out;
    }
    `;
};

const getTransformValue = (direction: EFFECT_VARIANTS, offset: number) => {
  switch (direction) {
    case EFFECT_VARIANTS.LEFT:
      return `transform: translate(-${offset}px, 0);`;
    case EFFECT_VARIANTS.RIGHT:
      return `transform: translate(${offset}px, 0);`;
    case EFFECT_VARIANTS.UP:
      return `transform: translate(0, -${offset}px);`;
    case EFFECT_VARIANTS.DOWN:
      return `transform: translate(0, ${offset}px);`;
    default:
      return "";
  }
};
