export interface IConditionalWrapper {
  children: JSX.Element;
  condition: boolean;
  wrapper: (child: JSX.Element) => JSX.Element;
}

const ConditionalWrapper: React.FC<IConditionalWrapper> = ({
  condition,
  children,
  wrapper,
}: IConditionalWrapper): JSX.Element =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
