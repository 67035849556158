import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  SettingPositionOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  HoverEffects,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ProductButtonActions } from "@ecp-boxes/shared/components/domain/Product/ProductModule/ProductModule.types";
import { createSecondaryButtonScheme } from "../BoxSearchResults/BoxSearchResults.scheme";

export const IBoxCompareMessagesScheme = z.object({
  add_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Dodano produkt do porównania"
  ),
  remove_compare_product_snackbar: themeScheme.MessageScheme.default(
    "Usunięto produkt z porównania"
  ),
  no_products_in_comparison: themeScheme.MessageScheme.default(
    "Brak produktów w porównaniu"
  ),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodano do koszyka"
  ),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
  error_message: themeScheme.MessageScheme.default(
    "Błąd dodawania do porównania"
  ),
});

export const IBoxCompareSettingsScheme = z.object({
  add_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  remove_compare_product_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  error_message_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  no_products_in_comparison_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  no_products_in_comparison_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
});

const AttributeValueIconScheme = z.union([
  z.object({
    type: z.literal("SVG").nullable().default(null),
    iconMainColor: themeScheme.ColorReadoutValueScheme,
    iconHoverColor: themeScheme.ColorReadoutValueScheme,
    iconUrl: z.string(),
    iconSize: z.object({
      auto: z.boolean(),
      height: z.number(),
    }),
    iconPosition: z.nativeEnum(SettingPositionOptions),
    iconSpacing: z.number(),
    pureIcon: z.boolean(),
    valueCode: z.string(),
  }),
  z.object({
    type: z.literal("PNG").nullable().default(null),
    iconUrl: z.string(),
    iconSize: z.object({
      auto: z.boolean(),
      height: z.number(),
    }),
    iconPosition: z.nativeEnum(SettingPositionOptions),
    iconSpacing: z.number(),
    pureIcon: z.boolean(),
    valueCode: z.string(),
  }),
]);

const AttributeTitleIconScheme = z.union([
  z.object({
    type: z.literal("SVG").nullable().default(null),
    iconMainColor: themeScheme.ColorReadoutValueScheme,
    iconHoverColor: themeScheme.ColorReadoutValueScheme,
    iconUrl: z.string(),
    iconSize: z.object({
      auto: z.boolean(),
      height: z.number(),
    }),
    iconPosition: z.nativeEnum(SettingPositionOptions),
    iconSpacing: z.number(),
  }),
  z.object({
    type: z.literal("PNG").nullable().default(null),
    iconUrl: z.string(),
    iconSize: z.object({
      auto: z.boolean(),
      height: z.number(),
    }),
    iconPosition: z.nativeEnum(SettingPositionOptions),
    iconSpacing: z.number(),
  }),
]);

export const BoxCompareContentScheme = z
  .object({
    selections: z
      .array(
        z.object({
          attributeId: z.string(),
          translation: z.string(),
          attributeIcon: z
            .object({
              titleIcon: AttributeTitleIconScheme,
              valuesIcons: z.array(AttributeValueIconScheme),
            })
            .optional(),
        })
      )
      .default([]),
  })
  .default({
    selections: [],
  });

export const BoxCompareContentSettingsScheme = z
  .object({ showLabels: z.boolean().default(true) })
  .default({ showLabels: true });

const productCompareModuleElement = [
  "productPhoto",
  "productName",
  "actualPrice",
  "button",
  "secondaryButton",
] as const;

const productCompareElementInModule = [
  "productPhoto",
  "productName",
  "actualPrice",
  "button",
  "secondaryButton",
];

export const BoxCompareDisplaySettingsScheme = z
  .object({
    title: z
      .object({
        show: z.boolean().default(true),
        label: z.string().default("Heading"),
        style: themeScheme
          .createTextStyleShowSettingScheme("typo$$1", "color$$1")
          .default({
            font: {
              id: "typo$$1",
              custom: {
                family: "Roboto",
                weight: 700,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            text: {
              color: {
                id: "color$$1",
                custom: "#000000",
              },
            },
            show: true,
          }),
        padding: themeScheme.SpacingScheme.default({
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }),
        alignment: z
          .nativeEnum(AlignmentHorizontalOption)
          .default(AlignmentHorizontalOption.CENTER),
      })
      .default({
        show: true,
        label: "Heading",
        style: {
          font: {
            id: "typo$$1",
            custom: {
              family: "Roboto",
              weight: 700,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          text: {
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          show: true,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
        alignment: AlignmentHorizontalOption.LEFT,
      }),
    attributeNameStyle: themeScheme
      .createTextStyleShowSettingScheme("typo$$1", "color$$1")
      .default({
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: 700,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        show: true,
      }),
    attributeValueStyle: themeScheme
      .createTextStyleShowSettingScheme("typo$$1", "color$$1")
      .default({
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: 700,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        show: true,
      }),
    contentAlignment: z
      .nativeEnum(AlignmentHorizontalOption)
      .default(AlignmentHorizontalOption.CENTER),
    backgroundColor: z
      .object({
        even: themeScheme.ColorReadoutValueScheme,
        odd: themeScheme.ColorReadoutValueScheme,
      })
      .default({
        even: themeScheme.ColorReadoutValueScheme,
        odd: themeScheme.ColorReadoutValueScheme,
      }),
    padding: themeScheme.SpacingScheme.default({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }),
    boxSettings: z
      .object({
        alignment: z.nativeEnum(AlignmentHorizontalOption),
        padding: themeScheme.SpacingScheme,
        spaceBetweenElements: z.number(),
        spaceBetweenSlides: z.number(),
        backgroundColor: themeScheme.ColorReadoutValueScheme,
      })
      .default({
        alignment: AlignmentHorizontalOption.CENTER,
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        spaceBetweenElements: 0,
        spaceBetweenSlides: 0,
        backgroundColor: { id: "color$$1", custom: "#000000" },
      }),
    productsSettings: z
      .object({
        numberOfProductsToCompare: z.number().default(1).catch(1),
      })
      .default({ numberOfProductsToCompare: 1 }),
    favorites: z.object({ show: z.boolean() }).default({ show: false }),
    productPhoto: z
      .object({
        height: z.number().default(364).catch(364),
        hover: z
          .union([
            z.nativeEnum(HoverEffects),
            z.nativeEnum(ProductHoverEffects),
          ])
          .default(HoverEffects.NONE)
          .catch(HoverEffects.NONE),
        show: z.boolean().default(true),
      })
      .default({ height: 300, hover: HoverEffects.NONE, show: true }),
    productName: themeScheme
      .createTextStyleShowSettingScheme("typo$$1", "color$$1")
      .default({
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: 700,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        show: true,
      }),
    actualPrice: themeScheme
      .createTextStyleShowSettingScheme("typo$$1", "color$$1")
      .default({
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: 700,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        show: true,
      }),
    button: createSecondaryButtonScheme().default({
      labelText: "Add to cart",
      isCustomWidth: false,
      customWidth: 100,
      type: ProductButtonActions.ADD_TO_CART,
      url: "",
      comparisonSetting: {
        addButtonLabel: "Add to comparison",
        addButtonStyle: {},
        removeButtonLabel: "Remove from comparison",
        removeButtonStyle: {},
      },
      show: true,
      id: "button$$1",
      custom: {
        horizontalPadding: 35,
        verticalPadding: 8,
        background: {
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        },
        border: {
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        },
        text: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        },
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: "300",
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        icon: {
          iconUrl: null,
          position: "RIGHT",
          size: 16,
          spacing: 10,
        },
      },
    }),
    secondaryButton: createSecondaryButtonScheme().default({
      labelText: "Add to cart",
      isCustomWidth: false,
      customWidth: 100,
      type: ProductButtonActions.ADD_TO_CART,
      url: "",
      comparisonSetting: {
        addButtonLabel: "Add to comparison",
        addButtonStyle: {},
        removeButtonLabel: "Remove from comparison",
        removeButtonStyle: {},
      },
      show: true,
      id: "button$$1",
      custom: {
        horizontalPadding: 35,
        verticalPadding: 8,
        background: {
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        },
        border: {
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        },
        text: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        },
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: "300",
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        icon: {
          iconUrl: null,
          position: "RIGHT",
          size: 16,
          spacing: 10,
        },
      },
    }),
    orderInModule: z
      .array(z.union([z.enum(productCompareModuleElement), z.string()]))
      .default(productCompareElementInModule)
      .catch(productCompareElementInModule),
  })
  .default({
    title: {
      show: true,
      label: "Heading",
      style: {
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: 700,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        show: true,
      },
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      alignment: AlignmentHorizontalOption.LEFT,
    },
    attributeNameStyle: {
      font: {
        id: "typo$$1",
        custom: {
          family: "Roboto",
          weight: 700,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
      },
      show: true,
    },
    attributeValueStyle: {
      font: {
        id: "typo$$1",
        custom: {
          family: "Roboto",
          weight: 700,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
      },
      show: true,
    },
    contentAlignment: AlignmentHorizontalOption.CENTER,
    backgroundColor: {
      even: { id: "color$$1", custom: "#000000" },
      odd: { id: "color$$1", custom: "#000000" },
    },
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
    boxSettings: {
      alignment: AlignmentHorizontalOption.CENTER,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      spaceBetweenElements: 0,
      spaceBetweenSlides: 0,
      backgroundColor: { id: "color$$1", custom: "#000000" },
    },
    productsSettings: {
      numberOfProductsToCompare: 1,
    },
    favorites: { show: true },
    productPhoto: { height: 300, hover: HoverEffects.NONE },
    productName: {
      font: {
        id: "typo$$1",
        custom: {
          family: "Roboto",
          weight: 700,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
      },
      show: true,
    },
    actualPrice: {
      font: {
        id: "typo$$1",
        custom: {
          family: "Roboto",
          weight: 700,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
      },
      show: true,
    },
    button: {
      isCustomWidth: false,
      customWidth: 100,
      type: {
        selectedGoToOption: ProductButtonActions.ADD_TO_CART,
        goToPageSetting: { link: null, openInNewTab: false },
        showLinkOptions: false,
        goToRowSetting: undefined,
        forceOrderInsideStack: undefined,
        openPopupSetting: undefined,
        closePopupSetting: undefined,
      },
      url: "",
      comparisonSetting: {
        addButtonLabel: "Add to comparison",
        addButtonStyle: {},
        removeButtonLabel: "Remove from comparison",
        removeButtonStyle: {},
      },
      id: "button$$1",
      custom: {
        horizontalPadding: 35,
        verticalPadding: 8,
        background: {
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        },
        border: {
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        },
        text: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        },
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: "300",
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        icon: {
          iconUrl: null,
          position: "RIGHT",
          size: 16,
          spacing: 10,
        },
      },
      show: true,
    },
    secondaryButton: {
      isCustomWidth: false,
      customWidth: 100,
      type: {
        selectedGoToOption: ProductButtonActions.ADD_TO_CART,
        goToPageSetting: { link: null, openInNewTab: false },
        showLinkOptions: false,
        goToRowSetting: undefined,
        forceOrderInsideStack: undefined,
        openPopupSetting: undefined,
        closePopupSetting: undefined,
      },
      url: "",
      comparisonSetting: {
        addButtonLabel: "Add to comparison",
        addButtonStyle: {},
        removeButtonLabel: "Remove from comparison",
        removeButtonStyle: {},
      },
      show: true,
      id: "button$$1",
      custom: {
        horizontalPadding: 35,
        verticalPadding: 8,
        background: {
          color: { id: "color$$2", custom: "#000000" },
          hoverColor: { id: "color$$2", custom: "#000000" },
          activeColor: { id: "color$$3", custom: "#000000" },
          disabledColor: { id: "color$$4", custom: "#000000" },
        },
        border: {
          radius: 4,
          width: 0,
          radiusV2: null,
          widthV2: null,
          color: { id: "color$$5", custom: "#000000" },
          hoverColor: { id: "color$$6", custom: "#000000" },
          activeColor: { id: "color$$7", custom: "#000000" },
          disabledColor: { id: "color$$8", custom: "#000000" },
        },
        text: {
          color: { id: "color$$1", custom: "#000000" },
          hoverColor: { id: "color$$10", custom: "#000000" },
          activeColor: { id: "color$$11", custom: "#000000" },
          disabledColor: { id: "color$$12", custom: "#000000" },
        },
        font: {
          id: "typo$$1",
          custom: {
            family: "Roboto",
            weight: "300",
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        icon: {
          iconUrl: null,
          position: "RIGHT",
          size: 16,
          spacing: 10,
        },
      },
    },
  });
