import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxArticleContentMessagesScheme = z.object({});

export const IBoxArticleContentSettingsScheme = z.object({
  styleHeader1Id: themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  styleHeader2Id: themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  styleHeader3Id: themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  styleParagraphId:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  styleLinkId: themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  styleListId: themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
});
