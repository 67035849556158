import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxPhotoGallerySettingsScheme = z.object({
  photo_gallery_pagination_empty_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  photo_gallery_pagination_fullfield_color:
    themeScheme.ColorIdScheme.default("color$$3").catch("color$$3"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  wishlist_icon_visible:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
});

export const IBoxPhotoGalleryMessagesScheme = z.object({
  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
});
